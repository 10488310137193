import React, { Component } from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    Link,
    Row,
    Col,
    Button,
    Icon,
    Popover,
    Card,
    CardContent
} from 'framework7-react';
import Table from './table';
import Toolbar from './toolbar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';
import generateTableForExcel from "./generate-table/generateTableForExcel";
import exportTableToExcel from "../../shared-components/exportTableToExcelService";
import JSONToCSVConvertor from '../../shared-components/JSONToCSVConvertor';

// const PDBersekolahDIluarProvDIY = () => {
class PDBersekolahDIluarProvDIY extends Component {

    state = {
        data: [
            { id: 1, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '001', desa: 'Desa 001', kecamatan: 'Kec. 001', kab_kota: 'Kab. 001', provinsi: 'Prov. DI Yogyakarta', kode_pos: '001' },

        ],
        error: null,
        loading: true,
        routeParams: {
            kode_wilayah: this.$f7route.params['kode_wilayah'] ? this.$f7route.params['kode_wilayah'] : localStorage.getItem('kode_wilayah_aplikasi'),
            id_level_wilayah: this.$f7route.params['id_level_wilayah'] ? this.$f7route.params['id_level_wilayah'] : localStorage.getItem('id_level_wilayah_aplikasi'),
            semester_id: localStorage.getItem('semester_id_aplikasi'),
            tahun_ajaran_id: localStorage.getItem('semester_id_aplikasi').substring(0, 4),
            //   bentuk_pendidikan_id: sudah_login === 1 ? jenjang_aplikasi_all : jenjang_aplikasi,
            status_sekolah: '99'
        }
    }
    componentDidMount = () => {
        // console.log(this.state.data)
        this.$f7.dialog.preloader('Memuat data')
        this.props.getDataPDLuar(this.state.routeParams).then((result) => {
            this.setState({
                data: result.payload.data,
                loading: false
            });
            this.$f7.dialog.close()
            // console.log(this.state.data)
        })
    }
    render() {
        // const downloadExcel = () => {
        //     const labelExcel = `Sebaran Sekolah Penggerak`;
        //     const tableForExcel = generateTableForExcel(
        //         "tableSebaranSekolahPenggerak",
        //         labelExcel
        //     );

        //     exportTableToExcel(tableForExcel, labelExcel);
        // } 

        const downloadExcel = () => {
            const labelExcel = `Peserta Didik Bersekolah Di Luar Provinsi DI Yogyakarta`;

            this.$f7.dialog.preloader('Memroses file unduhan...')

            this.props.getDataPDLuar({
                ...this.state.routeParams,
                start: 0,
                limit: 100000000
            }).then((result) => {
                this.$f7.dialog.close()
                JSONToCSVConvertor(result.payload.data, labelExcel, true)

            }).catch(() => {
                this.$f7.dialog.close()
            })

        }

        return (
            <Page name="profil-pendidikan-data" hideBarsOnScroll>
                <Navbar sliding={false}>
                    <NavLeft>
                        <Link iconIos="f7:chevron_left" iconAurora="f7:chevron_left" iconMd="material:chevron_left" href="/">Beranda</Link>
                    </NavLeft>
                    <NavTitle sliding>
                        {/*Profil Pendidikan{' '}&#45;{' '}{TitleFromModule()} {this.state.searchQuery.bentuk_pendidikan && this.state.searchQuery.bentuk_pendidikan.toUpperCase()}*/}
                    </NavTitle>
                </Navbar>
                <div style={{ padding: "2% 3%" }}>
                    <Card style={{ margin: "1rem 0px", borderRadius: "12px", padding: "10px" }}>
                        <CardContent>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <b>Peserta Didik Bersekolah Diluar Kota Palembang</b>
                                <Button onClick={downloadExcel} style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold', padding: '0px 20px', fontSize: '12px' }}>Unduh XLS</Button>
                            </div>
                            <Table data={this.props.profilPendidikanPdLuar.data} />
                        </CardContent>
                    </Card>
                </div>
            </Page>
        );
    }
};

// export default PDBersekolahDIluarProvDIY;

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension: Actions.updateWindowDimension,
        setLoading: Actions.setLoading,
        setTabActive: Actions.setTabActive,
        getSekolah: Actions.getSekolah,
        getRekapSekolah: Actions.getRekapSekolah,
        getSekolahIndividu: Actions.getSekolahIndividu,
        getWilayah: Actions.getWilayah,
        getDataPDLuar: Actions.getDataPDLuar
    }, dispatch);
}

function mapStateToProps({ App, RekapGTK, ProfilPendidikan }) {

    return {
        window_dimension: App.window_dimension,
        loading: App.loading,
        tabBar: App.tabBar,
        sekolah: App.sekolah,
        rekap_sekolah: App.rekap_sekolah,
        sekolah_individu: App.sekolah_individu,
        wilayah: App.wilayah,
        dummy_rows: App.dummy_rows,
        rekap_gtk_agama: RekapGTK.rekap_gtk_agama,
        judul_panel_kanan: App.judul_panel_kanan,
        isi_panel_kanan: App.isi_panel_kanan,
        profilPendidikanPdLuar: ProfilPendidikan.profilPendidikanPdLuar
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(PDBersekolahDIluarProvDIY));