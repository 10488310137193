import React from "react";

const Config = {
    Apk: {
        title: "APK PAUD",
        data: [],
        columns: [
            {
                Header: 'Wilayah',
                columns: [
                    {
                        Header: '',
                        accessor: 'kecamatan',
                        Cell: ({ row }) => (
                            <div style={{ textAlign: "left" }}>
                                {row.original.kecamatan || ''}
                            </div>
                        )
                    },
                ]
            },
            {
                Header: 'APK PAUD (%)',
                columns: [
                    {
                        Header: 'Laki-laki',
                        accessor: 'jp04_laki_laki',
                    },
                    {
                        Header: 'Perempuan',
                        accessor: 'jp04_perempuan',
                    },
                    {
                        Header: 'Jumlah',
                        accessor: 'jp04_jumlah',
                    },
                ]
            },
        ]
    },
    Apm: {
        title: "APM PAUD",
        data: [],
        columns: [
            {
                Header: 'Wilayah',
                columns: [
                    {
                        Header: '',
                        accessor: 'kecamatan',
                        Cell: ({ row }) => (
                            <div style={{ textAlign: "left" }}>
                                {row.original.kecamatan}
                            </div>
                        )
                    },
                ]
            },
            {
                Header: 'Jumlah Penduduk 3-6',
                columns: [
                    {
                        Header: 'L',
                        accessor: 'jp04_laki_laki',
                    },
                    {
                        Header: 'P',
                        accessor: 'jp04_perempuan',
                    },
                    {
                        Header: 'Jumlah',
                        accessor: 'jp04_jumlah',
                    },
                ]
            },
        ]
    }
}

export default Config;