import React from "react";
import { Card, CardContent } from "framework7-react";
import { useTable } from "react-table";
import _ from "lodash";

function ApkApmTable({ columns, data, title, loading }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    let total = {};

    const handleRender = (cell) => {
        return cell.render('Cell')
    }
    
    return (
        <Card style={{ margin: "1rem 0px" }}>
            <CardContent>
                <b>{title}</b>
                <div className="data-table mt-20px">
                    <table {...getTableProps()} style={{ border: "1px solid #d1d1d1" }}>
                        <thead className="bg-222222">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
                                    if(column.skip){
                                        return "";
                                    }
                                    return (<th
										width={column.width || ''}
										align={column.align || 'left'}
                                        rowSpan={column.rowSpan || ''}
                                        colSpan={column.colSpan || ''}
										className={"whitespace-no-wrap font-12 " + column.className}
										{...(!column.sortable
											? column.getHeaderProps()
											: column.getHeaderProps(column.getSortByToggleProps()))}
									>
										{column.render('Header')}
									</th>)
                            
                            })}
							</tr>
						))}
					    </thead>
                        <tbody {...getTableBodyProps()}>
                            { loading && <tr><td>Memuat...</td></tr> }
                            {!loading && rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()} className="text-center">
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* <tfoot style={{ fontWeight: "700", backgroundColor: "#E8E8E8", height: "40px" }}> */}
                            {/* <tr>
                                <td colSpan={2} className="text-center">JUMLAH</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                            </tr> */}
                            {/* {!loading && rows.map((row, i) => {
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            const { id } = cell.column;
                                            const value = cell.value;
                                            
                                            if(total[id]){
                                                total[id].push(value)
                                            }else{
                                                total[id] = [];
                                            }

                                            if((rows.length-1) === i){
                                                if(cell.column.footerLabel !== 'sum'){
                                                    return (<th>{ cell.column.footerLabel }</th>)
                                                }else{
                                                    const val = handleRender(cell);
                                                    const valint = parseInt(val)
                                                    console.log("🚀 ~ file: ApkApmTable.jsx:103 ~ {!loading&&rows.map ~ valint:", valint)
                                                    return (
                                                        <td {...cell.getCellProps()} className="text-center">
                                                            { val }
                                                        </td>
                                                    )
                                                }
                                            }
                                        })}
                                    </tr>
                                )
                            })} */}
                        {/* </tfoot> */}
                    </table>
                </div>
            </CardContent>
        </Card>
    )
}

export default ApkApmTable;