import React from "react";

const ConfigPaudSmpMTsPaketB = {
    Apk: {
        title: "APK SMP/MTs/Paket B",
        data: [],
        columns: [
            {
                Header: 'Wilayah',
                columns: [
                    {
                        Header: '',
                        accessor: 'kecamatan',
                        Cell: ({ row }) => (
                            <div style={{ textAlign: "left" }}>
                                {row.original.kecamatan}
                            </div>
                        )
                    },
                ]
            },
            {
                Header: 'Jumlah Penduduk 13-15',
                columns: [
                    {
                        Header: 'L',
                        accessor: 'jp1315_laki_laki',
                    },
                    {
                        Header: 'P',
                        accessor: 'jp1315_perempuan',
                    },
                    {
                        Header: 'Jumlah',
                        accessor: 'jp1315_jumlah',
                    },
                ]
            },
        ]
    },
    Apm: {
        title: "APM SMP/MTs/Paket B",
        data: [],
        columns: [
            {
                Header: 'Wilayah',
                columns: [
                    {
                        Header: '',
                        accessor: 'kecamatan',
                        Cell: ({ row }) => (
                            <div style={{ textAlign: "left" }}>
                                {row.original.kecamatan}
                            </div>
                        )
                    },
                ]
            },
            {
                Header: 'Jumlah Penduduk 13-15',
                columns: [
                    {
                        Header: 'L',
                        accessor: 'jp1315_laki_laki',
                    },
                    {
                        Header: 'P',
                        accessor: 'jp1315_perempuan',
                    },
                    {
                        Header: 'Jumlah',
                        accessor: 'jp1315_jumlah',
                    },
                ]
            },
        ]
    }
}

export default ConfigPaudSmpMTsPaketB;