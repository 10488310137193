function exportTableToExcel(tableSelect, filename = '') {
  const dataType = 'application/vnd.ms-excel';
  const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

  // Specify file name
  filename = filename ? `${filename}.xls` : 'excel_data.xls';

  // Create download link element
  const downloadLink = document.createElement('a');
  console.log("🚀 ~ file: exportTableToExcelService.js:10 ~ exportTableToExcel ~ downloadLink:", downloadLink)

  document.body.appendChild(downloadLink);
  if (navigator.msSaveOrOpenBlob) {
    console.log("🚀 ~ noval here file: exportTableToExcelService.js:14 ~ exportTableToExcel ~ navigator:", navigator)
    

    const blob = new Blob(['\ufeff', tableHTML], {
      type: dataType,
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = `data:${dataType}, ${tableHTML}`;

    console.log("🚀 ~ file: exportTableToExcelService.js:25 ~ exportTableToExcel ~ downloadLink:", downloadLink)
    // Setting the file name
    // downloadLink.download = filename;

    // triggering the function
    // downloadLink.click();

    
        //this trick will generate a temp <a /> tag
        var link = document.createElement("a"); 
        link.className = "external";   
        link.href = `data:${dataType}, ${tableHTML}`;
        
        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = filename + ".xls";
        
        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

  }
}

export default exportTableToExcel;
