import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../store/actions';
import { Card, CardContent, Col, Navbar, NavTitle, NavTitleLarge, Page, Row } from 'framework7-react';

class ProfilSekolahAnggota extends Component {
    state = {
        loading: true,
        routeParams: {
            sekolah_id: this.props.sekolah_id,
            tingkat: this.props.tingkat,
            type: this.props.type
        }
    }
    
    componentDidMount = () => {
        this.setState({ loading: true });
        
        if(this.state.routeParams.type === 'PD'){
            this.props.getPesertaDidikAnggota(this.state.routeParams).then(() => {
                this.setState({ loading: false });
            });
        }else if(this.state.routeParams.type === 'GTK'){
            this.props.getAnggotaGTKSekolah(this.state.routeParams).then(() => {
                this.setState({ loading: false });
            })
        }
    }

    render()
    {
        const { tingkat, rekap_sekolah_anggota, rekap_sekolah_anggota_gtk } = this.props;

        return (
            <Page name="ProfilSekolahAnggotaDetail" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>{ this.state.routeParams.type === 'PD' ? `Daftar Siswa Kelas ${tingkat}` : `Daftar ${tingkat}` }</NavTitle>
                    <NavTitleLarge>
                        { this.state.routeParams.type === 'PD' ? `Daftar Siswa Kelas ${tingkat}` : `Daftar ${tingkat}` }
                    </NavTitleLarge>
                </Navbar>
                <div>
                    { this.state.routeParams.type === 'PD' && (
                    <Row>
                        <Col
                            width={100}
                            tabletWidth={100}
                        >
                            <Card>
                                <CardContent>
                                    <div className="data-table" style={{overflowY:'hidden'}}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama</th>
                                                    <th>NISN</th>
                                                    <th>NIK</th>
                                                    <th>Kelas</th>
                                                    <th>Ruang Kelas</th>
                                                    <th>Jenis Kelamin</th>
                                                    <th>Tempat Lahir</th>
                                                    <th>Tanggal Lahir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.loading === true && (
                                                    <tr>
                                                        <td colSpan={9} align="center">Memuat...</td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                this.state.loading === false &&
                                                rekap_sekolah_anggota &&
                                                rekap_sekolah_anggota.map((opt, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="font-12">{ key + 1 }</td>
                                                            <td className="font-12">
                                                                <a href={`/ProfilSekolahAnggotaDetailPD/${opt.peserta_didik_id}`}><b>{ opt.nama }</b></a>
                                                            </td>
                                                            <td className="font-12">{ opt.nisn }</td>
                                                            <td className="font-12">{ opt.nik }</td>
                                                            <td className="font-12">{ opt.tingkat_pendidikan }</td>
                                                            <td className="font-12">{ opt.nama_ruang }</td>
                                                            <td className="font-12">{ opt.jenis_kelamin }</td>
                                                            <td className="font-12">{ opt.tempat_lahir }</td>
                                                            <td className="font-12">{ opt.tanggal_lahir }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    )}
                    
                    { this.state.routeParams.type === 'GTK' && (
                        <Row>
                        <Col
                            width={100}
                            tabletWidth={100}
                        >
                            <Card>
                                <CardContent>
                                    <div className="data-table" style={{overflowY:'hidden'}}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama</th>
                                                    <th>NIP</th>
                                                    <th>NIK</th>
                                                    <th>NUPTK</th>
                                                    <th>Jenis PTK</th>
                                                    <th>Status Kepegawaian</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.loading === true && (
                                                    <tr>
                                                        <td colSpan={7} align="center">Memuat...</td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                this.state.loading === false &&
                                                rekap_sekolah_anggota_gtk &&
                                                rekap_sekolah_anggota_gtk.map((opt, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="font-12">{ key + 1 }</td>
                                                            <td className="font-12">
                                                                <a href={`/ProfilSekolahAnggotaDetail/${opt.ptk_id}`}><b>{ opt.nama }</b></a>
                                                            </td>
                                                            <td className="font-12">{ opt.nik }</td>
                                                            <td className="font-12">{ opt.nik }</td>
                                                            <td className="font-12">{ opt.nuptk }</td>
                                                            <td className="font-12">{ opt.jenis_ptk }</td>
                                                            <td className="font-12">{ opt.status_kepegawaian }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    )}
                </div>
            </Page>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getKecamatan                : Actions.getKecamatan,
        getPesertaDidikAnggota      : Actions.getPesertaDidikAnggota,
        getAnggotaGTKSekolah        : Actions.getAnggotaGTKSekolah
    }, dispatch);
}

function mapStateToProps({ App, RekapSekolah }) {

    return {
        loading                     : App.loading,
        rekap_sekolah_anggota       : RekapSekolah.rekap_sekolah_anggota.rows,
        rekap_sekolah_anggota_gtk   : RekapSekolah.rekap_sekolah_anggota_gtk.rows
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ProfilSekolahAnggota));