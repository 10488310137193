import React from "react";
import { Button, Card, CardContent } from "framework7-react"
import { useTable } from "react-table"

const ApkApmTable = ({ columns, data, title, setDataApkApm }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    const handleChange = (params) => {
        const { key, id, value } = params;
        let data_ = data;
        data_[key][id] = value;

        setDataApkApm(data_);
    }
    
    return (
        <Card style={{ margin: "1rem 0px" }}>
            <CardContent>
                <b>{title}</b>
                <div className="data-table mt-20px">
                    <table {...getTableProps()} className="table-auto" style={{ border: "1px solid #d1d1d1" }}>
                        <thead className="bg-222222">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
                                    if(column.skip){
                                        return "";
                                    }

                                    return (
									<th
										width={column.width || ''}
										align={column.align || 'left'}
                                        rowSpan={column.rowSpan || ''}
                                        colSpan={column.colSpan || ''}
										className={"whitespace-no-wrap font-12 " + column.className}
										{...(!column.sortable
											? column.getHeaderProps()
											: column.getHeaderProps(column.getSortByToggleProps()))}
									>
										{column.render('Header')}
									</th>)
                                })}
							</tr>
						))}
					    </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>                                        
                                        {row.cells.map((cell, idx) => {
                                            const { type } = cell.column;
                                            if (!type || type !== 'input') {
                                                return <td {...cell.getCellProps()} className="text-center">
                                                    {cell.render('Cell')}
                                                </td>
                                            }
                                            const { key } = cell.getCellProps();
                                            const str_del = key.substr(0,7);
                                            const val = key.replace(str_del, "");
                                            const value = row.original[val];
                                            
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    <input
                                                        style={{ width: 50 }}
                                                        className="text-elipsis table-input-form"
                                                        type="tel"
                                                        value={value ? value : ''}
                                                        name={cell.column.Header + '-' + cell.row.kecamatan + '-' + cell.column.id}
                                                        onChange={(e) => {
                                                            handleChange({
                                                                key: i,
                                                                id: cell.column.id,
                                                                value: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* <tfoot style={{ fontWeight: "700", backgroundColor: "#E8E8E8", height: "40px" }}>
                            <tr>
                                <td colSpan={2} className="text-center">JUMLAH</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                                <td className="text-center">70</td>
                                <td className="text-center">100</td>
                                <td className="text-center">170</td>
                            </tr>
                        </tfoot> */}
                    </table>
                </div>
            </CardContent>
        </Card>
    )
}

export default ApkApmTable;