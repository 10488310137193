import React from "react";
import { Button, Card, CardContent } from "framework7-react"
import { useTable } from "react-table"
import generateTableForExcel from "../generate-table/generateTableForExcel";
import exportTableToExcel from "../../../shared-components/exportTableToExcelService";

const ApkApmPaudRaTable = ({ columns, data, title, current_url, rows:dataRows, total }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: dataRows })

    const downloadExcel = () => {
    const labelExcel = `Apk Table ${current_url}`;
    const tableForExcel = generateTableForExcel(
        "apkTable",
        labelExcel
    );

        exportTableToExcel(tableForExcel, labelExcel);
    } 

    const footer = columns[1].columns;
    
    return (
        <Card style={{ margin: "1rem 0px", borderRadius: "12px", padding: "10px" }}>
            <CardContent>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>{title}</b>
                    <Button onClick={downloadExcel} style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold', padding: '0px 20px', fontSize: '12px' }}>Unduh XLS</Button>
                </div>
                <div className="data-table mt-20px">
                    <table {...getTableProps()} className="table-auto" style={{ border: "1px solid #d1d1d1" }} id="apkTable">
                        <thead className="bg-blue">
                            <tr>
                                <th className="small-only text-center" rowSpan={2}>NO</th>
                                <th className="label-cell" rowSpan={2}>Wilayah</th>
                                <th className="small-only text-center" colSpan={3}>APK {current_url} (%)</th>
                            </tr>
                            <tr>
                                <th className="small-only text-center">LAKI-LAKI</th>
                                <th className="small-only text-center">PEREMPUAN</th>
                                <th className="small-only text-center">JUMLAH</th>
                            </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        <td className="text-center" style={{ width: "70px" }}>{i + 1}</td>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()} className="text-center">
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot style={{ fontWeight: "700", backgroundColor: "#FFD966", height: "40px" }}>
                            <tr>
                                <td colSpan={2} className="text-center">JUMLAH</td>
                                {
                                    footer.map((opt, key) => {
                                        return (
                                            <td key={key} className="text-center">{ total[opt.accessor] }</td>
                                        )
                                    })
                                }
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </CardContent>
        </Card>
    )
}

export default ApkApmPaudRaTable;