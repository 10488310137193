import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../store/actions';
import { Card, CardContent, CardHeader, Col, List, ListItem, Navbar, NavTitle, NavTitleLarge, Page, Row } from 'framework7-react';

class ProfilSekolahAnggotaDetail extends Component {
    state = {
        loading: true,
        routeParams: {
            ptk_id: this.props.ptk_id            
        }
    }
    
    componentDidMount = () => {
        this.setState({ loading: true });
            
        this.props.getGTKPtk(this.state.routeParams).then(() => {
            this.setState({ loading: false });
        });
    }

    render()
    {
        const { tingkat, gtk_ptk } = this.props;

        return (
            <Page name="ProfilSekolahAnggotaDetail" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>Biodata Guru</NavTitle>
                    <NavTitleLarge>
                        Biodata Guru
                    </NavTitleLarge>
                </Navbar>
                <Card>
                    <CardContent>
                        <Row>
                            <Col width={100} tabletWidth={60}>
                                <Row>
                                    <Col width={100} tabletWidth={100}>
                                        <Card>
                                            <CardHeader>1. IDENTITAS GURU</CardHeader>
                                            <CardContent>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        a. Nama Lengkap
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{ gtk_ptk.nama }</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        b. NIK
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nik}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        c. NIP
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nip}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        d. NUPTK
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nuptk}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        e. Tempat, Tanggal Lahir
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.tempat_lahir + ", " + gtk_ptk.tanggal_lahir}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        f. Alamat
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.alamat_jalan}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        g. Jenis Kelamin
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.jenis_kelamin}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        h. Agama
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.agama}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        i. Status Perkawinan
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.status_perkawinan}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        j. Nama Istri/Suami
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nama_suami_istri}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        k. Pekerjaan Istri/Suami
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.perkerjaan_suami_istri}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        l. NIP Istri/Suami
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nip_suami_istri}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        M. Nama Ibu Kandung
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nama_ibu_kandung}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        N. No HP
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.no_hp}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        o. Email
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.email}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        p. Nama WP
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.nm_wp}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        q. NPWN
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.npwn}</b>
                                                    </Col>
                                                </Row>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                    <Col width={100} tabletWidth={100}>
                                        <Card>
                                            <CardHeader>2. PENDIDIKAN TERAKHIR <b>{ gtk_ptk.riwayat_pendidikan_formal_jenjang_pendidikan }</b></CardHeader>
                                            <CardContent>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        a. NAMA UNIVERSITAS/SEKOLAH TINGGI
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.riwayat_pendidikan_formal_satuan_pendidikan_formal}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        b. PROGRAM STUDI / JURUSAN
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.riwayat_pendidikan_formal_fakultas}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        c. NOMOR INDUK MAHASISWA
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.riwayat_pendidikan_formal_nim}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        d. TAHUN MASUK
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.riwayat_pendidikan_formal_tahun_masuk}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        e. TAHUN LULUS
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.riwayat_pendidikan_formal_tahun_lulus}</b>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:'8px'}}>
                                                    <Col width="40">
                                                        f. IPK
                                                    </Col>
                                                    <Col width="60">
                                                        : <b>{gtk_ptk.riwayat_pendidikan_formal_ipk}</b>
                                                    </Col>
                                                </Row>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>

                            <Col width={100} tabletWidth={40}>
                                <Card>
                                    <CardHeader>3. Status Kepegawaian</CardHeader>
                                    <CardContent>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                a. TMT PENGANGKATAN
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.tmt_pengangkatan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                b. NO SK PENGANGKATAN
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.sk_pengangkatan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                c. TANGGAL SK PENGANGKATAN
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.tmt_pengangkatan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                d. TMT PNS
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.tmt_pns}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                e. NO SK PNS
                                            </Col>
                                            <Col width="60">
                                                : <b>-</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                f. TANGGAL SK PNS
                                            </Col>
                                            <Col width="60">
                                                : <b>-</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                g. TMT SK BERKALA
                                            </Col>
                                            <Col width="60">
                                                : <b>-</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                h. NO SK BERKALA
                                            </Col>
                                            <Col width="60">
                                                : <b>-</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                j. TMT DI SEKOLAH INI
                                            </Col>
                                            <Col width="60">
                                                : <b>-</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                k. TANGGAL SK DI SEKOLAH INI
                                            </Col>
                                            <Col width="60">
                                                : <b>-</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                l. NOMOR SERTIFIKAT PENDIDIK
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.riwayat_sertifikasi_nomor_sertifikat}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                m. NOMOR PESERTA SERTIFIKASI
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.riwayat_sertifikasi_nomor_peserta}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                n. NRG
                                            </Col>
                                            <Col width="60">
                                                : <b>{gtk_ptk.riwayat_sertifikasi_nrg}</b>
                                            </Col>
                                        </Row>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </Page>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGTKPtk                   : Actions.getGTKPtk,
    }, dispatch);
}

function mapStateToProps({ App, Gtk }) {

    return {
        loading                     : App.loading,
        gtk_ptk                     : Gtk.gtk_ptk
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ProfilSekolahAnggotaDetail));