import React, {Component} from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    Searchbar,
    Icon,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem,
    Subnavbar,
    Segmented,
} from 'framework7-react';

// import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";

import { Doughnut, Bar } from 'react-chartjs-2';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';
import SelectSemester from '../SelectSemester';

const sudah_login = parseInt(localStorage.getItem('sudah_login'));
const jenjang_aplikasi = localStorage.getItem('jenjang_aplikasi');
const jenjang_aplikasi_all = localStorage.getItem('jenjang_aplikasi_all');
const levelWilayah = localStorage.getItem('id_level_wilayah_aplikasi');

const header = [
    {
        id: 'npsn',
        label: 'NPSN',
        width: '4%'
    },
    {
        id: 'nama',
        label: 'Nama',
        width: '4%'
    },
    {
        id: 'nik',
        label: 'NIK',
        width: '4%'
    },
    {
        id: 'nuptk',
        label: 'NUPTK',
        width: '4%'
    },
    {
        id: 'nip',
        label: 'NIP',
        width: '4%'
    },
    {
        id: 'jenis_kelamin',
        label: 'Jenis Kelamin (L/P)',
        width: '4%'
    },
    {
        id: 'tempat_lahir',
        label: 'Tempat Lahir',
        width: '4%'
    },
    {
        id: 'tanggal_lahir',
        label: 'Tanggal Lahir',
        width: '4%'
    },
    {
        id: 'usia',
        label: 'Usia',
        width: '4%'
    },
    {
        id: 'status_tugas',
        label: 'Status Tugas',
        width: '4%'
    },
    {
        id: 'tempat_tugas',
        label: 'Tempat Tugas',
        width: '4%'
    },
    {
        id: 'kecamatan',
        label: 'Kecamatan',
        width: '4%'
    },
    {
        id: 'kabupaten',
        label: 'Kabupaten/Kota',
        width: '4%'
    },
    {
        id: 'jenis_ptk',
        label: 'Jenis PTK',
        width: '4%'
    },
    {
        id: 'pendidikan',
        label: 'Pendidikan',
        width: '4%'
    },
    {
        id: 'didang_studi_pendidikan',
        label: 'Bidang Studi Pendidikan',
        width: '4%'
    },
    {
        id: 'status_sertifikasi',
        label: 'Status Sertifikasi',
        width: '4%'
    },
    {
        id: 'bidang_studi_sertifikasi',
        label: 'Bidang Studi Sertifikasi',
        width: '4%'
    },
    {
        id: 'status_kepegawaian',
        label: 'Status Kepegawaian',
        width: '4%'
    },
    {
        id: 'pangkat_golongan',
        label: 'Pangkat/Golongan',
        width: '4%'
    },
    {
        id: 'masa_kerja_tahun',
        label: 'Masa Kerja Tahun',
        width: '4%'
    },
    {
        id: 'masa_kerja_bulan',
        label: 'Masa Kerja Bulan',
        width: '4%'
    },
    {
        id: 'mata_pelajaran_diajarkan',
        label: 'Mata Pelajaran Diajarkan',
        width: '4%'
    },
    {
        id: 'jam_mengajar_perminggu',
        label: 'Jam Mengajar Perminggu',
        width: '4%'
    },
    {
        id: 'jabatan_kepsek',
        label: 'Jabatan Kepsek',
        width: '4%'
    },
]

class DataIndividuptk extends Component {
    state = {
        error: null,
        loading: true,
        routeParams: {
            kode_wilayah: this.$f7route.params['kode_wilayah'] ? this.$f7route.params['kode_wilayah'] : localStorage.getItem('kode_wilayah_aplikasi'),
            id_level_wilayah: this.$f7route.params['id_level_wilayah'] ? this.$f7route.params['id_level_wilayah']: localStorage.getItem('id_level_wilayah_aplikasi'),
            semester_id: localStorage.getItem('semester_id_aplikasi'),
            tahun_ajaran_id: localStorage.getItem('semester_id_aplikasi').substring(0,4),
            bentuk_pendidikan_id: sudah_login === 1 ? jenjang_aplikasi_all : jenjang_aplikasi,
            page: 0,
            start: 0,
            limit: 20,
        },
        wilayah_kab: [],
        wilayah_kec: [],
        sekolah: [],
        limit: [10, 20, 50, 100, 200, 500, 1000]
    }

    componentDidMount = () => {
        this.setState({
            routeParams: {
                ...this.state.routeParams,
                kode_wilayah: this.state.routeParams.kode_wilayah
            }
        },()=>{
            const routeParams = {
                id_level_wilayah: 2,
                mst_kode_wilayah: this.state.routeParams.kode_wilayah
            }
            console.log(routeParams);
            this.props.getWilayah(routeParams).then((e)=>{
                console.log(e.payload.rows);
                if(levelWilayah === 1){
                    console.log('provinsi wilayah')
                    this.setState({ wilayah_kab: e.payload.rows });
                }else{
                    console.log('kabupaten wilayah')
                    this.setState({ wilayah_kec: e.payload.rows });
                }
                this.getSekolah(this.state.routeParams.kode_wilayah);
                this.props.getPTK(this.state.routeParams).then(()=>{
                    this.setState({ loading: false });
                    this.setIsiKanan();
                });
            });
        });
    }

    getSekolah = (kode_wilayah) => {
        const { bentuk_pendidikan_id, id_level_wilayah } = this.state.routeParams;

        const paramsekolah = {
            kolom: ['sekolah.sekolah_id', 'sekolah.nama'],
            bentuk_pendidikan_id: bentuk_pendidikan_id,
            id_level_wilayah: id_level_wilayah,
            kode_wilayah: kode_wilayah,
            limit: parseInt(id_level_wilayah) === 3 ? undefined : 100
        }

        this.props.getSekolah(paramsekolah).then((e) => {
            this.setState({ sekolah: e.payload.rows });
        });
    }

    setIsiKanan = () => {
        this.props.setIsiKanan((
            <>
            <List>
                <Searchbar
                    searchContainer=".search-list"
                    placeholder="Nama Wilayah"
                    className="searchbar-demo"
                    searchIn=".item-title"
                    onSubmit={this.cariKeyword}
                ></Searchbar>
                <ListItem
                    title="Bentuk"
                    smartSelect
                    smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Saring Bentuk'}}
                >
                    <select onChange={this.setParamValue} name="bentuk_pendidikan_id" defaultValue={localStorage.getItem('jenjang_aplikasi')}>
                        {localStorage.getItem('jenjang_aplikasi').includes('-') && <option value="5-6-13-15-29">Semua</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('5') && <option value="5">SD</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('6') && <option value="6">SMP</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('5-6') && <option value="5-6">SD/SMP</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('13') && <option value="13">SMA</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('15') && <option value="15">SMK</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('13-15') && <option value="13-15">SMA/SMK</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('29') && <option value="29">SLB</option>}
                    </select>
                </ListItem>
                <ListItem
                    title="Status"
                    smartSelect
                    smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Saring Status'}}
                >
                    <select onChange={this.setParamValue} name="status_sekolah" defaultValue="semua">
                        <option value="99">Semua</option>
                        <option value="1">Negeri</option>
                        <option value="2">Swasta</option>
                    </select>
                </ListItem>
            </List>
            <List>  
                <ListItem style={{cursor:'pointer'}} title="Unduh Excel" onClick={()=>{window.open(
                        localStorage.getItem('api_base')+"/api/GTK/getPTKExcel"
                        +"?semester_id="+localStorage.getItem('semester_id_aplikasi')
                        +"&tahun_ajaran_id="+localStorage.getItem('semester_id_aplikasi').substring(0,4)
                        +"&id_level_wilayah="+(this.state.routeParams.id_level_wilayah)
                        +"&kode_wilayah="+(this.state.routeParams.kode_wilayah)
                        +"&bentuk_pendidikan_id="+(this.state.routeParams.bentuk_pendidikan_id ? this.state.routeParams.bentuk_pendidikan_id : '')
                        +"&status_sekolah="+(this.state.routeParams.status_sekolah ? this.state.routeParams.status_sekolah : '')
                        +"&keyword="+(this.state.routeParams.keyword ? this.state.routeParams.keyword : '')
                        +"&limit=20000"
                    )}}>
                    <img slot="media" src="static/icons/xls.png" width="25" />
                </ListItem>
            </List>
            </>
        ));
    }


    formatAngka = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : num;
    }

    cariKeyword = (event)  => {
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                keyword: event.target[0].value
            }
        },()=>{
            this.props.getPTK(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        })
    }

    klikNext = () => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: this.state.routeParams.page + 1,
                start: (parseInt(this.state.routeParams.start) + parseInt(this.state.routeParams.limit))
            }
        },()=>{
            this.props.getPTK(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }
    
    klikPrev = () => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: this.state.routeParams.page - 1,
                start: (parseInt(this.state.routeParams.start) - parseInt(this.state.routeParams.limit))
            }
        },()=>{
            this.props.getPTK(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }


    setParamValue = (b) => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: 0,
                start: 0,
                [b.target.getAttribute('name')]: b.target.value
            }
        },()=>{
            this.props.getPTK(this.state.routeParams).then(()=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }

    setFilterKabupaten = (event) => {
        const kode_wilayah = event.target.value;
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: 0,
                start: 0,
                id_level_wilayah: 2,
                kode_kabupaten: kode_wilayah,
                kode_kecamatan: undefined
            }
        }, () => {
            const routeParams = {
                id_level_wilayah: 3,
                mst_kode_wilayah: kode_wilayah
            }
            
            this.getSekolah(kode_wilayah);

            this.props.getWilayah(routeParams).then((e)=>{
                this.setState({ wilayah_kec: e.payload.rows });
            });

            this.props.getPTK(this.state.routeParams).then(()=>{
                this.setState({
                    loading: false
                });
            });
        })
    }

    setFilterKecamatan = (event) => {
        const kode_wilayah = event.target.value;
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: 0,
                start: 0,
                id_level_wilayah: 3,
                kode_kecamatan: kode_wilayah
            }
        }, () => {
            this.getSekolah(kode_wilayah);

            this.props.getPTK(this.state.routeParams).then(()=>{
                this.setState({
                    loading: false
                });
            });
        })
    }

    // setFilterSekolah = () => {

    // }

    bukaPengaturan = () => {
        this.props.setJudulKanan('Data Individu PTK');
    }

    render()
    {
        return (
            <Page name="DataIndividuPtk" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>{this.props.wilayah.rows[0].nama}</NavTitle>
                    <NavTitleLarge>
                        Data Individu PTK
                    </NavTitleLarge>
                    <Subnavbar>
                        <Segmented raised>
                            <Button tabLink="#tab1" tabLinkActive>PTK</Button>
                            <Button tabLink="#tab2" href={`/data-individu/pd`}>PD</Button>
                        </Segmented>
                    </Subnavbar>
                </Navbar>
                <Row>
                    { levelWilayah === 1 && 
                        <Col width={50} tabletWidth={25}>
                            <List style={{ margin: 0 }}>
                                <ListItem
                                    title="Kab/Kota"
                                    smartSelect
                                    smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Pencarian'}}
                                >
                                    <select onChange={this.setFilterKabupaten} name="kode_kabupaten" defaultValue="semua">
                                        <option value="">Semua</option>
                                        { this.state.wilayah_kab.map((opt, key) => <option key={key} value={opt.kode_wilayah}>{ opt.nama }</option>)}
                                    </select>
                                </ListItem>
                            </List>
                        </Col>
                    }
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Kecamatan"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Pencarian'}}
                            >
                                <select onChange={this.setFilterKecamatan} name="kode_kecamatan" defaultValue="semua">
                                    <option value="">Semua</option>
                                    { this.state.wilayah_kec.map((opt, key) => <option key={key} value={opt.kode_wilayah}>{ opt.nama }</option>)}
                                </select>
                            </ListItem>
                        </List>
                    </Col>
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Sekolah"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Pencarian'}}
                            >
                                <select onChange={this.setParamValue} name="sekolah_id" defaultValue="semua">
                                    <option value="">Semua</option>
                                    { this.state.sekolah.map((opt, key) => <option key={key} value={opt.sekolah_id}>{ opt.nama }</option>)}
                                </select>
                            </ListItem>
                        </List>
                    </Col>
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Limit"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Saring Status'}}
                            >
                                <select onChange={this.setParamValue} name="limit" defaultValue={this.state.routeParams.limit}>
                                    {
                                        this.state.limit.map((opt, key) => <option key={key} value={opt}>{ opt }</option>)
                                    }
                                </select>
                            </ListItem>
                        </List>
                    </Col>
                </Row>
                <Block strong style={{
                    marginTop:'0px', 
                    paddingLeft:'0px', 
                    paddingRight:'0px', 
                    paddingTop:'0px', 
                    paddingBottom:'0px'
                }}>
                    <div className="data-table" style={{overflowY:'hidden'}}>
                        <div className="data-table-footer" style={{display:'block'}}>
                            <div className="data-table-pagination">
                                <a onClick={this.klikPrev} href="#" className={"link "+(this.state.routeParams.start < 1 ? "disabled" : "" )}>
                                    <i className="icon icon-prev color-gray"></i>
                                </a>
                                <a onClick={this.klikNext} href="#" className={"link "+((parseInt(this.state.routeParams.start)+20) > parseInt(this.props.individu_ptk.total) ? "disabled" : "" )}>
                                    <i className="icon icon-next color-gray"></i>
                                </a>
                                <span className="data-table-pagination-label">{(this.state.routeParams.start+1)}-{(this.state.routeParams.start)+parseInt(this.state.routeParams.limit)} dari {this.formatAngka(this.props.individu_ptk.total)} PTK</span>
                            </div>
                        </div>
                        <table>
                            <thead style={{background:'#eeeeee'}}>
                                <tr>
                                    <td>No</td>
                                    {
                                        header.map((opt, key) => {
                                            return (
                                                <th key={key} className="label-cell">{ opt.label }</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loading ?
                            <>
                                {this.props.dummy_rows.rows.map(()=>{
                                    return (
                                        <tr>
                                            {header.map((opt, key) => <td key={key} className="label-cell skeleton-text skeleton-effect-blink font-12">loremipsum</td>)}
                                        </tr>
                                    )
                                })}
                            </>
                            :
                            <>
                            {this.props.individu_ptk.rows.map((option, key)=>{
                                const no = (this.state.routeParams.page * this.state.routeParams.limit) + (key + 1);
                                return(
                                    <tr key={option.kode_wilayah}>
                                        <td>{ no }</td>
                                        {
                                            header.map((opt, key) => {
                                                return (
                                                    <td key={key} className="label-cell font-12" >{option[opt.id]}</td>
                                                )
                                            })
                                            }
                                    </tr>
                                )
                            })}
                            </>
                            }
                            </tbody>
                        </table>
                        <div className="data-table-footer" style={{display:'block'}}>
                            <div className="data-table-pagination">
                                <a onClick={this.klikPrev} href="#" className={"link "+(this.state.routeParams.start < 1 ? "disabled" : "" )}>
                                    <i className="icon icon-prev color-gray"></i>
                                </a>
                                <a onClick={this.klikNext} href="#" className={"link "+((parseInt(this.state.routeParams.start)+20) > parseInt(this.props.individu_ptk.total) ? "disabled" : "" )}>
                                    <i className="icon icon-next color-gray"></i>
                                </a>
                                <span className="data-table-pagination-label">{(this.state.routeParams.start+1)}-{(this.state.routeParams.start)+parseInt(this.state.routeParams.limit)} dari {this.formatAngka(this.props.individu_ptk.total)} PTK</span>
                            </div>
                        </div>
                    </div>
                </Block>
            </Page>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      updateWindowDimension: Actions.updateWindowDimension,
      setLoading: Actions.setLoading,
      setTabActive: Actions.setTabActive,
      getSekolah: Actions.getSekolah,
      getRekapSekolah: Actions.getRekapSekolah,
      getSekolahIndividu: Actions.getSekolahIndividu,
      getWilayah: Actions.getWilayah,
      getPTK: Actions.getPTK,
      setJudulKanan: Actions.setJudulKanan,
      setIsiKanan: Actions.setIsiKanan
    }, dispatch);
}

function mapStateToProps({ App, PesertaDidik }) {

    return {
        window_dimension: App.window_dimension,
        loading: App.loading,
        tabBar: App.tabBar,
        sekolah: App.sekolah,
        rekap_sekolah: App.rekap_sekolah,
        sekolah_individu: App.sekolah_individu,
        wilayah: App.wilayah,
        dummy_rows: App.dummy_rows,
        individu_ptk: PesertaDidik.individu_ptk,
        judul_panel_kanan: App.judul_panel_kanan,
        isi_panel_kanan: App.isi_panel_kanan
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(DataIndividuptk));