import React, {Component} from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Block,
    Button,
    Icon,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem,
    Subnavbar,
    Segmented,
} from 'framework7-react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';
import SelectSemester from '../SelectSemester';

const colums = [
    {
        id: 'total_pd',
        title : 'Total PD'
    },
    {
        id: 'terima_kip',
        title : 'Terima KIP'
    },
    {
        id: 'tidak_terima_kip',
        title : 'Tidak Terima KIP'
    },
    {
        id: 'terima_kip_1',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_1',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_2',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_2',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_3',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_3',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_4',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_4',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_5',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_5',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_6',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_6',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_7',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_7',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_8',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_8',
        title: 'Tidak'
    },
    {
        id: 'terima_kip_9',
        title: 'Terima'
    },
    {
        id: 'tidak_terima_kip_9',
        title: 'Tidak'
    },
]

class PesertaDidikKIP extends Component {
    state = {
        error: null,
        loading: true,
        routeParams: {
            kode_wilayah: this.$f7route.params['kode_wilayah'] ? this.$f7route.params['kode_wilayah'] : localStorage.getItem('kode_wilayah_aplikasi'),
            id_level_wilayah: this.$f7route.params['id_level_wilayah'] ? this.$f7route.params['id_level_wilayah']: localStorage.getItem('id_level_wilayah_aplikasi'),
            semester_id: localStorage.getItem('semester_id_aplikasi'),
            tahun_ajaran_id: localStorage.getItem('semester_id_aplikasi').substring(0,4),
            bentuk_pendidikan_id: localStorage.getItem('jenjang_aplikasi'),
            status_sekolah: '99'
        }
    }

    componentDidMount = () => {
        this.setState({
            routeParams: {
                kode_wilayah: this.state.routeParams.kode_wilayah
            }
        },()=>{
            this.props.getWilayah(this.state.routeParams).then((result)=>{
                this.setState({
                    routeParams: {
                        kode_wilayah: this.$f7route.params['kode_wilayah'] ? this.$f7route.params['kode_wilayah'] : localStorage.getItem('kode_wilayah_aplikasi'),
                        id_level_wilayah: this.$f7route.params['id_level_wilayah'] ? this.$f7route.params['id_level_wilayah']: localStorage.getItem('id_level_wilayah_aplikasi'),
                        semester_id:localStorage.getItem('semester_id_aplikasi'),
                        tahun_ajaran_id:localStorage.getItem('semester_id_aplikasi').substring(0,4),
                        bentuk_pendidikan_id: localStorage.getItem('jenjang_aplikasi'),
                        status_sekolah: '99'
                    }
                },()=>{
                    this.props.getPenerimaKIP(this.state.routeParams).then((result)=>{
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });
                })
            })
        });
    }

    formatAngka = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : num;
    }

    cariKeyword = (event)  => {
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                keyword: event.target[0].value
            }
        },()=>{
            this.props.getPenerimaKIP(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        })
    }

    setParamValue = (b) => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                [b.target.getAttribute('name')]: b.target.value
            }
        },()=>{
            this.props.getPenerimaKIP(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }

    bukaPengaturan = () => {        
        this.props.setJudulKanan('Menu Peserta Didik KIP');
    }

    render()
    {
        return (
            <Page name="PesertaDidikAgama" hideBarsOnScroll>
                {this.state.routeParams.kode_wilayah === localStorage.getItem('kode_wilayah_aplikasi') &&
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>{this.props.wilayah.rows[0].nama}</NavTitle>
                    <NavTitleLarge>
                        KIP Peserta Didik
                    </NavTitleLarge>
                    <Subnavbar>
                        <Segmented raised>
                            <Button tabLink="#tab1" tabLinkActive>Per Wilayah</Button>
                            <Button tabLink="#tab2" href={"/PesertaDidik/AgamaSp/"+localStorage.getItem('id_level_wilayah_aplikasi')+"/"+localStorage.getItem('kode_wilayah_aplikasi')}>Per Sekolah</Button>
                        </Segmented>
                    </Subnavbar>
                    <NavRight >
                        <Link panelOpen="right" onClick={this.bukaPengaturan} iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu">&nbsp;Menu</Link>
                    </NavRight>
                </Navbar>
                }
                {this.state.routeParams.kode_wilayah !== localStorage.getItem('kode_wilayah_aplikasi') &&
                <Navbar sliding={false}>
                    <NavLeft >
                        <Link iconIos="f7:chevron_left" iconAurora="f7:chevron_left" iconMd="material:chevron_left" href={(parseInt(this.state.routeParams.id_level_wilayah) === 1 ? "/PesertaDidik/Agama" : "/PesertaDidik/Agama/1/"+this.state.routeParams.kode_wilayah.substring(0,2) + "0000" )}>Kembali</Link>
                    </NavLeft>
                    <NavTitle sliding>{this.props.wilayah.rows[0].nama}</NavTitle>
                    <NavTitleLarge>
                        Peserta Didik KIP
                    </NavTitleLarge>
                    <Subnavbar>
                        <Segmented raised>
                            <Button tabLink="#tab1" tabLinkActive>Per Wilayah</Button>
                            <Button tabLink="#tab2" href={(parseInt(this.state.routeParams.id_level_wilayah) === 1 ? "/PesertaDidik/AgamaSp/1/"+this.state.routeParams.kode_wilayah : "/PesertaDidik/Agama/2/"+this.state.routeParams.kode_wilayah)}>Per Sekolah</Button>
                        </Segmented>
                    </Subnavbar>
                    <NavRight >
                        <Link panelOpen="right" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu">&nbsp;Menu</Link>
                    </NavRight>
                </Navbar>
                }
                <SelectSemester/>
                <Block strong style={{
                    marginTop:'0px', 
                    paddingLeft:'0px', 
                    paddingRight:'0px', 
                    paddingTop:'0px', 
                    paddingBottom:'0px'
                }}>
                    <div className="data-table" style={{overflowY:'hidden'}}>
                        <table>
                            <thead style={{background:'#eeeeee'}}>
                                <tr>
                                    <th className="label-cell" colSpan="2" rowSpan="2" style={{minWidth:'40px'}}>Wilayah</th>
                                    <th className="label-cell" colSpan="3" style={{minWidth:'40px'}}>&nbsp;</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 1</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 2</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 3</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 4</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 5</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 6</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 7</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 8</th>
                                    <th className="label-cell" colSpan="2" style={{minWidth:'40px', textAlign: 'center'}}>Kelas 9</th>
                                </tr>
                                <tr>
                                    {
                                        colums.map((headers, kh) =>
                                            <th key={kh} className="lebel-cell">{ headers.title }</th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loading ?
                            <>
                                {this.props.dummy_rows.rows.map((option)=>{
                                    return (
                                        <tr>
                                            <td className="label-cell skeleton-text skeleton-effect-blink">
                                                loremipsum
                                            </td>
                                            <td className="label-cell skeleton-text skeleton-effect-blink">lorenipsumlorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                            <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                        </tr>
                                    )
                                })}
                            </>
                            :
                            <>
                            {this.props.peserta_didik_kip.rows.map((option)=>{
                                return(
                                    <tr key={option.kode_wilayah}>
                                        <td className="label-cell">
                                            <MenuItem style={{marginLeft: 'auto', marginTop: '4px', marginBottom: '4px'}} iconF7="menu" dropdown className="MenuDetail">
                                                <MenuDropdown left style={{zIndex:999999}}>
                                                    <MenuDropdownItem href={"/PesertaDidik/Agama/"+option.id_level_wilayah+"/"+option.kode_wilayah} onClick={()=>this.dataRaporWilayah(option.kode_wilayah)}>
                                                        <span>Rekap Wilayah {option.nama}</span>
                                                        <Icon className="margin-left" f7="bookmark" />
                                                    </MenuDropdownItem>
                                                    <MenuDropdownItem href={"/PesertaDidik/AgamaSp/"+(parseInt(option.id_level_wilayah))+"/"+option.kode_wilayah}>
                                                        <span>Rekap Sekolah {option.nama}</span>
                                                        <Icon className="margin-left" f7="bookmark" />
                                                    </MenuDropdownItem>
                                                </MenuDropdown>
                                            </MenuItem>
                                        </td>
                                        <td className="text-cell">{ option.nama }</td>
                                        {/* <td className="label-cell">
                                            {option.nama}<br/>
                                            {parseInt(option.id_level_wilayah) === 1 && <span></span>}
                                            {parseInt(option.id_level_wilayah) === 2 && <span style={{fontSize:'11px', color:'#434343'}}>{option.induk_propinsi}</span>}
                                            {parseInt(option.id_level_wilayah) === 3 && <span style={{fontSize:'11px', color:'#434343'}}>{option.induk_kabupaten}</span>}
                                        </td> */}
                                        {
                                            colums.map((opt, kb) =>
                                                <td key={kb} className="numeric-cell">{this.formatAngka(parseInt(option[opt.id]))}</td>
                                            )
                                        }
                                    </tr>
                                )
                            })}
                            </>
                            }
                            </tbody>
                        </table>
                        <div className="data-table-footer" style={{display:'block', height:'75px'}}>
                            <div className="data-table-pagination">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </Block>
            </Page>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension           : Actions.updateWindowDimension,
        setLoading                      : Actions.setLoading,
        setTabActive                    : Actions.setTabActive,
        getSekolah                      : Actions.getSekolah,
        getRekapSekolah                 : Actions.getRekapSekolah,
        getSekolahIndividu              : Actions.getSekolahIndividu,
        getWilayah                      : Actions.getWilayah,
        getPenerimaKIP                  : Actions.getPenerimaKIP,
    }, dispatch);
}

function mapStateToProps({ App, PesertaDidik }) {

    return {
        window_dimension                : App.window_dimension,
        loading                         : App.loading,
        tabBar                          : App.tabBar,
        sekolah                         : App.sekolah,
        rekap_sekolah                   : App.rekap_sekolah,
        sekolah_individu                : App.sekolah_individu,
        wilayah                         : App.wilayah,
        dummy_rows                      : App.dummy_rows,
        peserta_didik_kip               : PesertaDidik.peserta_didik_kip,
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(PesertaDidikKIP));