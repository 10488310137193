import React, { Component } from 'react';
import { Page, Navbar, NavTitle, Link, Popover, Block, NavTitleLarge, NavRight } from 'framework7-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';

class SaprasRekapRuangKelas extends Component {
    state = {
        error: null,
        loading: true,
    }

    componentDidMount = () => {
        this.props.getRekap();
    }

    handleClickCell = (status, bentuk, kode_kecamatan) => {
        const link = `/Sarpras/RekapRuangKelas/${kode_kecamatan.trim()}/${bentuk}/${status}`;
        this.$f7router.navigate(link);
    }

    render() {
        const { rows } = this.props.rekap;
        let total = {
            tk_n : 0,
            tk_s : 0,
            tk_kb_tpa_n : 0,
            tk_kb_tpa_s : 0,
            tk_kb_tpa_jumlah : 0,
            sd_n : 0,
            sd_s : 0,
            mi_n : 0,
            mi_s : 0,
            sd_mi_jumlah : 0,
            smp_n : 0,
            smp_s : 0,
            mts_n : 0,
            mts_s : 0,
            jumlah : 0,
            pkbm : 0,
        }

        return (
            <Page name="apk-apm" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>----</NavTitle>
                    <NavTitleLarge style={{background:'#F6BC31',color:'white'}}>
                        Rekap Ruang Kelas
                    </NavTitleLarge>
                    <NavRight >
                        <Link panelOpen="right" onClick={this.bukaPengaturan} iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu">&nbsp;Menu</Link>
                    </NavRight>
                </Navbar>

                <div>
                    <Block strong style={{
                        marginTop:'0px', 
                        paddingLeft:'0px', 
                        paddingRight:'0px', 
                        paddingTop:'0px', 
                        paddingBottom:'0px'
                    }}>
                        <div className="data-table some_style" style={{overflowY:'hidden'}}>
                            <table style={{  border: '1px solid #FFF', minWidth: 1400 }}>
                                <thead style={{ backgroundColor: '#222a35', textAlign: 'center' }}>
                                    <tr>
                                        <th className="text-white" rowSpan={3}>No</th>
                                        <th className="text-white" rowSpan={3}>Kecamatan</th>
                                        <th className="text-white" colSpan={16}>Jenjang Pendidikan</th>
                                    </tr>
                                    <tr style={{ backgroundColor: '#0270bb' }}>
                                        <th className="text-white" colSpan={2}>TK</th>
                                        <th className="text-white" colSpan={2}>SPS/KB/TPA</th>
                                        <th className="text-white" rowSpan={2}>Jumlah</th>
                                        <th className="text-white" colSpan={2}>SD</th>
                                        <th className="text-white" colSpan={2}>MI</th>
                                        <th className="text-white" rowSpan={2}>Jumlah</th>
                                        <th className="text-white" colSpan={2}>SMP</th>
                                        <th className="text-white" colSpan={2}>MTs</th>
                                        <th className="text-white" rowSpan={2}>Jumlah</th>
                                        <th className="text-white" rowSpan={2}>PKBM</th>
                                    </tr>
                                    <tr style={{ backgroundColor: '#0270bb' }}>
                                        <th className="text-white">Negeri</th>
                                        <th className="text-white">Swasta</th>
                                        <th className="text-white">Negeri</th>
                                        <th className="text-white">Swasta</th>

                                        <th className="text-white">Negeri</th>
                                        <th className="text-white">Swasta</th>
                                        <th className="text-white">Negeri</th>
                                        <th className="text-white">Swasta</th>

                                        <th className="text-white">Negeri</th>
                                        <th className="text-white">Swasta</th>
                                        <th className="text-white">Negeri</th>
                                        <th className="text-white">Swasta</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    { rows.map((opt, key) => {
                                        total['tk_n'] = total['tk_n'] + parseInt(opt.tk_n);
                                        total['tk_s'] = total['tk_s'] + parseInt(opt.tk_s);
                                        total['tk_kb_tpa_n'] = total['tk_kb_tpa_n'] + parseInt(opt.tk_kb_tpa_n);
                                        total['tk_kb_tpa_s'] = total['tk_kb_tpa_s'] + parseInt(opt.tk_kb_tpa_s);
                                        total['tk_kb_tpa_jumlah'] = total['tk_kb_tpa_jumlah'] + parseInt(opt.tk_kb_tpa_jumlah);
                                        total['sd_n'] = total['sd_n'] + parseInt(opt.sd_n);
                                        total['sd_s'] = total['sd_s'] + parseInt(opt.sd_s);
                                        total['mi_n'] = total['mi_n'] + parseInt(opt.mi_n);
                                        total['mi_s'] = total['mi_s'] + parseInt(opt.mi_s);
                                        total['sd_mi_jumlah'] = total['sd_mi_jumlah'] + parseInt(opt.sd_mi_jumlah);
                                        total['smp_n'] = total['smp_n'] + parseInt(opt.smp_n);
                                        total['smp_s'] = total['smp_s'] + parseInt(opt.smp_s);
                                        total['mts_n'] = total['mts_n'] + parseInt(opt.mts_n);
                                        total['mts_s'] = total['mts_s'] + parseInt(opt.mts_s);
                                        total['jumlah'] = total['jumlah'] + parseInt(opt.jumlah);
                                        total['pkbm'] = total['pkbm'] + parseInt(opt.pkbm);

                                        return (
                                            <tr key={key}>
                                                <td width='5%'>{ key + 1 }</td>
                                                <td width='15%'>{ opt.kecamatan }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri', '1', opt.kode_kecamatan)}>{ opt.tk_n }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('swasta', '1', opt.kode_kecamatan)}>{ opt.tk_s }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri', '2-3-4', opt.kode_kecamatan)}>{ opt.tk_kb_tpa_n }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('swasta', '2-3-4', opt.kode_kecamatan)}>{ opt.tk_kb_tpa_s }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri-swasta', '1-2-3-4', opt.kode_kecamatan)}>{ opt.tk_kb_tpa_jumlah }</td>

                                                <td width='5%' onClick={() => this.handleClickCell('negeri', '5', opt.kode_kecamatan)}>{ opt.sd_n }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('swasta', '6', opt.kode_kecamatan)}>{ opt.sd_s }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri', '9', opt.kode_kecamatan)}>{ opt.mi_n }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('swasta', '9', opt.kode_kecamatan)}>{ opt.mi_s }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri-swasta', '5-9', opt.kode_kecamatan)}>{ opt.sd_mi_jumlah }</td>

                                                <td width='5%' onClick={() => this.handleClickCell('negeri', '6', opt.kode_kecamatan)}>{ opt.smp_n }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('swasta', '6', opt.kode_kecamatan)}>{ opt.smp_s }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri', '10', opt.kode_kecamatan)}>{ opt.mts_n }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('swasta', '10', opt.kode_kecamatan)}>{ opt.mts_s }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri-swasta', '6-10', opt.kode_kecamatan)}>{ opt.jumlah }</td>
                                                <td width='5%' onClick={() => this.handleClickCell('negeri-swasta', '27', opt.kode_kecamatan)}>{ opt.pkbm }</td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <th>{ total['tk_n'] }</th>
                                        <th>{ total['tk_s'] }</th>
                                        <th>{ total['tk_kb_tpa_n'] }</th>
                                        <th>{ total['tk_kb_tpa_s'] }</th>
                                        <th>{ total['tk_kb_tpa_jumlah'] }</th>
                                        <th>{ total['sd_n'] }</th>
                                        <th>{ total['sd_s'] }</th>
                                        <th>{ total['mi_n'] }</th>
                                        <th>{ total['mi_s'] }</th>
                                        <th>{ total['sd_mi_jumlah'] }</th>
                                        <th>{ total['smp_n'] }</th>
                                        <th>{ total['smp_s'] }</th>
                                        <th>{ total['mts_n'] }</th>
                                        <th>{ total['mts_s'] }</th>
                                        <th>{ total['jumlah'] }</th>
                                        <th>{ total['pkbm'] }</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </Block>
                </div>
                <Popover className="popover-menu">
                    Popover
                </Popover>
            </Page>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension       : Actions.updateWindowDimension,
        getRekap                    : Actions.getRekapRuangKelas,
        setLoading                  : Actions.setLoading,
    }, dispatch);
}

function mapStateToProps({ App }) {

    return {
        window_dimension            : App.window_dimension,
        rekap                       : App.rekap_ruang_kelas,
        loading                     : App.loading,
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(SaprasRekapRuangKelas));