import React, { Component } from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    Link,
    Row,
    Col,
    Button,
    Icon,
    Popover,
    Progressbar,
} from 'framework7-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';
import ApkApmTable from "./ApkApmTable";
import ApkApmTableInput from "./ApkApmTableInput";
import dataInputPaudAPK from "../dataInputPaudAPK";
import dataInputSdAPK from "../dataInputSdAPK";
import dataInputSmpAPK from '../dataInputSmpAPK';
import dataInputPaudAPM from '../dataInputPaudAPM_';
import dataInputSdAPM from '../dataInputSdAPM';
import dataInputSmpAPM from '../dataInputSmpAPM';
import dataInputSMA from '../dataInputSmaAPK';
import dataInputPaud04 from '../dataInputPaudAPK04'
import dataInputSMAAPM from '../dataInputSmaAPM';
import dataInputPaud04APM from '../dataInputPaudAPM04'


class APKAPM extends Component {
    state = {
        error: null,
        loading: false,
        datas: [],
        selectedValue: {
            value: 1,
            modul: dataInputPaudAPK,
        },
        param: {
            modulTitle: "",
            isInput: false,
        }
    }

    componentDidMount = () => {
        console.log('1');
        this.setState({ 
            loading: true,
            selectedValue: {
                value: 1,
                modul: (this.props.type === 'apk' ? dataInputPaudAPK : dataInputPaudAPM)
            }
        }, () => {
            console.log('2');
        });
        console.log('3');
        this.props.getData({ jenjang_id: this.state.selectedValue.value }).then(({ payload }) => {
            this.setState({ datas: payload, loading: false })
        });

        if (parseInt(localStorage.getItem('sudah_login')) !== 1 && localStorage.getItem('harus_login') === 'Y') {
            this.$f7router.navigate('/login');
        }

        localStorage.setItem('current_url', this.$f7route.url);
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.selectedValue){
            if(prevState.selectedValue.value != this.state.selectedValue.value){
                this.setState({ loading: true });
                this.props.getData({ jenjang_id: this.state.selectedValue.value }).then(({ payload }) => {
                    this.setState({ datas: payload, loading: false })
                });
            }
        }
    }

    handleChange = (e) => {
        const { value } = e.target;
        const { type } = this.props;
        let page = {};

        switch (parseInt(value)) {
            case 0: page = type === 'apk' ? dataInputPaud04 : dataInputPaud04APM ; break;
            case 1: page = type === 'apk' ? dataInputPaudAPK : dataInputPaudAPM ; break;
            case 2: page = type === 'apk' ? dataInputSdAPK : dataInputSdAPM ; break;
            case 3: page = type === 'apk' ? dataInputSmpAPK : dataInputSmpAPM ; break;
            case 4: page = type === 'apk' ? dataInputSMA : dataInputSMAAPM ; break;
        
            default: page = {}; break;
        }

        this.setState({
            ...this.state,
            selectedValue: {
                value: value,
                modul: page,
            }
        })
    }

    buttonInput = (data) => {
        this.setState({
            ...this.state,
            param: {
                modulTitle: data,
                isInput: true,
            }
        }, () => {
        })
    }

    buttonSimpan = () => {
        const data = {
            data: this.state.datas,
            jenjang_id: this.state.selectedValue.value
        }

        this.setState({ loading: true })
        this.props.saveDataInputPakApm(data).then(() => {
            this.setState({
                ...this.state,
                loading: false,
                param: {
                    isInput: false,
                }
                }, () => {
            })
        })
    }

    setDataApkApm = (e) => {
        this.setState({ datas: e })
    }

    render() {
        const { selectedValue, param, datas, loading } = this.state;
        const { modul, value } = selectedValue;
        const { data, type } = this.props;

        return (
            <Page name="apk-apm" hideBarsOnScroll>
                <Navbar sliding={false}>
                    <NavLeft>
                        <Link iconIos="f7:chevron_left" iconAurora="f7:chevron_left" iconMd="material:chevron_left" href="/">Beranda</Link>
                    </NavLeft>
                    <NavTitle sliding>{ type ? type.toUpperCase() : '-' }</NavTitle>
                </Navbar>
                { loading && <Progressbar infinite color="multi" id="demo-inline-progressbar" /> }
                <div style={{ padding: "2% 3%" }}>
                    <Row noGap style={{ gap: "10px" }}>
                        <Col width="100" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: "32px" }}>
                                <b>Jenis Rekap Data Input</b>
                                <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                                    <div className="bg-white item-content item-input item-input-outline item-input-with-value">
                                        <div className="item-inner">
                                            <div className="item-input-wrap input-dropdown">
                                                <select
                                                    defaultValue={1}
                                                    value={this.state.selectedValue.value}
                                                    className="input-with-value"
                                                    style={{ height: "35px", width: "200px", cursor: "pointer" }}
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="DEFAULT" disabled>Pilih Jenis Rekap Data ...</option>
                                                    <option value={0}>PAUD</option>
                                                    <option value={1}>TK</option>
                                                    <option value={2}>SD/MI/Paket A</option>
                                                    <option value={3}>SMP/MTs/Paket B</option>
                                                    <option value={4}>SMA/SMK</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Link popoverOpen=".popover-menu" style={{ background: "#4486ff", borderRadius: "100%", color: "#fff", height: "30px", aspectRatio: "1/1" }}>
                                        <Icon size="16" f7="info" />
                                    </Link> */}
                                </div>
                            </div>
                            <div>
                                {!param.isInput && (
                                    <Button
                                        fill
                                        style={{ background: "#2BA579" }}
                                        onClick={() => this.buttonInput(value)}
                                    >
                                        <Icon size="16" f7="pencil_circle_fill" /> Input Isian Data
                                    </Button>

                                )}
                                {param.isInput && (
                                    <Button
                                        fill
                                        style={{ background: "#2BA579" }}
                                        onClick={() => this.buttonSimpan(value)}
                                    >
                                        <Icon size="16" f7="pencil_circle_fill" /> Simpan Isian Data
                                    </Button>

                                )}
                            </div>
                        </Col>
                        <Col width="100">
                            <div style={{ display: 'flex', alignItems: 'center', gap: "110px" }}>
                                <b>Tahun Data</b>
                                <div>
                                    {new Date().getFullYear()}
                                </div>
                            </div>
                            {!param.isInput && <ApkApmTable title={modul.title} columns={modul.columns} data={datas} loading={loading}/>}
                            {param.isInput && <ApkApmTableInput title={modul.title} columns={modul.columns} data={datas} loading={loading} setDataApkApm={(e) => this.setDataApkApm(e)} />}
                        </Col>
                    </Row>
                </div>

                { JSON.stringify(data) }
                <Popover className="popover-menu">
                    Popover
                </Popover>
            </Page>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension                   : Actions.updateWindowDimension,
        getData                                 : Actions.getDataInputPakApm,
        setLoading                              : Actions.setLoading,
        saveDataInputPakApm                     : Actions.saveDataInputPakApm
    }, dispatch);
}

function mapStateToProps({ App }) {

    return {
        window_dimension                        : App.window_dimension,
        loading                                 : App.loading,
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(APKAPM));