import * as Actions from '../actions';

const initialState = {
    rekap_gtk_ringkasan: {
        rows: [{
            kode_wilayah: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_ringkasan_sp: {
        rows: [{
            sekolah_id: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_agama: {
        rows: [{
            kode_wilayah: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_agama_sp: {
        rows: [{
            sekolah_id: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_status_kepegawaian: {
        rows: [{
            kode_wilayah: '---',
            nama: '---',
        }],
        total: 0
    },
    rekap_gtk_status_kepegawaian_sp: {
        rows: [{
            sekolah_id: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_jenis_kepegawaian: {
        rows: [{
            kode_wilayah: '---',
            nama: '---',
        }],
        total: 0
    },
    rekap_gtk_jenis_kepegawaian_sp: {
        rows: [{
            sekolah_id: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_satuan_pendidikan: {
        rows: [{
            kode_wilayah: '---',
            nama: '---',
        }],
        total: 0
    },
    rekap_gtk_satuan_pendidikan_sp: {
        rows: [{
            sekolah_id: '---',
            nama: '---'
        }],
        total: 0
    },
    rekap_gtk_sertifikasi: {
        rows: [{
            kode_wilayah: '---',
            nama: '---',
        }],
        total: 0
    },
    rekap_gtk_sertifikasi_sp: {
        rows: [{
            sekolah_id: '---',
            nama: '---'
        }],
        total: 0
    }
};


const RekapGTKReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_REKAP_GTK_RINGKASAN:
        {
            return {
                ...state,
                rekap_gtk_ringkasan: action.payload
            };
        }
        case Actions.GET_REKAP_GTK_RINGKASAN_SP:
        {
            return {
                ...state,
                rekap_gtk_ringkasan_sp: action.payload
            };
        }
        case Actions.GET_REKAP_GTK_AGAMA:
        {
            return {
                ...state,
                rekap_gtk_agama: action.payload
            };
        }
        case Actions.GET_REKAP_GTK_AGAMA_SP:
        {
            return {
                ...state,
                rekap_gtk_agama_sp: action.payload
            };
        }
        case Actions.GET_REKAP_GTK_STATUS_KEPEGAWAIAN:
        {
            return {
                ...state,
                rekap_gtk_status_kepegawaian: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_STATUS_KEPEGAWAIAN_SP:
        {
            return {
                ...state,
                rekap_gtk_status_kepegawaian_sp: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_JENIS_KEPEGAWAIAN:
        {
            return {
                ...state,
                rekap_gtk_jenis_kepegawaian: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_JENIS_KEPEGAWAIAN_SP:
        {
            return {
                ...state,
                rekap_gtk_jenis_kepegawaian_sp: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_SATUAN_PENDIDIKAN:
        {
            return {
                ...state,
                rekap_gtk_satuan_pendidikan: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_SATUAN_PENDIDIKAN_SP:
        {
            return {
                ...state,
                rekap_gtk_satuan_pendidikan_sp: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_SERTIFIKASI:
        {
            return {
                ...state,
                rekap_gtk_sertifikasi: action.payload
            }
        }
        case Actions.GET_REKAP_GTK_SERTIFIKASI_SP:
        {
            return {
                ...state,
                rekap_gtk_sertifikasi_sp: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
}

export default RekapGTKReducer;