import React from "react";

const dataInputSd = {
    title: `Input APM SD/MI/Pake A > Rekap Data Jumlah Penduduk Usia 7 - 12 Tahun dengan Jumlah Siswa SD`,
    data: [],
    columns: [
        {
            Header: 'No',
            rowSpan: 2,
            columns: [
                {
                    Header: 'NO',
                    skip:1,
                    Cell:({row}) => {
                        const { index } = row;
                        return index + 1;
                    },
                },
            ]
        },
        {
            // Header: 'Kecamatan',
            Header: () => {
                const kode_wilayah = localStorage.getItem('kode_wilayah_aplikasi');
                return(
                    kode_wilayah === '040000' ? "Kab/Kota" : "Kecamatan"
                )
            },
            id: 'kecamatan',
            rowSpan: 2,
            columns: [
                {
                    Header: '',
                    skip: 1,
                    accessor: 'kecamatan',
                    Cell: ({ row }) => (
                        <div style={{ textAlign: "left" }}>
                            {row.original.kecamatan}
                        </div>
                    )
                },
            ]
        },
        {
            Header: 'Jumlah Penduduk Umur 7-12',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'penduduk_7_12_l',
                    type: 'input',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'penduduk_7_12_p',
                    type: 'input',
                    align: 'center',
                },
                {
                    id: 'jml_ppd',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { penduduk_7_12_l, penduduk_7_12_p } = row.original;

                        const value = parseInt(penduduk_7_12_l || 0) + parseInt(penduduk_7_12_p || 0);

                        return (<span>{ value }</span>)
                    }
                },
            ]
        },
        {
            Header: 'Jumlah Seluruh SD',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_l_5',
                    type: 'input',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_p_5',
                    type: 'input',
                    align: 'center',
                },
                {
                    id: 'jumlah_5',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_5, murid_p_5 } = row.original;
                        const value = parseInt(murid_l_5 || 0) + parseInt(murid_p_5 || 0);

                        return (<span>{ value }</span>)
                    }
                }
            ]
        },
        {
            Header: 'Jumlah Seluruh MI',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_l_9',
                    type: 'input',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_p_9',
                    type: 'input',
                    align: 'center',
                },
                {
                    id: 'jumlah_9',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_9, murid_p_9 } = row.original;
                        const value = parseInt(murid_l_9 || 0) + parseInt(murid_p_9 || 0);

                        return (<span>{ value }</span>)
                    }
                },
            ]
        },
        {
            Header: 'Jumlah Seluruh Paket A',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_l_11',
                    type: 'input',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_p_11',
                    type: 'input',
                    align: 'center',
                },
                {
                    id: 'jumlah_11',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_11, murid_p_11 } = row.original;
                        const value = parseInt(murid_l_11 || 0) + parseInt(murid_p_11 || 0);

                        return (<span>{ value }</span>)
                    }
                },
            ]
        },
        {
            Header: 'Jumlah',
            align: 'center',
            columns: [
                {
                    id: 'jumlah_siswa_l_total',
                    Header: 'L',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_5, murid_l_9, murid_l_11 } = row.original;
        
                        const value = parseInt(murid_l_5 || 0) + parseInt(murid_l_9 || 0) + parseInt(murid_l_11 || 0);
        
                        return (<span>{ value }</span>)
                    }
                },
                {
                    id: 'jumlah_siswa_p_total',
                    Header: 'P',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_p_5, murid_p_9, murid_p_11 } = row.original;
        
                        const value = parseInt(murid_p_5 || 0) + parseInt(murid_p_9 || 0) + parseInt(murid_p_11 || 0);
        
                        return (<span>{ value }</span>)
                    }
                },
                {
                    id: 'jumlah_siswa_total',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_p_5, murid_l_5, murid_p_9, murid_l_9, murid_p_11, murid_l_11 } = row.original;
        
                        const value = parseInt(murid_p_5 || 0) + parseInt(murid_p_9 || 0) + parseInt(murid_l_5 || 0) + parseInt(murid_l_9 || 0) + parseInt(murid_l_11 || 0) + parseInt(murid_p_11 || 0);
        
                        return (<span>{ value }</span>)
                    }
                },
            ]
        }
    ]
}

export default dataInputSd;