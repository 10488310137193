import React from 'react';
import { Card, CardContent } from "framework7-react"
import { useTable } from 'react-table';
import Toolbar from './toolbar'

const SimpleTable = (props) => {
  console.log(props);
  const {data} = props;
  // const data = React.useMemo(
  //   () => [
  //     { id: 1, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '001', desa: 'Desa 001', kecamatan: 'Kec. 001', kab_kota: 'Kab. 001', provinsi: 'Prov. DI Yogyakarta', kode_pos: '001' },
  //     { id: 2, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '002', desa: 'Desa 002', kecamatan: 'Kec. 002', kab_kota: 'Kab. 002', provinsi: 'Prov. DI Yogyakarta', kode_pos: '002' },
  //     { id: 3, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '003', desa: 'Desa 003', kecamatan: 'Kec. 003', kab_kota: 'Kab. 003', provinsi: 'Prov. DI Yogyakarta', kode_pos: '003' },
  //     { id: 4, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '004', desa: 'Desa 004', kecamatan: 'Kec. 004', kab_kota: 'Kab. 004', provinsi: 'Prov. DI Yogyakarta', kode_pos: '004' },
  //     { id: 5, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '005', desa: 'Desa 005', kecamatan: 'Kec. 005', kab_kota: 'Kab. 005', provinsi: 'Prov. DI Yogyakarta', kode_pos: '005' },
  //     { id: 6, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '006', desa: 'Desa 006', kecamatan: 'Kec. 006', kab_kota: 'Kab. 006', provinsi: 'Prov. DI Yogyakarta', kode_pos: '006' },
  //     { id: 7, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '007', desa: 'Desa 007', kecamatan: 'Kec. 007', kab_kota: 'Kab. 007', provinsi: 'Prov. DI Yogyakarta', kode_pos: '007' },
  //     { id: 8, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '008', desa: 'Desa 008', kecamatan: 'Kec. 008', kab_kota: 'Kab. 008', provinsi: 'Prov. DI Yogyakarta', kode_pos: '008' },
  //     { id: 9, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '009', desa: 'Desa 009', kecamatan: 'Kec. 009', kab_kota: 'Kab. 009', provinsi: 'Prov. DI Yogyakarta', kode_pos: '009' },
  //     { id: 10, nama_peserta_didik: 'Fulan', tempat_lahir: 'Jakarta', tanggal_lahir: 'dd-mm-yyyy', pd_menurut_tempat_tinggal: 'Gunung Kidul', nama_sekolah: 'SD Negeri', npsn: '010', desa: 'Desa 010', kecamatan: 'Kec. 010', kab_kota: 'Kab. 010', provinsi: 'Prov. DI Yogyakarta', kode_pos: '010' },
  //   ],
  //   []
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'id',
        Cell: ({ row }) => <div style={{ textAlign: 'left' }}>{row.index + 1}</div>,
        style: { textAlign: 'left' },
      },
      { Header: 'Nama Peserta Didik', accessor: 'nama', style: { textAlign: 'left' } },
      { Header: 'NIK', accessor: 'nik', style: { textAlign: 'left' } },
      { Header: 'NISN', accessor: 'nisn', style: { textAlign: 'left' } },
      { Header: 'Tempat Lahir', accessor: 'tempat_lahir', style: { textAlign: 'left' } },
      { Header: 'Tanggal Lahir', accessor: 'tanggal_lahir', style: { textAlign: 'left' } },
      { Header: 'PD Menurut Tempat Tinggal (Domisili)', accessor: 'tempat_tinggal', style: { textAlign: 'left' } },
      { Header: 'Nama Sekolah', accessor: 'nama_sekolah', style: { textAlign: 'left' } },
      { Header: 'NPSN', accessor: 'npsn', style: { textAlign: 'left' } },
      { Header: 'Desa', accessor: 'desa_kelurahan', style: { textAlign: 'left' } },
      { Header: 'Kecamatan', accessor: 'kecamatan', style: { textAlign: 'left' } },
      { Header: 'Kab/Kota', accessor: 'kabupaten', style: { textAlign: 'left' } },
      { Header: 'Provinsi', accessor: 'provinsi', style: { textAlign: 'left' } },
      { Header: 'Kode Pos', accessor: 'kode_pos', style: { textAlign: 'left' } },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()} style={{ border: '1px solid #d1d1d1', width: '100%', marginTop: '12px' }} id="tableSebaranSekolahPenggerak">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} style={{ background: '#f2f2f2' }}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{ padding: '8px', ...column.style }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{
                borderBottom: '1px solid black',
                background: index % 2 === 0 ? '#ffffff' : '#f2f2f2', // Ganti warna sesuai kebutuhan
              }}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} style={{ padding: '8px', ...cell.column.style }}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SimpleTable;
