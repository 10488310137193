import axios from 'axios/index';

export const GET_APKAPM = '[NPD] GET APKAPM';
export const GET_ANGGARAN = '[NPD] GET ANGGARAN';
export const GET_AKREDITASI = '[NPD] GET AKREDITASI';
export const GET_GURU_MENURUT_USIA = '[NPD] GET GURU_MENURUT_USIA';
export const GET_KONDISI_RUANG_KELAS = '[NPD] GET KONDISI_RUANG_KELAS';
export const GET_KUALIFIKASI_PENDIDIKAN = '[NPD] GET KUALIFIKASI_PENDIDIKAN';
export const GET_SATUAN_PENDIDIKAN = '[NPD] GET SATUAN PENDIDIKAN';
export const GET_PENDIDIK = '[NPD] GET PENDIDIK';
export const GET_PESERTA_DIDIK = '[NPD] GET PESERTA DIDIK';
export const GET_RUANG_KELAS = '[NPD] GET RUANG KELAS';
export const GET_TENDIK = '[NPD] GET TENDIk';
export const GET_ROMBEL = '[NPD] GET ROMBEL';
export const GET_IPM = '[NPD] GET IPM';
export const GET_SISWA_MENGULANG = '[NPD] GET SISWA MENGULANG';

export function getApkapm(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/apkapm', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_APKAPM,
                payload: response.data,
                routeParams
            })
        );
}

export function getAnggaran(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/anggaran', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_ANGGARAN,
                payload: response.data,
                routeParams
            })
        );
}

export function getAkreditasi(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/akreditasi', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_AKREDITASI,
                payload: response.data,
                routeParams
            })
        );
}
export function getGurumenurutusia(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/gurumenurutusia', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_GURU_MENURUT_USIA,
                payload: response.data,
                routeParams
            })
        );
}
export function getKondisiruangkelas(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/kondisiruangkelas', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_KONDISI_RUANG_KELAS,
                payload: response.data,
                routeParams
            })
        );
}
export function getKualifikasipendidik(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/kualifikasipendidik', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_KUALIFIKASI_PENDIDIKAN,
                payload: response.data,
                routeParams
            })
        );
}

export function getSatuanPendidikan(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/satuanpendikan', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_SATUAN_PENDIDIKAN,
                payload: response.data,
                routeParams
            })
        );
}

export function getPendidik(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/pendidik', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_PENDIDIK,
                payload: response.data,
                routeParams
            })
        );
}

export function getPesertadidik(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/pesertadidik', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_PESERTA_DIDIK,
                payload: response.data,
                routeParams
            })
        );
}

export function getRuangkelas(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/ruangkelas', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_RUANG_KELAS,
                payload: response.data,
                routeParams
            })
        );
}

export function getTendik(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/tendik', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_TENDIK,
                payload: response.data,
                routeParams
            })
        );
}

export function getRombel(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/rombel', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_ROMBEL,
                payload: response.data,
                routeParams
            })
        );
}

export function getIPM(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/ipm', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_IPM,
                payload: response.data,
                routeParams
            })
        );
}

export function getSiswamengulang(routeParams)
{
    const request = axios.get(localStorage.getItem('api_base')+'/api/npd/siswaputusmengulang', {
        ...routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_SISWA_MENGULANG,
                payload: response.data,
                routeParams
            })
        );
}