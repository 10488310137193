import React, {Component} from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    Searchbar,
    Icon,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem,
    Subnavbar,
    Segmented,
} from 'framework7-react';

// import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";

import { Doughnut, Bar } from 'react-chartjs-2';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';
import SelectSemester from '../SelectSemester';

const sudah_login = parseInt(localStorage.getItem('sudah_login'));
const jenjang_aplikasi = localStorage.getItem('jenjang_aplikasi');
const jenjang_aplikasi_all = localStorage.getItem('jenjang_aplikasi_all');
const levelWilayah = localStorage.getItem('id_level_wilayah_aplikasi');

const header = [
    {
        id: 'nisn',
        label: 'NISN',
        color: '#007aff'
    },
    {
        id: 'nik',
        label: 'NIK',
        color: '#007aff'
    },
    {
        id: 'nama',
        label: 'Nama',
        color: '#007aff'
    },
    {
        id: 'jenis_kelamin',
        label: 'Jenis Kelamin',
        color: '#007aff'
    },
    {
        id: 'tempat_lahir',
        label: 'Tempat Lahir',
        color: '#007aff'
    },
    {
        id: 'tanggal_lahir',
        label: 'Tanggal Lahir',
        color: '#007aff'
    },
    {
        id: 'usia',
        label: 'Usia',
        color: '#007aff'
    },
    {
        id: 'agama',
        label: 'Agama',
        color: '#007aff'
    },
    {
        id: 'alamat_jalan',
        label: 'Alamat',
        color: '#ff9500'
    },
    {
        id: 'dusun',
        label: 'Dusun',
        color: '#ff9500'
    },
    {
        id: 'desa_kelurahan',
        label: 'Kelurahan',
        color: '#ff9500'
    },
    {
        id: 'kecamatan',
        label: 'Kecamatan',
        color: '#ff9500'
    },
    {
        id: 'kabupaten',
        label: 'Kabupaten',
        color: '#ff9500'
    },
    {
        id: 'provinsi',
        label: 'Provinsi',
        color: '#ff9500'
    },
    {
        id: 'lintang',
        label: 'Lintang',
        color: '#ff9500'
    },
    {
        id: 'bujur',
        label: 'Bujur',
        color: '#ff9500'
    },
    {
        id: 'nomor_telepon_seluler',
        label: 'HP',
        color: '#ff9500'
    },
    {
        id: 'email',
        label: 'E-Mail',
        color: '#ff9500'
    },
    {
        id: 'jenis_tinggal',
        label: 'Jenis Tinggal',
        color: '#ff9500'
    },
    {
        id: 'jarak_rumah_ke_sekolah',
        label: 'Jarak Rumah Ke Sekolah',
        color: '#ff9500'
    },
    {
        id: 'alat_transportasi',
        label: 'Alat Transportasi',
        color: '#ff9500'
    },
    {
        id: 'nama_ayah',
        label: 'Nama',
        color: '#4cd964'
    },
    {
        id: 'jenjang_pendidikan_ayah',
        label: 'Jenjang Pendidikan',
        color: '#4cd964'
    },
    {
        id: 'pekerjaan_ayah',
        label: 'Pekerjaan',
        color: '#4cd964'
    },
    {
        id: 'penghasilan_ayah',
        label: 'Penghasilan',
        color: '#4cd964'
    },
    {
        id: 'nik_ayah',
        label: 'NIK',
        color: '#4cd964'
    },
    {
        id: 'nama_ibu_kandung',
        label: 'Nama',
        color: '#cddc39'
    },
    {
        id: 'jenjang_pendidikan_ibu',
        label: 'Jenjang Pendidikan',
        color: '#cddc39'
    },
    {
        id: 'pekerjaan_ibu',
        label: 'Pekerjaan',
        color: '#cddc39'
    },
    {
        id: 'penghasilan_ibu',
        label: 'Penghasilan',
        color: '#cddc39'
    },
    {
        id: 'nik_ibu',
        label: 'NIK',
        color: '#cddc39'
    },
    {
        id: 'nama_wali',
        label: 'Nama',
        color: '#ffcc00'
    },
    {
        id: 'jenjang_pendidikan_wali',
        label: 'Jenjang Pendidikan',
        color: '#ffcc00'
    },
    {
        id: 'pekerjaan_wali',
        label: 'Pekerjaan',
        color: '#ffcc00'
    },
    {
        id: 'penghasilan_wali',
        label: 'Penghasilan',
        color: '#ffcc00'
    },
    {
        id: 'nik_wali',
        label: 'NIK',
        color: '#ffcc00'
    },
    {
        id: 'npsn_sekolah',
        label: 'NPSN Sekolah',
        color: '#5ac8fa'
    },
    {
        id: 'tingkat_pendidikan',
        label: 'Tingkat/Kelas Saat Ini',
        color: '#5ac8fa'
    },
    {
        id: 'program_jurusan_saat_ini',
        label: 'Program/Jurusan Saat Ini',
        color: '#5ac8fa'
    },
    {
        id: 'penerima_KIP',
        label: 'Penerima KIP',
        color: '#5ac8fa'
    },
    {
        id: 'jenis_kebutuhan_khusus_siswa_abk',
        label: 'Jenis Kebutuhan Khusus (Siswa ABK)',
        color: '#5ac8fa'
    },
]

class DataIndividupd extends Component {
    state = {
        error: null,
        loading: true,
        routeParams: {
            kode_wilayah: this.$f7route.params['kode_wilayah'] ? this.$f7route.params['kode_wilayah'] : localStorage.getItem('kode_wilayah_aplikasi'),
            id_level_wilayah: this.$f7route.params['id_level_wilayah'] ? this.$f7route.params['id_level_wilayah']: localStorage.getItem('id_level_wilayah_aplikasi'),
            semester_id: localStorage.getItem('semester_id_aplikasi'),
            tahun_ajaran_id: localStorage.getItem('semester_id_aplikasi').substring(0,4),
            bentuk_pendidikan_id: sudah_login === 1 ? jenjang_aplikasi_all : jenjang_aplikasi,
            page: 0,
            start: 0,
            limit: 20,
        },
        wilayah_kab: [],
        wilayah_kec: [],
        sekolah: [],
        limit: [10, 20, 50, 100, 200, 500, 1000]
    }

    componentDidMount = () => {
        this.setState({
            routeParams: {
                ...this.state.routeParams,
                kode_wilayah: this.state.routeParams.kode_wilayah
            }
        },()=>{
            const routeParams = {
                id_level_wilayah: 2,
                mst_kode_wilayah: this.state.routeParams.kode_wilayah
            }
            this.props.getWilayah(routeParams).then((e)=>{
                this.setState({ wilayah_kab: e.payload.rows });
                if(levelWilayah === 1){
                    console.log('provinsi wilayah')
                    this.setState({ wilayah_kab: e.payload.rows });
                }else{
                    console.log('kabupaten wilayah')
                    this.setState({ wilayah_kec: e.payload.rows });
                }
                this.getSekolah(this.state.routeParams.kode_wilayah);
                this.props.getPD(this.state.routeParams).then(()=>{
                    this.setState({ loading: false });
                    this.setIsiKanan();
                });
            });
        });
    }

    getSekolah = (kode_wilayah) => {
        const { bentuk_pendidikan_id, id_level_wilayah } = this.state.routeParams;

        const paramsekolah = {
            kolom: ['sekolah.sekolah_id', 'sekolah.nama'],
            bentuk_pendidikan_id: bentuk_pendidikan_id,
            id_level_wilayah: id_level_wilayah,
            kode_wilayah: kode_wilayah,
            limit: parseInt(id_level_wilayah) === 3 ? undefined : 100
        }

        this.props.getSekolah(paramsekolah).then((e) => {
            this.setState({ sekolah: e.payload.rows });
        });
    }

    setIsiKanan = () => {
        this.props.setIsiKanan((
            <>
            <List>
                <Searchbar
                    className="searchbar-demo"
                    placeholder="Nama Wilayah"
                    searchContainer=".search-list"
                    searchIn=".item-title"
                    onSubmit={this.cariKeyword}
                ></Searchbar>
                <ListItem
                    title="Bentuk"
                    smartSelect
                    smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Saring Bentuk'}}
                >
                    <select onChange={this.setParamValue} name="bentuk_pendidikan_id" defaultValue={localStorage.getItem('jenjang_aplikasi')}>
                        {localStorage.getItem('jenjang_aplikasi').includes('-') && <option value="5-6-13-15-29">Semua</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('5') && <option value="5">SD</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('6') && <option value="6">SMP</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('5-6') && <option value="5-6">SD/SMP</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('13') && <option value="13">SMA</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('15') && <option value="15">SMK</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('13-15') && <option value="13-15">SMA/SMK</option>}
                        {localStorage.getItem('jenjang_aplikasi').includes('29') && <option value="29">SLB</option>}
                    </select>
                </ListItem>
                <ListItem
                    title="Status"
                    smartSelect
                    smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Saring Status'}}
                >
                    <select onChange={this.setParamValue} name="status_sekolah" defaultValue="semua">
                        <option value="99">Semua</option>
                        <option value="1">Negeri</option>
                        <option value="2">Swasta</option>
                    </select>
                </ListItem>
            </List>
            <List>  
                <ListItem style={{cursor:'pointer'}} title="Unduh Excel" onClick={()=>{window.open(
                        localStorage.getItem('api_base')+"/api/GTK/getPDExcel"
                        +"?semester_id="+localStorage.getItem('semester_id_aplikasi')
                        +"&tahun_ajaran_id="+localStorage.getItem('semester_id_aplikasi').substring(0,4)
                        +"&id_level_wilayah="+(this.state.routeParams.id_level_wilayah)
                        +"&kode_wilayah="+(this.state.routeParams.kode_wilayah)
                        +"&bentuk_pendidikan_id="+(this.state.routeParams.bentuk_pendidikan_id ? this.state.routeParams.bentuk_pendidikan_id : '')
                        +"&status_sekolah="+(this.state.routeParams.status_sekolah ? this.state.routeParams.status_sekolah : '')
                        +"&keyword="+(this.state.routeParams.keyword ? this.state.routeParams.keyword : '')
                        +"&limit=20000"
                    )}}>
                    <img slot="media" src="static/icons/xls.png" width="25" />
                </ListItem>
            </List>
            </>
        ));
    }


    formatAngka = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : num;
    }

    cariKeyword = (event)  => {
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                keyword: event.target[0].value
            }
        },()=>{
            this.props.getPD(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        })
    }

    klikNext = () => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: this.state.routeParams.page + 1,
                start: (parseInt(this.state.routeParams.start) + parseInt(this.state.routeParams.limit))
            }
        },()=>{
            this.props.getPD(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }
    
    klikPrev = () => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: this.state.routeParams.page - 1,
                start: (parseInt(this.state.routeParams.start) - parseInt(this.state.routeParams.limit))
            }
        },()=>{
            this.props.getPD(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }

    setParamValue = (b) => {
        this.setState({
            ...this.state,
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: 0,
                start: 0,
                [b.target.getAttribute('name')]: b.target.value
            }
        },()=>{
            // console.log(this.state.routeParams);
            this.props.getPD(this.state.routeParams).then((result)=>{
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        });
    }

    setFilterKabupaten = (event) => {
        const kode_wilayah = event.target.value;
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: 0,
                start: 0,
                id_level_wilayah: 2,
                kode_kabupaten: kode_wilayah,
                kode_kecamatan: undefined
            }
        }, () => {
            const routeParams = {
                id_level_wilayah: 3,
                mst_kode_wilayah: kode_wilayah
            }
            
            this.getSekolah(kode_wilayah);

            this.props.getWilayah(routeParams).then((e)=>{
                this.setState({ wilayah_kec: e.payload.rows });
            });

            this.props.getPD(this.state.routeParams).then(()=>{
                this.setState({
                    loading: false
                });
            });
        })
    }

    setFilterKecamatan = (event) => {
        const kode_wilayah = event.target.value;
        this.setState({
            loading: true,
            routeParams: {
                ...this.state.routeParams,
                page: 0,
                start: 0,
                id_level_wilayah: 3,
                kode_kecamatan: kode_wilayah
            }
        }, () => {
            this.getSekolah(kode_wilayah);

            this.props.getPD(this.state.routeParams).then(()=>{
                this.setState({
                    loading: false
                });
            });
        })
    }

    bukaPengaturan = () => {
        this.props.setJudulKanan('Menu Agama GTK');
    }

    render()
    {
        return (
            <Page name="GTKAgama" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>{this.props.wilayah.rows[0].nama}</NavTitle>
                    <NavTitleLarge>
                        Data Individu PD
                    </NavTitleLarge>
                    <Subnavbar>
                        <Segmented raised>
                            <Button tabLink="#tab1" href={`/data-individu/ptk`}>PTK</Button>
                            <Button tabLink="#tab2" tabLinkActive>PD</Button>
                        </Segmented>
                    </Subnavbar>
                </Navbar>
                <Row>
                { levelWilayah === 1 && 
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Kab/Kota"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Pencarian'}}
                            >
                                <select onChange={this.setFilterKabupaten} name="kode_kabupaten" defaultValue="semua">
                                    <option value="">Semua</option>
                                    { this.state.wilayah_kab.map((opt, key) => <option key={key} value={opt.kode_wilayah}>{ opt.nama }</option>)}
                                </select>
                            </ListItem>
                        </List>
                    </Col>
         }
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Kecamatan"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Pencarian'}}
                            >
                                <select onChange={this.setFilterKecamatan} name="kode_kecamatan" defaultValue="semua">
                                    <option value="">Semua</option>
                                    { this.state.wilayah_kec.map((opt, key) => <option key={key} value={opt.kode_wilayah}>{ opt.nama }</option>)}
                                </select>
                            </ListItem>
                        </List>
                    </Col>
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Sekolah"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Pencarian', scrollToSelectedItem: true}}
                            >
                                <select onChange={this.setParamValue} name="sekolah_id" defaultValue="semua">
                                    <option value="">Semua</option>
                                    { this.state.sekolah.map((opt, key) => <option key={key} value={opt.sekolah_id}>{ opt.nama }</option>)}
                                </select>
                            </ListItem>
                        </List>
                    </Col>
                    <Col width={50} tabletWidth={25}>
                        <List style={{ margin: 0 }}>
                            <ListItem
                                title="Limit"
                                smartSelect
                                smartSelectParams={{openIn: 'popup', searchbar: true, routableModals: false, searchbarPlaceholder: 'Saring Status'}}
                            >
                                <select onChange={this.setParamValue} name="limit" defaultValue={this.state.routeParams.limit}>
                                    {
                                        this.state.limit.map((opt, key) => <option key={key} value={opt}>{ opt }</option>)
                                    }
                                </select>
                            </ListItem>
                        </List>
                    </Col>
                </Row>
                <Block strong style={{
                    marginTop:'0px', 
                    paddingLeft:'0px', 
                    paddingRight:'0px', 
                    paddingTop:'0px', 
                    paddingBottom:'0px'
                }}>
                    <div className="data-table" style={{overflowY:'hidden'}}>
                        <div className="data-table-footer" style={{display:'block'}}>
                            <div className="data-table-pagination">
                                <a onClick={this.klikPrev} href="#" className={"link "+(this.state.routeParams.start < 1 ? "disabled" : "" )}>
                                    <i className="icon icon-prev color-gray"></i>
                                </a>
                                <a onClick={this.klikNext} href="#" className={"link "+((parseInt(this.state.routeParams.start)+20) > parseInt(this.props.individu_pd.total) ? "disabled" : "" )}>
                                    <i className="icon icon-next color-gray"></i>
                                </a>
                                <span className="data-table-pagination-label">{(this.state.routeParams.start+1)}-{(this.state.routeParams.start)+parseInt(this.state.routeParams.limit)} dari {this.formatAngka(this.props.individu_pd.total)} Peserta Didik</span>
                            </div>
                        </div>
                        <table>
                            <thead style={{background:'#eeeeee'}}>
                                <tr>
                                    <th className='text-center' colSpan={9} rowSpan={2} style={{ backgroundColor: '#007aff', color: 'white' }}>IDENTITAS SISWA</th>
                                    <th className='text-center' colSpan={13} rowSpan={2} style={{ backgroundColor: '#ff9500', color: 'white' }}>ALAMAT & JENIS TINGGAL/DOMISILI & KONTAK SISWA</th>
                                    <th className='text-center' colSpan={15} style={{ backgroundColor: '#009688', color: 'white' }}>IDENTITAS ORANG TUA</th>
                                    <th className='text-center' colSpan={5} rowSpan={2} style={{ backgroundColor: '#5ac8fa', color: 'white' }}>ATRIBUT SEKOLAH/AKADEMIK</th>
                                </tr>
                                <tr>
                                    <th className='text-center' colSpan={5} style={{ backgroundColor: '#4cd964', color: 'white' }}>Data Ayah</th>
                                    <th className='text-center' colSpan={5} style={{ backgroundColor: '#cddc39', color: 'white' }}>Data Ibu</th>
                                    <th className='text-center' colSpan={5} style={{ backgroundColor: '#ffcc00', color: 'white' }}>Data Wali</th>
                                </tr>
                                <tr>
                                    <th className='text-center' style={{ backgroundColor: '#007aff', color: 'white' }}>No</th>
                                    {
                                        header.map((opt, key) => {
                                            return (
                                                <th key={key} className='text-center' style={{ backgroundColor: opt.color || '', color: 'white' }}>{ opt.label }</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loading ?
                            <>
                                {this.props.dummy_rows.rows.map((e, k)=>{
                                    return (
                                        <tr key={k}>
                                            {header.map((opt, key) => <td key={key} className="label-cell skeleton-text skeleton-effect-blink font-12">loremipsum</td>)}
                                        </tr>
                                    )
                                })}
                            </>
                            :
                            <>
                            {this.props.individu_pd.rows.map((option, key)=>{
                                const no = (this.state.routeParams.page * this.state.routeParams.limit) + (key + 1);
                                return(
                                    <tr key={option.kode_wilayah}>
                                        <td>{ no }</td>
                                        {
                                            header.map((opt, key) => {
                                                return (
                                                    <td key={key} className="label-cell font-12">{option[opt.id]}</td>
                                                )
                                            })
                                            }
                                    </tr>
                                )
                            })}
                            </>
                            }
                            </tbody>
                        </table>
                        <div className="data-table-footer" style={{display:'block'}}>
                            <div className="data-table-pagination">
                                <a onClick={this.klikPrev} href="#" className={"link "+(this.state.routeParams.start < 1 ? "disabled" : "" )}>
                                    <i className="icon icon-prev color-gray"></i>
                                </a>
                                <a onClick={this.klikNext} href="#" className={"link "+((parseInt(this.state.routeParams.start)+20) > parseInt(this.props.individu_pd.total) ? "disabled" : "" )}>
                                    <i className="icon icon-next color-gray"></i>
                                </a>
                                <span className="data-table-pagination-label">{(this.state.routeParams.start+1)}-{(this.state.routeParams.start)+parseInt(this.state.routeParams.limit)} dari {this.formatAngka(this.props.individu_pd.total)} Peserta Didik</span>
                            </div>
                        </div>
                    </div>
                </Block>
            </Page>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      updateWindowDimension: Actions.updateWindowDimension,
      setLoading: Actions.setLoading,
      setTabActive: Actions.setTabActive,
      getSekolah: Actions.getSekolah,
      getRekapSekolah: Actions.getRekapSekolah,
      getSekolahIndividu: Actions.getSekolahIndividu,
      getWilayah: Actions.getWilayah,
      getPD: Actions.getPD,
      setJudulKanan: Actions.setJudulKanan,
      setIsiKanan: Actions.setIsiKanan
    }, dispatch);
}

function mapStateToProps({ App, PesertaDidik }) {

    return {
        window_dimension: App.window_dimension,
        loading: App.loading,
        tabBar: App.tabBar,
        sekolah: App.sekolah,
        rekap_sekolah: App.rekap_sekolah,
        sekolah_individu: App.sekolah_individu,
        wilayah: App.wilayah,
        dummy_rows: App.dummy_rows,
        individu_pd: PesertaDidik.individu_pd,
        judul_panel_kanan: App.judul_panel_kanan,
        isi_panel_kanan: App.isi_panel_kanan
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(DataIndividupd));