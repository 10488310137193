import React from "react";

const dataInput = {
    title: `Input APK PAUD > Rekap Data Jumlah Penduduk Usia 0-4 dengan Jumlah Siswa PAUD`,
    data: [],
    columns: [
        {
            Header: 'No',
            rowSpan: 2,
            columns: [
                {
                    Header: 'NO',
                    skip:1,
                    footerLabel: '',
                    Cell:({row}) => {
                        const { index } = row;
                        return index + 1;
                    },
                },
            ]
        },
        {
            id: 'kecamatan',
            Header: () => {
                const kode_wilayah = localStorage.getItem('kode_wilayah_aplikasi');
                return(
                    kode_wilayah === '040000' ? "Kab/Kota" : "Kecamatan"
                )
            },
            className: 'label-cell',
            rowSpan:2,
            columns: [
                {   
                    skip:1,
                    id: 'kecamatan',
                    Header: '',
                    accessor: 'kecamatan',
                    footerLabel: 'Total',
                    Cell: ({ row }) => (
                        <div style={{ textAlign: "left" }}>
                            {row.original.kecamatan}
                        </div>
                    )
                },
            ]
        },
        {
            Header: 'Jumlah Penduduk Umur 0-4',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'penduduk_0_4_l',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'penduduk_0_4_p',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    id: 'jml_ppd',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { penduduk_0_4_l, penduduk_0_4_p } = row.original;

                        const value = parseInt(penduduk_0_4_l || 0) + parseInt(penduduk_0_4_p || 0);

                        return value;
                    }
                },
            ]
        },
        {
            Header: 'Jumlah Seluruh PAUD',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_l_2',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_p_2',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    id: 'jumlah_2',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_2, murid_p_2 } = row.original;
                        const value = parseInt(murid_l_2 || 0) + parseInt(murid_p_2 || 0);

                        return value;
                    }
                },
            ]
        },
        {
            Header: 'Jumlah',
            align: 'center',
            columns: [
                {
                    id: 'jumlah_siswa_l_total',
                    Header: 'L',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_34, murid_l_2 } = row.original;
        
                        const value = parseInt(murid_l_34 || 0) + parseInt(murid_l_2 || 0);
        
                        return value;
                    }
                },
                {
                    id: 'jumlah_siswa_p_total',
                    Header: 'P',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_p_34, murid_p_2 } = row.original;
        
                        const value = parseInt(murid_p_34 || 0) + parseInt(murid_p_2 || 0);
        
                        return value;
                    }
                },
                {
                    id: 'jumlah_siswa_total',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_p_2, murid_l_2 } = row.original;
        
                        const value = parseInt(murid_p_2 || 0) + parseInt(murid_l_2 || 0);
        
                        return value;
                    }
                },
            ]
        },
    ]
}

export default dataInput;