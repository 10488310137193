// Import React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';

// Import Framework7
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-React Plugin
import Framework7React from 'framework7-react';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.jsx';

// Init F7 Vue Plugin
Framework7.use(Framework7React)

// Palembang
localStorage.setItem('api_base', "http://82.197.69.145:9191");
localStorage.setItem('wilayah_aplikasi', 'Kota Palembang');
localStorage.setItem('id_level_wilayah_aplikasi', '2');
localStorage.setItem('kode_wilayah_aplikasi', '116000');

localStorage.setItem('judul_aplikasi', 'Disdik Kota Palembang');
localStorage.setItem('tema_warna_aplikasi', 'pink-1');
localStorage.setItem('sub_judul_aplikasi', 'Data Center Kota Palembang');
localStorage.setItem('kode_aplikasi', 'SIMDIK');
localStorage.setItem('jenjang_aplikasi', '1-2-3-4-5-6-27');
localStorage.setItem('jenjang_aplikasi_all', '1-2-3-4-5-6-27');
localStorage.setItem('versi_aplikasi', '2021.09');
localStorage.setItem('semester_id_aplikasi', '20232');
localStorage.setItem('semester_id_rekap_dapo', '20232');
localStorage.setItem('logo_aplikasi', "https://www.kemdikbud.go.id/main/files/large/83790f2b43f00be");
localStorage.setItem('logo_wilayah', "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Palembang_CoA.svg/79px-Palembang_CoA.svg.png");
localStorage.setItem('harus_login', "N");

localStorage.setItem('google_api', '408984113206-5qrhpn45hsts6tr0ibfn90ve1rcj4kdh.apps.googleusercontent.com');

document.title = localStorage.getItem('judul_aplikasi') + " - " + localStorage.getItem('sub_judul_aplikasi');

if (localStorage.getItem('sudah_login') === null || localStorage.getItem('sudah_login') === '') {
  localStorage.setItem('sudah_login', '0');
}

if (localStorage.getItem('riwayat_kata_kunci') === null) {
  localStorage.setItem('riwayat_kata_kunci', '');
}

// localStorage.setItem('device', 'web');
// localStorage.setItem('device', 'android');

// Mount React App
ReactDOM.render(
  <Provider store={store}>
    {React.createElement(App)}
  </Provider>,
  document.getElementById('app'),
);
