import React from "react";
import { Button, Card, CardContent } from "framework7-react"
import { useTable } from "react-table"
import generateTableForExcel from "../generate-table/generateTableForExcel";
import exportTableToExcel from "../../../shared-components/exportTableToExcelService";

const Table = ({ columns, data, title, current_url, rows:dataRows, total }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: dataRows })

    const downloadExcel = () => {
    const labelExcel = `Profile Pendidikan Table ${current_url}`;
    const tableForExcel = generateTableForExcel(
        "profilePendidikan",
        labelExcel
    );

        exportTableToExcel(tableForExcel, labelExcel);
    } 

    const footer = columns[1].columns;

    const totalJumlah = rows.reduce((acc, row) => acc + parseFloat(row.values.jumlah), 0);

    return (
        <Card style={{ margin: "1rem 0px", borderRadius: "12px", padding: "10px" }}>
            <CardContent>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>{title}</b>
                    <Button onClick={downloadExcel} style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold', padding: '0px 20px', fontSize: '12px' }}>Unduh XLS</Button>
                </div>
                <div className="data-table mt-20px">
                    <table {...getTableProps()} className="table-auto" style={{ border: "1px solid #d1d1d1" }} id="profilePendidikan">
                        <thead>
                            {headerGroups.map(headerGroup => {
                                return(
                                    <tr {...headerGroup.getHeaderGroupProps()} style={{alignContent: "center", background: "#e3f2fd"}}>
                                        <th style={{ textAlign: "center", color: "#000" }}>Nomor</th>
                                        {headerGroup.headers.map((column) => {
                                            const isKabupaten = column.render('Header') === 'Kabupaten' || column.render('Header') === 'Wilayah';
                                            const textAlign = isKabupaten ? 'left' : 'right';
                                            
                                            return (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    style={{
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontWeight: "bold",
                                                        textAlign: textAlign,
                                                        color: "#000",
                                                        textTransform: "capitalize"
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                )
                            })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} style={{
                                        backgroundColor: i % 2 === 0 ? '#FFFFFF' : '#F5F5F5'
                                    }}>
                                        <td className="text-center" style={{ width: "70px" }}>{i + 1}</td>
                                        {row.cells.map((cell, index) => {
                                            const numericValue = parseFloat(cell.value);
                                            const isNumeric = !isNaN(numericValue);
                                            
                                            const cellProps = cell.getCellProps();
                                            
                                            const cellStyle = isNumeric ? { textAlign: 'right' } : {};

                                            return (
                                                <td {...cellProps} style={cellStyle} key={index}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}

                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot style={{ fontWeight: "700", backgroundColor: "#FFD966", height: "40px" }}>
                            <tr>
                                <td colSpan={2}>Jumlah Total</td>
                                <td colSpan={columns.length - 0} style={{ textAlign: 'right' }}>{totalJumlah}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </CardContent>
        </Card>
    )
}

export default Table;