import React, { Component } from 'react';
import { Page, Navbar, NavLeft, NavTitle, NavTitleLarge, NavRight, Link, Block, Card, BlockTitle, List, ListItem, Row, Col, Button, CardHeader, CardContent } from 'framework7-react';
import { Line, Radar } from 'react-chartjs-2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../store/actions';
import moment from 'moment';

const sudah_login = parseInt(localStorage.getItem('sudah_login'));
const jenjang_aplikasi = localStorage.getItem('jenjang_aplikasi');
const jenjang_aplikasi_all = localStorage.getItem('jenjang_aplikasi_all');
const semester = localStorage.getItem('semester_id_aplikasi');
const semester_dapo = localStorage.getItem('semester_id_rekap_dapo');
const semester_opt = localStorage.getItem('semester_opt') ? JSON.parse(localStorage.getItem('semester_opt')) : [];

class Beranda extends Component {
    state = {
        error: null,
        loading: true,
        data: {
            r_kelas: [],
            perpustakaan: []
        },
        dataTabel: {
            r_kelas: [],
            perpustakaan: []
        },
        routeParams: {
            kode_wilayah: localStorage.getItem('kode_wilayah_aplikasi'),
            id_level_wilayah: localStorage.getItem('id_level_wilayah_aplikasi'),
            tipe: 'chart',
        },
        peringkat_kabupaten: {
            rows: [{
                kode_wilayah: '000000'
            }]
        },
        peringkat_provinsi: {
            rows: [{
                kode_wilayah: '000000'
            }]
        },
        peringkat_sekolah: {
            rows: [{
                sekolah_id: ''
            }]
        },
        sekolah_total: 0,
        pd_total: 0,
        guru_total: 0,
        pegawai_total: 0,
        rombel_total: 0,
        tanggal_rekap_terakhir: '2020-01-01',
        sd_negeri: 0,
        sd_swasta: 0,
        smp_negeri: 0,
        smp_swasta: 0,
        sma_negeri: 0,
        sma_swasta: 0,
        smk_negeri: 0,
        smk_swasta: 0,
        slb_negeri: 0,
        slb_swasta: 0,
        pd_dalam_kota: 0,
        pd_luar_kota: 0,
        jumlah_ruang_kelas: 0
    }

    bulan = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
    ]

    formatAngka = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : num;
    }

    backClick = () => {
        let properti = 'beranda';

        for (var property in this.props.tabBar) {
            this.props.tabBar[property] = false;
        }

        if (this.props.f7router.url.replace("/", "").replace("/", "") !== "") {
            properti = this.props.f7router.url.replace("/", "").replace("/", "");
        }

        this.props.tabBar[properti] = true;
        this.props.setTabActive(this.props.tabBar);
    }

    gantiSemester = (b) => {
        localStorage.setItem('semester_id_aplikasi', b.target.value);
    }

    componentDidMount = () => {
        if (parseInt(localStorage.getItem('sudah_login')) !== 1 && localStorage.getItem('harus_login') === 'Y') {
            this.$f7router.navigate('/login/');
        }

        localStorage.setItem('current_url', this.$f7route.url);

        if (localStorage.getItem('user')) {
            if (localStorage.getItem('user') !== '') {
                if (parseInt(JSON.parse(localStorage.getItem('user')).verified) === 0) {
                    this.$f7.dialog.confirm('Anda belum dapat menggunakan hak akses pengguna Anda secara penuh sebelum akun pengguna Anda terverifikasi oleh Administrator. Apakah Anda ingin melakukan verifikasi akun pengguna?', 'Informasi', () => {
                        this.$f7router.navigate('/ProfilPengguna');
                    });
                }
            }
        }

        this.setState({
            ...this.state,
            routeParams: {
                ...this.state.routeParams,
                kode_wilayah: localStorage.getItem('kode_wilayah_aplikasi') ? localStorage.getItem('kode_wilayah_aplikasi') : '090000',
                bentuk_pendidikan_id: sudah_login === 1 ? jenjang_aplikasi_all : jenjang_aplikasi,
                semester_id: semester
            }
        }, () => {
            let { routeParams } = this.state;
            const params = {
                ...routeParams,
                id_level_wilayah: undefined
            }

            this.props.getWilayah(params).then(() => {
                // this.props.getRls(routeParams);
                this.props.getCountPDwilayah(routeParams);

                this.props.getRekapSekolahRingkasan(this.state.routeParams).then((result) => {
                    let sekolah_total = 0;
                    let pd_total = 0;
                    let guru_total = 0;
                    let pegawai_total = 0;
                    let rombel_total = 0;
                    let pd_dalam_kota = 0;
                    let pd_luar_kota = 0;
                    let jumlah_ruang_kelas = 0;

                    for (let iRekapSekolah = 0; iRekapSekolah < this.props.rekap_sekolah_ringkasan.rows.length; iRekapSekolah++) {
                        const element = this.props.rekap_sekolah_ringkasan.rows[iRekapSekolah];
                        sekolah_total = sekolah_total + parseInt(element.total);
                        pd_total = pd_total + parseInt(element.pd_total);
                        guru_total = guru_total + parseInt(element.guru_total);
                        pegawai_total = pegawai_total + parseInt(element.pegawai_total);
                        rombel_total = rombel_total + parseInt(element.rombel_total);
                        pd_dalam_kota = pd_dalam_kota + parseInt(element.pd_dalam_kota);
                        pd_luar_kota = pd_luar_kota + parseInt(element.pd_luar_kota);
                        jumlah_ruang_kelas = jumlah_ruang_kelas + parseInt(element.jumlah_ruang_kelas);
                    }

                    this.setState({
                        sekolah_total: sekolah_total,
                        pd_total: pd_total,
                        guru_total: guru_total,
                        pegawai_total: pegawai_total,
                        rombel_total: rombel_total,
                        pd_dalam_kota: pd_dalam_kota,
                        pd_luar_kota: pd_luar_kota,
                        jumlah_ruang_kelas: jumlah_ruang_kelas,
                    }, () => {
                        this.setState({
                            routeParams: {
                                kode_wilayah: this.state.routeParams.kode_wilayah
                            }
                        }, () => {
                            this.props.getIndexPendidikan(this.state.routeParams).then((result) => {
                                let labels = [];
                                let datasets_ipm = [];
                                let datasets_hls = [];
                                let datasets_rls = [];
                                let datasets_amh = [];
                                let datasets_apk = [];
                                let datasets_apm = [];

                                for (let index = 0; index < result.payload.rows.length; index++) {
                                    const element = result.payload.rows[index];
                                    labels.push(element.tahun)
                                    datasets_ipm.push(element.nilai_ipm ? element.nilai_ipm : 0)
                                    datasets_hls.push(element.nilai_hls ? element.nilai_hls : 0)
                                    datasets_rls.push(element.nilai_rls ? element.nilai_rls : 0)
                                    datasets_amh.push(element.nilai_amh ? element.nilai_amh : 0)
                                    datasets_apk.push(element.nilai_apk ? element.nilai_apk : 0)
                                    datasets_apm.push(element.nilai_apm ? element.nilai_apm : 0)
                                }

                                this.setState({
                                    loading: false,
                                    data: {
                                        ...this.state.data,
                                        labels: labels,
                                        datasets: [{
                                            label: 'IPM',
                                            data: datasets_ipm,
                                            fill: false,
                                            backgroundColor: 'rgb(255, 99, 132)',
                                            borderColor: 'rgba(255, 99, 132, 0.2)',
                                        },
                                        {
                                            label: 'HLS',
                                            data: datasets_hls,
                                            fill: false,
                                            backgroundColor: 'rgb(0, 99, 0)',
                                            borderColor: 'rgba(0, 99, 0, 0.2)',
                                        },
                                        {
                                            label: 'RLS',
                                            data: datasets_rls,
                                            fill: false,
                                            backgroundColor: 'rgb(255, 99, 0)',
                                            borderColor: 'rgba(255, 99, 0, 0.2)',
                                        },
                                        {
                                            label: 'AMH',
                                            data: datasets_amh,
                                            fill: false,
                                            backgroundColor: 'rgb(0, 0, 0)',
                                            borderColor: 'rgba(0, 0, 0, 0.2)',
                                        },
                                        {
                                            label: 'APK',
                                            data: datasets_apk,
                                            fill: false,
                                            backgroundColor: 'rgb(128, 0, 0)',
                                            borderColor: 'rgba(128, 0, 0, 0.2)',
                                        },
                                        {
                                            label: 'APM',
                                            data: datasets_apm,
                                            fill: false,
                                            backgroundColor: 'rgb(255, 0, 0)',
                                            borderColor: 'rgba(255, 0, 0, 0.2)',
                                        }]
                                    },
                                })
                            })
                        });
                    });
                });

                this.props.getRekapSekolahTotal(this.state.routeParams).then(() => {
                    let sd_negeri = 0
                    let sd_swasta = 0
                    let smp_negeri = 0
                    let smp_swasta = 0
                    let sma_negeri = 0
                    let sma_swasta = 0
                    let smk_negeri = 0
                    let smk_swasta = 0
                    let slb_negeri = 0
                    let slb_swasta = 0

                    for (let index = 0; index < this.props.rekap_sekolah_total.rows.length; index++) {
                        const element = this.props.rekap_sekolah_total.rows[index]

                        if (parseInt(element.bentuk_pendidikan_id) === 5) {
                            sd_negeri = element.negeri
                            sd_swasta = element.swasta
                        }

                        if (parseInt(element.bentuk_pendidikan_id) === 6) {
                            smp_negeri = element.negeri
                            smp_swasta = element.swasta
                        }

                        if (parseInt(element.bentuk_pendidikan_id) === 13) {
                            sma_negeri = element.negeri
                            sma_swasta = element.swasta
                        }

                        if (parseInt(element.bentuk_pendidikan_id) === 15) {
                            smk_negeri = element.negeri
                            smk_swasta = element.swasta
                        }

                        if (parseInt(element.bentuk_pendidikan_id) === 29) {
                            slb_negeri = element.negeri
                            slb_swasta = element.swasta
                        }
                    }

                    this.setState({
                        sd_negeri: sd_negeri,
                        sd_swasta: sd_swasta,
                        smp_negeri: smp_negeri,
                        smp_swasta: smp_swasta,
                        sma_negeri: sma_negeri,
                        sma_swasta: sma_swasta,
                        smk_negeri: smk_negeri,
                        smk_swasta: smk_swasta,
                        slb_negeri: slb_negeri,
                        slb_swasta: slb_swasta
                    }, () => {
                        this.props.getRekapPesertaDidikRingkasan({
                            ...this.state.routeParams,
                            id_level_wilayah: localStorage.getItem('id_level_wilayah_aplikasi'),
                            semester_id: localStorage.getItem('semester_id_aplikasi'),
                            tahun_ajaran_id: localStorage.getItem('semester_id_aplikasi').substring(0, 4),
                            status_sekolah: 99
                        }).then(() => {
                            this.props.getRekapGTKRingkasan({
                                ...this.state.routeParams,
                                id_level_wilayah: localStorage.getItem('id_level_wilayah_aplikasi'),
                                semester_id: localStorage.getItem('semester_id_aplikasi'),
                                tahun_ajaran_id: localStorage.getItem('semester_id_aplikasi').substring(0, 4),
                                status_sekolah: 99
                            }).then(() => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            });
                        });
                    })
                });
                this.props.getPesertaDidikTingkatKelasPie(this.state.routeParams);
                this.props.getGtkJenisPie(this.state.routeParams);
            });
        });

        this.props.saveLog({
            judul_aplikasi: localStorage.getItem('judul_aplikasi'),
            kode_wilayah_aplikasi: localStorage.getItem('kode_wilayah_aplikasi'),
            wilayah_aplikasi: localStorage.getItem('wilayah_aplikasi'),
            semester_id_aplikasi: localStorage.getItem('semester_id_aplikasi')
        })

    }

    render() {
        const { count_pd_wilayah } = this.props;

        return (
            <Page name="Beranda" hideBarsOnScroll>
                {parseInt(localStorage.getItem('sudah_login')) !== 1 && localStorage.getItem('harus_login') === "N" &&
                    <Navbar sliding={false} large>
                        <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="sideMenuToggle" />
                        </NavLeft>
                        <NavTitle sliding>{localStorage.getItem('sub_judul_aplikasi')}</NavTitle>
                        {!localStorage.getItem('logo_aplikasi_2') &&
                            <NavTitleLarge style={{ background: '#fff', color: '#000' }}>
                                {localStorage.getItem('sub_judul_aplikasi')}
                            </NavTitleLarge>}
                        {localStorage.getItem('logo_aplikasi_2') &&
                            <NavTitleLarge style={{ background: '#fff', color: '#000' }}>
                                <img src="/static/icons/logo_putih.png" style={{ width: '160px' }} />
                            </NavTitleLarge>}
                        <NavRight>
                            <Link iconIos="f7:search" iconAurora="f7:search" iconMd="material:search" href="/DataPokokSekolah/" ></Link>
                        </NavRight>
                    </Navbar>
                }

                {parseInt(localStorage.getItem('sudah_login')) === 1 &&
                    <Navbar sliding={false} large>
                        <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="sideMenuToggle" />
                        </NavLeft>
                        <NavTitle sliding>{localStorage.getItem('sub_judul_aplikasi')}</NavTitle>
                        {!localStorage.getItem('logo_aplikasi_2') &&
                            <NavTitleLarge style={{ background: '#fff', color: '#000' }}>
                                {localStorage.getItem('sub_judul_aplikasi')}
                            </NavTitleLarge>}
                        {localStorage.getItem('logo_aplikasi_2') &&
                            <NavTitleLarge style={{ background: '#fff', color: '#000' }}>
                                <img src="/static/icons/logo_putih.png" style={{ width: '160px' }} />
                            </NavTitleLarge>}
                        <NavRight>
                            <Link iconIos="f7:search" iconAurora="f7:search" iconMd="material:search" href="/DataPokokSekolah/" ></Link>
                        </NavRight>
                    </Navbar>
                }
                {localStorage.getItem('kode_aplikasi') === 'RAPORDAPODIK' &&
                    <>
                        <Block strong style={{ marginBottom: '8px', marginTop: '8px' }}>
                            <Row noGap>
                                <Col width="25" tabletWidth="10">
                                    <img src={localStorage.getItem('logo_aplikasi')} style={{ width: '80px' }} />
                                </Col>
                                <Col width="50" tabletWidth="70">
                                    <List style={{ width: '100%', marginBottom: '0px', marginTop: '0px' }}>
                                        <ListItem>
                                            <span style={{ fontWeight: 'bold', fontSize: '25px' }}>{localStorage.getItem('sub_judul_aplikasi')}</span>
                                        </ListItem>
                                    </List>
                                    <List style={{ width: '100%', marginBottom: '0px', marginTop: '0px' }}>
                                        <ListItem title="Semester" smartSelect >
                                            <select onChange={this.gantiSemester} name="semester_id" defaultValue={localStorage.getItem('semester_id_aplikasi')}>
                                                {semester_opt.map((smt, key) =>
                                                    <option key={key} value={smt.semester_id}>{smt.nama}</option>
                                                )}
                                            </select>
                                        </ListItem>
                                    </List>
                                </Col>
                                <Col width="25" tabletWidth="20">
                                    <Button raised fill>
                                        Tes
                                    </Button>
                                </Col>
                            </Row>
                        </Block>
                    </>
                }
                {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <>
                        <Block strong style={{ marginBottom: '8px', marginTop: '8px' }}>
                            <Row noGap>
                                <Col width="25" tabletWidth="10">
                                    <img src={localStorage.getItem('logo_aplikasi')} style={{ width: '80px' }} />
                                </Col>
                                <Col width="50" tabletWidth="70">
                                    <List style={{ width: '100%', marginBottom: '0px', marginTop: '0px' }}>
                                        <ListItem>
                                            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{localStorage.getItem('sub_judul_aplikasi')}</span>
                                        </ListItem>
                                    </List>
                                    <List style={{ width: '100%', marginBottom: '0px', marginTop: '0px' }}>
                                        <ListItem title="Semester" smartSelect >
                                            <select onChange={this.gantiSemester} name="semester_id" defaultValue={localStorage.getItem('semester_id_aplikasi')}>
                                                <option value="">-Semester-</option>
                                                {semester_opt.map((smt, key) =>
                                                    <option key={key} value={smt.semester_id}>{smt.nama}</option>
                                                )}
                                            </select>
                                        </ListItem>
                                    </List>
                                </Col>
                                <Col width="25" tabletWidth="20" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                    <Button style={{ height: '90px', border: '1px solid #eee' }} border raised>
                                        <img style={{ height: '89px', marginTop: '-4px' }} src={localStorage.getItem('logo_wilayah')} />
                                    </Button>
                                </Col>
                            </Row>
                        </Block>
                    </>
                }
                {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <>
                        <Block strong style={{ marginBottom: '8px', marginTop: '8px' }}>
                            <BlockTitle className="publikasiJudul" style={{ fontSize: 'large', fontWeight: 'bold' }}>Rekap Data Pokok Pendidikan</BlockTitle>
                            <BlockTitle className="publikasiTanggal">Berdasarkan data tanggal {moment(this.state.tanggal_rekap_terakhir).format('D')} {this.bulan[parseInt(moment(this.state.tanggal_rekap_terakhir).format('M') - 1)]} {moment(this.state.tanggal_rekap_terakhir).format('YYYY')}, {moment(this.state.tanggal_rekap_terakhir).format('HH')}:{moment(this.state.tanggal_rekap_terakhir).format('mm')}</BlockTitle>
                            <Row noGap>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #2193b0, #3DA9C4)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah Sekolah</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.sekolah_total)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #9B3577, #753a88)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah PD</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.pd_total)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #ec008c, #F33B7D)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah Guru</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.guru_total)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #2193b0, #3DA9C4)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah PD Dalam Kota</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.pd_dalam_kota)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #9B3577, #753a88)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah PD Luar Kota</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.pd_luar_kota)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #00467f, #3C7282)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah Rombel</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.rombel_total)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="50" tabletWidth="25">
                                    <Card style={{ background: 'linear-gradient(to right, #ec008c, #F33B7D)', color: 'white' }}>
                                        <CardContent>
                                            <b>Jumlah Ruang Kelas</b>
                                            <br />
                                            <h1 style={{ fontSize: '30px', marginBottom: '0px' }}>{this.formatAngka(this.state.jumlah_ruang_kelas)}</h1>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col width="100" tabletWidth="100">
                                    <div className="data-table" style={{ overflowY: 'hidden' }}>
                                        <table>
                                            <thead style={{ background: '#eeeeee' }}>
                                                <tr>
                                                    <th className="label-cell" rowSpan="2" style={{ minWidth: '100px' }}>&nbsp;</th>
                                                    <th className="label-cell" style={{ textAlign: 'center', color: '#434343', fontSize: '13px' }} colSpan="3">Sekolah</th>
                                                    <th className="label-cell" style={{ textAlign: 'center', color: '#434343', fontSize: '13px' }} colSpan="3">Peserta Didik</th>
                                                    <th className="label-cell" style={{ textAlign: 'center', color: '#434343', fontSize: '13px' }} colSpan="3">Guru</th>
                                                    <th className="label-cell" style={{ textAlign: 'center', color: '#434343', fontSize: '13px' }} colSpan="3">Tendik</th>
                                                    <th className="label-cell" style={{ textAlign: 'center', color: '#434343', fontSize: '13px' }} colSpan="3">Rombel</th>
                                                </tr>
                                                <tr>
                                                    <th className="numeric-cell">Negeri</th>
                                                    <th className="numeric-cell">Swasta</th>
                                                    <th className="numeric-cell">Total</th>
                                                    <th className="numeric-cell">Negeri</th>
                                                    <th className="numeric-cell">Swasta</th>
                                                    <th className="numeric-cell">Total</th>
                                                    <th className="numeric-cell">Negeri</th>
                                                    <th className="numeric-cell">Swasta</th>
                                                    <th className="numeric-cell">Total</th>
                                                    <th className="numeric-cell">Negeri</th>
                                                    <th className="numeric-cell">Swasta</th>
                                                    <th className="numeric-cell">Total</th>
                                                    <th className="numeric-cell">Negeri</th>
                                                    <th className="numeric-cell">Swasta</th>
                                                    <th className="numeric-cell">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loading ?
                                                    <>
                                                        {this.props.dummy_rows.rows.map((option, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td className="label-cell skeleton-text skeleton-effect-blink">loremipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                    <td className="numeric-cell skeleton-text skeleton-effect-blink">lorenipsum</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <>
                                                        {this.props.rekap_sekolah_total.rows.map((option, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td className="label-cell">
                                                                        {option.nama}
                                                                    </td>
                                                                    <td className="numeric-cell">{option.negeri ? this.formatAngka(option.negeri) : "0"}</td>
                                                                    <td className="numeric-cell">{option.swasta ? this.formatAngka(option.swasta) : "0"}</td>
                                                                    <td className="numeric-cell">{option.total ? this.formatAngka(option.total) : "0"}</td>
                                                                    <td className="numeric-cell">{option.pd_negeri ? this.formatAngka(option.pd_negeri) : "0"}</td>
                                                                    <td className="numeric-cell">{option.pd_swasta ? this.formatAngka(option.pd_swasta) : "0"}</td>
                                                                    <td className="numeric-cell">{option.pd_total ? this.formatAngka(option.pd_total) : "0"}</td>
                                                                    <td className="numeric-cell">{option.guru_negeri ? this.formatAngka(option.guru_negeri) : "0"}</td>
                                                                    <td className="numeric-cell">{option.guru_swasta ? this.formatAngka(option.guru_swasta) : "0"}</td>
                                                                    <td className="numeric-cell">{option.guru_total ? this.formatAngka(option.guru_total) : "0"}</td>
                                                                    <td className="numeric-cell">{option.pegawai_negeri ? this.formatAngka(option.pegawai_negeri) : "0"}</td>
                                                                    <td className="numeric-cell">{option.pegawai_swasta ? this.formatAngka(option.pegawai_swasta) : "0"}</td>
                                                                    <td className="numeric-cell">{option.pegawai_total ? this.formatAngka(option.pegawai_total) : "0"}</td>
                                                                    <td className="numeric-cell">{option.rombel_negeri ? this.formatAngka(option.rombel_negeri) : "0"}</td>
                                                                    <td className="numeric-cell">{option.rombel_swasta ? this.formatAngka(option.rombel_swasta) : "0"}</td>
                                                                    <td className="numeric-cell">{option.rombel_total ? this.formatAngka(option.rombel_total) : "0"}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Block>
                    </>
                }
                <Col width={100} tabletWidth={50}>
                    <Card style={{ minHeight: '290px' }}>
                        <CardHeader>
                            Rekap Jumlah Peserta Didik
                        </CardHeader>
                        <CardContent>
                            <div className="data-table" style={{ overflowY: 'hidden' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Jumlah PD</th>
                                            <th>Jumlah PD Dalam Kota</th>
                                            <th>Jumlah PD Luar Kota</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {count_pd_wilayah.map((opt, key) =>
                                            <tr key={key}>
                                                <td>{this.formatAngka(parseInt(opt.jumlah_pd))}</td>
                                                <td>{this.formatAngka(parseInt(opt.jml_dalam_kota))}</td>
                                                <td>{this.formatAngka(parseInt(opt.jml_luar_kota))}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                    </Card>
                </Col>
                <Col width="100">
                    <Block strong style={{ marginTop: '8px', marginBottom: '8px' }}>
                        <Row>
                            <Col width={50} tabletWidth={25}>
                                <Link style={{ width: '100%' }} href="/DataPokokSekolah/" className={'text-' + localStorage.getItem('tema_warna_aplikasi')}>
                                    <Card style={{ width: '100%' }}>
                                        <CardContent style={{ textAlign: 'center' }}>
                                            <i style={{ fontSize: '50px' }} className="f7-icons">search</i>
                                            <br />
                                            <h3 style={{ marginBottom: '0px' }}>Cari Sekolah</h3>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Col>
                            {localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                                <Col width={50} tabletWidth={25}>
                                    <Link style={{ width: '100%' }} href="/RaporDapodik/" className={'text-' + localStorage.getItem('tema_warna_aplikasi')}>
                                        <Card style={{ width: '100%' }}>
                                            <CardContent style={{ textAlign: 'center' }}>
                                                <i style={{ fontSize: '50px' }} className="f7-icons">book</i>
                                                <br />
                                                <h3 style={{ marginBottom: '0px' }}>{(localStorage.getItem('kode_aplikasi') === 'RAPORDAPODIK' ? 'Rapor Dapodik' : 'Rapor Kualitas Data')}</h3>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Col>
                            }
                            {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <Col width={50} tabletWidth={25}>
                                    <Link style={{ width: '100%' }} href="/ProfilPendidikan/" className={'text-' + localStorage.getItem('tema_warna_aplikasi')}>
                                        <Card style={{ width: '100%' }}>
                                            <CardContent style={{ textAlign: 'center' }}>
                                                <i style={{ fontSize: '50px' }} className="f7-icons">chart_pie_fill</i>
                                                <br />
                                                <h3 style={{ marginBottom: '0px' }}>Profil Pendidikan</h3>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Col>
                            }
                            {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <Col width={50} tabletWidth={25}>
                                    <Link style={{ width: '100%' }} href="/RekapData/" className={'text-' + localStorage.getItem('tema_warna_aplikasi')}>
                                        <Card style={{ width: '100%' }}>
                                            <CardContent style={{ textAlign: 'center' }}>
                                                <i style={{ fontSize: '50px' }} className="f7-icons">folder</i>
                                                <br />
                                                <h3 style={{ marginBottom: '0px' }}>Rekap Pendidikan</h3>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </Block>
                </Col>
            </Page >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension: Actions.updateWindowDimension,
        setLoading: Actions.setLoading,
        setTabActive: Actions.setTabActive,
        getRekapSekolahSarpras: Actions.getRekapSekolahSarpras,
        getRekapSekolahSarprasWilayah: Actions.getRekapSekolahSarprasWilayah,
        getSarprasKerusakanWilayah: Actions.getSarprasKerusakanWilayah,
        getSarprasKerusakanWilayahTabel: Actions.getSarprasKerusakanWilayahTabel,
        getSarprasJenisWilayah: Actions.getSarprasJenisWilayah,
        getSarprasJenisWilayahTabel: Actions.getSarprasJenisWilayahTabel,
        getSarprasKebutuhanRkbWilayah: Actions.getSarprasKebutuhanRkbWilayah,
        getSarprasKebutuhanRkbWilayahTabel: Actions.getSarprasKebutuhanRkbWilayahTabel,
        getRaporDapodikWilayah: Actions.getRaporDapodikWilayah,
        setRaporDapodikWilayah: Actions.setRaporDapodikWilayah,
        getRaporDapodikSekolah: Actions.getRaporDapodikSekolah,
        // getRaporDapodikRadar: Actions.getRaporDapodikRadar,
        // getRaporDapodikAkuratRadar: Actions.getRaporDapodikAkuratRadar,
        // getRaporDapodikMutakhirRadar: Actions.getRaporDapodikMutakhirRadar,
        getWilayah: Actions.getWilayah,
        getSPMKabupaten: Actions.getSPMKabupaten,
        getSPMKabupatenPerKecamatan: Actions.getSPMKabupatenPerKecamatan,
        getSPMKabupatenPerSekolah: Actions.getSPMKabupatenPerSekolah,
        getRekapSekolahRingkasan: Actions.getRekapSekolahRingkasan,
        getRekapSekolahTotal: Actions.getRekapSekolahTotal,
        getPesertaDidikTingkatKelasPie: Actions.getPesertaDidikTingkatKelasPie,
        getGtkJenisPie: Actions.getGtkJenisPie,
        getRekapValidasiBeranda: Actions.getRekapValidasiBeranda,
        getRekapPesertaDidikRingkasan: Actions.getRekapPesertaDidikRingkasan,
        getRekapGTKRingkasan: Actions.getRekapGTKRingkasan,
        getIndexPendidikan: Actions.getIndexPendidikan,
        saveLog: Actions.saveLog,
        // getRls: Actions.getRls,
        getCountPDwilayah: Actions.getCountPDwilayah,
        getSemester: Actions.getSemester
    }, dispatch);
}

function mapStateToProps({ App, Sarpras, RaporDapodik, Spm, RekapSekolah, PesertaDidik, Gtk, ValidasiData, RekapPesertaDidik, RekapGTK, RekapRobel }) {
    return {
        window_dimension: App.window_dimension,
        loading: App.loading,
        tabBar: App.tabBar,
        wilayah: App.wilayah,
        rekap_sekolah_sarpras: Sarpras.rekap_sekolah_sarpras,
        rekap_sekolah_sarpras_wilayah: Sarpras.rekap_sekolah_sarpras_wilayah,
        sarpras_kerusakan_wilayah: Sarpras.sarpras_kerusakan_wilayah,
        sarpras_kerusakan_wilayah_tabel: Sarpras.sarpras_kerusakan_wilayah_tabel,
        sarpras_jenis_wilayah: Sarpras.sarpras_jenis_wilayah,
        sarpras_jenis_wilayah_tabel: Sarpras.sarpras_jenis_wilayah_tabel,
        sarpras_kebutuhan_rkb_wilayah: Sarpras.sarpras_kebutuhan_rkb_wilayah,
        sarpras_kebutuhan_rkb_wilayah_tabel: Sarpras.sarpras_kebutuhan_rkb_wilayah_tabel,
        rapor_dapodik_wilayah: RaporDapodik.rapor_dapodik_wilayah,
        rapor_dapodik_sekolah: RaporDapodik.rapor_dapodik_sekolah,
        rapor_dapodik_radar: RaporDapodik.rapor_dapodik_radar,
        rapor_dapodik_akurat_radar: RaporDapodik.rapor_dapodik_akurat_radar,
        rapor_dapodik_mutakhir_radar: RaporDapodik.rapor_dapodik_mutakhir_radar,
        spm_kabupaten: Spm.spm_kabupaten,
        spm_kabupaten_per_kecamatan: Spm.spm_kabupaten_per_kecamatan,
        spm_kabupaten_per_sekolah: Spm.spm_kabupaten_per_sekolah,
        dummy_rows: App.dummy_rows,
        rekap_sekolah_ringkasan: RekapSekolah.rekap_sekolah_ringkasan,
        rekap_sekolah_total: RekapSekolah.rekap_sekolah_total,
        peserta_didik_tingkat_kelas_pie: PesertaDidik.peserta_didik_tingkat_kelas_pie,
        gtk_jenis_pie: Gtk.gtk_jenis_pie,
        rekap_peserta_didik_ringkasan: RekapPesertaDidik.rekap_peserta_didik_ringkasan,
        rekap_gtk_ringkasan: RekapGTK.rekap_gtk_ringkasan,
        rekap_validasi_beranda: ValidasiData.rekap_validasi_beranda,
        rls: RekapRobel.rls,
        count_pd_wilayah: PesertaDidik.count_pd_wilayah,
        semester: App.semester,
        semester_aktif: App.semester_aktif
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Beranda);