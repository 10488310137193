import React, { Component } from 'react';
import { Page, Navbar, NavTitle, Link, Popover, Block, NavTitleLarge, NavRight, Card, CardContent } from 'framework7-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';

class DaftarSatuanPendidikan extends Component {
    state = {
        error: null,
        loading: true,
    }

    componentDidMount = () => {
        const params = this.$f7route.params;
        
        this.props.getRekap(params);
    }   
    
    backClick = () => {
        const link = `/RekapData`;
        this.$f7router.navigate(link);
    }

    handleOpen = (id) => {
        const link = `/ProfilSekolah/${id}`;
        this.$f7router.navigate(link);
    }

    render() {
        const { rows } = this.props.rekap;

        return (
            <Page name="apk-apm" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>----</NavTitle>
                    <NavTitleLarge style={{background:'#F6BC31',color:'white'}}>
                        Daftar Satuan Pendidikan
                    </NavTitleLarge>
                    <NavRight >
                        <Link panelOpen="right" onClick={this.bukaPengaturan} iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu">&nbsp;Menu</Link>
                    </NavRight>
                </Navbar>

                <div>
                    <Block strong style={{
                        marginTop:'0px', 
                        paddingLeft:'0px', 
                        paddingRight:'0px', 
                        paddingTop:'0px', 
                        paddingBottom:'0px'
                    }}>
                        <Card>
                            <CardContent>
                                <div className="data-table" style={{overflowY:'hidden'}}>
                                    <table style={{  border: '1px solid #FFF', minWidth: 1400 }}>
                                        <thead style={{ backgroundColor: '#222a35', textAlign: 'center' }}>
                                            <th>No</th>
                                            <th>Nama Sekolah</th>
                                            <th>NPSN</th>
                                            <th>Status</th>
                                            <th>Kecamatan</th>
                                            <th>Alamat</th>
                                            <th>Kepsek</th>
                                        </thead>

                                        <tbody>
                                            { rows.map((opt, key) => {

                                                return (
                                                    <tr key={key}>
                                                        <td>{ key+1 }</td>
                                                        <td>
                                                            <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.handleOpen(opt.sekolah_id)}>{ opt.nama }</span>
                                                        </td>
                                                        <td>{ opt.npsn }</td>
                                                        <td>{ opt.status_sekolah }</td>
                                                        <td>{ opt.kecamatan }</td>
                                                        <td>{ opt.alamat }</td>
                                                        <td>{ opt.kepsek }</td>
                                                    </tr>
                                                )
                                            }) }
                                        </tbody>
                                    </table>
                                </div>
                            </CardContent>
                        </Card>
                    </Block>
                </div>
            </Page>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension       : Actions.updateWindowDimension,
        getRekap                    : Actions.getDaftarSatuanPendidikan,
        setLoading                  : Actions.setLoading,
    }, dispatch);
}

function mapStateToProps({ App }) {

    return {
        window_dimension            : App.window_dimension,
        rekap                       : App.daftar_satuan_pendidikan,
        loading                     : App.loading,
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(DaftarSatuanPendidikan));