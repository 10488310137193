import axios from 'axios/index';

export const GET_REKAP_GTK_RINGKASAN = '[REKAP GTK] GET REKAP GTK RINGKASAN';
export const GET_REKAP_GTK_RINGKASAN_SP = '[REKAP GTK] GET REKAP GTK RINGKASAN SP';
export const GET_REKAP_GTK_AGAMA = '[REKAP GTK] GET REKAP GTK AGAMA';
export const GET_REKAP_GTK_AGAMA_SP = '[REKAP GTK] GET REKAP GTK AGAMA SP';
export const GET_REKAP_GTK_STATUS_KEPEGAWAIAN = '[REKAP GTK] GET REKAP GTK STATUS KEPEGAWAIAN';
export const GET_REKAP_GTK_STATUS_KEPEGAWAIAN_SP = '[REKAP GTK] GET REKAP GTK STATUS KEPEGAWAIAN SP';
export const GET_REKAP_GTK_JENIS_KEPEGAWAIAN = '[REKAP GTK] GET REKAP GTK JENIS KEPEGAWAIAN';
export const GET_REKAP_GTK_JENIS_KEPEGAWAIAN_SP = '[REKAP GTK] GET REKAP GTK JENIS KEPEGAWAIAN SP';
export const GET_REKAP_GTK_SATUAN_PENDIDIKAN = '[REKAP GTK] GET REKAP GTK SATUAN PENDIDIKAN';
export const GET_REKAP_GTK_SATUAN_PENDIDIKAN_SP = '[REKAP GTK] GET REKAP GTK SATUAN PENDIDIKAN SP';
export const GET_REKAP_GTK_SERTIFIKASI = '[REKAP GTK] GET REKAP GTK SERTIFIKASI';
export const GET_REKAP_GTK_SERTIFIKASI_SP = '[REKAP GTK] GET REKAP GTK SERTIFIKASI SP';

// ringkasan
export function getRekapGTKRingkasan(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKRingkasan', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_RINGKASAN,
            payload: response.data,
            routeParams
        })
    });

}

export function getRekapGTKRingkasanSp(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKRingkasanSp', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_RINGKASAN_SP,
            payload: response.data,
            routeParams
        })
    });

}


// agama
export function getRekapGTKAgama(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKAgama', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_AGAMA,
            payload: response.data,
            routeParams
        })
    });

}

export function getRekapGTKAgamaSp(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKAgamaSp', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_AGAMA_SP,
            payload: response.data,
            routeParams
        })
    });

}

// status kepegawaian
export function getRekapGTKStatusKepegawaian(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKStatusKepegawaian', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_STATUS_KEPEGAWAIAN,
            payload: response.data,
            routeParams
        })
    });
}

export function getRekapGTKStatusKepegawaianSp(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKStatusKepegawaianSp', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_STATUS_KEPEGAWAIAN_SP,
            payload: response.data,
            routeParams
        })
    });
}

// Jenis Kepegawaian
export function getRekapGTKJenisKepegawaian(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKJenisKepegawaian', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_JENIS_KEPEGAWAIAN,
            payload: response.data,
            routeParams
        })
    });
}

export function getRekapGTKJenisKepegawaianSp(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKJenisKepegawaianSp', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_JENIS_KEPEGAWAIAN_SP,
            payload: response.data,
            routeParams
        })
    });
}

export function getRekapGTKSatuanPendidikan(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKSatuanPendidikan', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_SATUAN_PENDIDIKAN,
            payload: response.data,
            routeParams
        })
    });
}

export function getRekapGTKSatuanPendidikanSp(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKSatuanPendidikanSp', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_SATUAN_PENDIDIKAN_SP,
            payload: response.data,
            routeParams
        })
    });
}

export function getRekapGTKSertifikasi(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKSertifikasi', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_SERTIFIKASI,
            payload: response.data,
            routeParams
        })
    });
}

export function getRekapGTKSertifikasiSp(routeParams)
{

    const request = axios.post(localStorage.getItem('api_base')+'/api/GTK/getRekapGTKSertifikasiSp', {
        ...routeParams
    });
    
    return (dispatch) =>
    request.then((response) =>
    {
        dispatch({
            type   : GET_REKAP_GTK_SERTIFIKASI_SP,
            payload: response.data,
            routeParams
        })
    });
}