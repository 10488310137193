import React from "react";
import { Block, Button, Link, List, ListItem, Navbar, NavLeft, NavRight, NavTitle, NavTitleLarge, Page } from "framework7-react";
import { useState } from "react";

function NPD() {
    const [tahun, setTahun] = useState("2022");
    const kode_wilayah = localStorage.getItem('kode_wilayah_aplikasi') || '026100';

    const data = [
        // Kota Bogor
        { wilayah: '026100', tahun : "2022" , link: "Kota_Bogor/026100_Kota_Bogor_2022-1.jpg" },
        { wilayah: '026100', tahun : "2022" , link: "Kota_Bogor/026100_Kota_Bogor_2022-2.jpg" },
        { wilayah: '026100', tahun : "2021" , link: "Kota_Bogor/026100_Kota_Bogor_2021-1.jpg" },
        { wilayah: '026100', tahun : "2021" , link: "Kota_Bogor/026100_Kota_Bogor_2021-2.jpg" },
        { wilayah: '026100', tahun : "2020" , link: "Kota_Bogor/026100_Kota_Bogor_2020-1.jpg" },
        { wilayah: '026100', tahun : "2020" , link: "Kota_Bogor/026100_Kota_Bogor_2020-2.jpg" },
        { wilayah: '026100', tahun : "2019" , link: "Kota_Bogor/026100_Kota_Bogor_2019-1.jpg" },
        { wilayah: '026100', tahun : "2019" , link: "Kota_Bogor/026100_Kota_Bogor_2019-2.jpg" },
        { wilayah: '026100', tahun : "2018" , link: "Kota_Bogor/026100_Kota_Bogor_2018-1.jpg" },
        { wilayah: '026100', tahun : "2017" , link: "Kota_Bogor/026100_Kota_Bogor_2017-1.jpg" },
        { wilayah: '026100', tahun : "2016" , link: "Kota_Bogor/026100_Kota_Bogor_2016-1.jpg" },
        { wilayah: '026100', tahun : "2016" , link: "Kota_Bogor/026100_Kota_Bogor_2016-2.jpg" },
        { wilayah: '026100', tahun : "2015" , link: "Kota_Bogor/026100_Kota_Bogor_2015-1.jpg" },
        { wilayah: '026100', tahun : "2015" , link: "Kota_Bogor/026100_Kota_Bogor_2015-2.jpg" },

        // Prov Yogyakarta
        { wilayah: '040000', tahun: '2022', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2022-1.jpg' },
        { wilayah: '040000', tahun: '2022', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2022-2.jpg' },
        { wilayah: '040000', tahun: '2021', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2021-1.jpg' },
        { wilayah: '040000', tahun: '2021', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2021-2.jpg' },
        { wilayah: '040000', tahun: '2020', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2020-1.jpg' },
        { wilayah: '040000', tahun: '2020', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2020-2.jpg' },
        { wilayah: '040000', tahun: '2019', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2019-1.jpg' },
        { wilayah: '040000', tahun: '2019', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2019-2.jpg' },
        { wilayah: '040000', tahun: '2018', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2018-1.jpg' },
        { wilayah: '040000', tahun: '2018', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2018-2.jpg' },
        { wilayah: '040000', tahun: '2017', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2017-1.jpg' },
        { wilayah: '040000', tahun: '2017', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2017-2.jpg' },
        { wilayah: '040000', tahun: '2016', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2016-1.jpg' },
        { wilayah: '040000', tahun: '2016', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2016-2.jpg' },
        { wilayah: '040000', tahun: '2015', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2015-1.jpg' },
        { wilayah: '040000', tahun: '2015', link: 'Prov_Yogyakarta/040000_Prov_DI_Yogyakarta_2015-2.jpg' },
    ]

    const data_pdf = {
        // Kota bogor
        '026100_2022': "Kota_Bogor/026100_Kota_Bogor_2022.pdf",
        '026100_2021': "Kota_Bogor/026100_Kota_Bogor_2021.pdf",
        '026100_2020': "Kota_Bogor/026100_Kota_Bogor_2020.pdf",
        '026100_2019': "Kota_Bogor/026100_Kota_Bogor_2019.pdf",
        '026100_2018': "Kota_Bogor/026100_Kota_Bogor_2018.pdf",
        '026100_2017': "Kota_Bogor/026100_Kota_Bogor_2017.pdf",
        '026100_2016': "Kota_Bogor/026100_Kota_Bogor_2016.pdf",
        '026100_2015': "Kota_Bogor/026100_Kota_Bogor_2015.pdf",

        // Prov Yogyakarta
        '040000_2022': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2022.pdf',
        '040000_2021': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2021.pdf',
        '040000_2020': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2020.pdf',
        '040000_2019': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2019.pdf',
        '040000_2018': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2018.pdf',
        '040000_2017': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2017.pdf',
        '040000_2016': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2016.pdf',
        '040000_2015': 'Prov_Yogyakarta/040000_Prov_Yogyakarta_2015.pdf',
    }

    const filter = ["2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015"];

    const data_ = data.filter((o) => o.tahun === tahun && o.wilayah === kode_wilayah);
    
    return (
        <Page name="Beranda">
            {parseInt(localStorage.getItem('sudah_login')) === 1 &&
            <Navbar>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="sideMenuToggle" />
              </NavLeft>
              <NavTitle>Neraca Pendidikan Daerah (NPD)</NavTitle>
              <NavRight>
                <List>
                    <ListItem title="Tahun" smartSelect smartSelectParams={{ openIn: 'popover', routableModals: false, closeOnSelect: true }}>
                        <select
                            name="tahun"
                            onChange={(event) => setTahun(event.target.value)}
                        >
                            { filter.map((opt, key) =>
                                <option key={key} value={opt}>{opt}</option>
                            ) }
                        </select>
                    </ListItem>
                </List>&nbsp;
                <Button fill round color="red"
                    onClick={() => {
                        const link = `/static/npd_pdf/${data_pdf[kode_wilayah+'_'+tahun]}`;
                        // window.location.assign(link)
                        window.open(link, '_blank');
                    }}
                    target="_blank"
                >Unduh PDF</Button>
              </NavRight>
            </Navbar>
            }
            
            <Block
                style={{
                    marginTop:'0px', 
                    paddingLeft:'0px', 
                    paddingRight:'0px', 
                    paddingTop:'0px', 
                    paddingBottom:'0px'
                }}
            >
                { data_.map((opt, key) => {

                    return (
                        <div key={key}>
                            <img alt="" src={`/static/npd_image/${opt.link}`} style={{ width: "100%" }} />
                        </div>
                    )
                }) }
            </Block>
        </Page>
    )
}

export default NPD;