import React from "react";

const dataInput = {
    title: `Input APK TK/RA > Rekap Data Jumlah Penduduk Usia 5-6 dengan Jumlah Siswa TK/RA`,
    data: [],
    columns: [
        {
            Header: 'No',
            rowSpan: 2,
            columns: [
                {
                    Header: 'NO',
                    skip:1,
                    footerLabel: '',
                    Cell:({row}) => {
                        const { index } = row;
                        return index + 1;
                    },
                },
            ]
        },
        {
            id: 'kecamatan',
            Header: () => {
                const kode_wilayah = localStorage.getItem('kode_wilayah_aplikasi');
                return(
                    kode_wilayah === '040000' ? "Kab/Kota" : "Kecamatan"
                )
            },
            className: 'label-cell',
            rowSpan:2,
            columns: [
                {   
                    skip:1,
                    id: 'kecamatan',
                    Header: '',
                    accessor: 'kecamatan',
                    footerLabel: 'Total',
                    Cell: ({ row }) => (
                        <div style={{ textAlign: "left" }}>
                            {row.original.kecamatan}
                        </div>
                    )
                },
            ]
        },
        {
            Header: 'Jumlah Penduduk Umur 5-6',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'penduduk_5_6_l',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'penduduk_5_6_p',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    id: 'jml_ppd',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { penduduk_5_6_l, penduduk_5_6_p } = row.original;

                        const value = parseInt(penduduk_5_6_l || 0) + parseInt(penduduk_5_6_p || 0);

                        return value;
                    }
                },
            ]
        },
        {
            Header: 'Jumlah Seluruh TK',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_l_1',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_p_1',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    id: 'jumlah_1',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_1, murid_p_1 } = row.original;
                        const value = parseInt(murid_l_1 || 0) + parseInt(murid_p_1 || 0);

                        return value;
                    }
                },
            ]
        },
        {
            Header: 'Jumlah Seluruh RA',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_l_34',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_p_34',
                    className: 'small-only',
                    type: 'input',
                    footerLabel: 'sum',
                    align: 'center',
                },
                {
                    id: 'jumlah_34',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_34, murid_p_34 } = row.original;
                        const value = parseInt(murid_l_34 || 0) + parseInt(murid_p_34 || 0);

                        return value;
                    }
                },
            ]
        },
        {
            Header: 'Jumlah',
            align: 'center',
            columns: [
                {
                    id: 'jumlah_siswa_l_total',
                    Header: 'L',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_l_34, murid_l_1 } = row.original;
        
                        const value = parseInt(murid_l_34 || 0) + parseInt(murid_l_1 || 0);
        
                        return value;
                    }
                },
                {
                    id: 'jumlah_siswa_p_total',
                    Header: 'P',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_p_34, murid_p_1 } = row.original;
        
                        const value = parseInt(murid_p_34 || 0) + parseInt(murid_p_1 || 0);
        
                        return value;
                    }
                },
                {
                    id: 'jumlah_siswa_total',
                    Header: 'Jumlah',
                    className: 'small-only',
                    footerLabel: 'sum',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_p_34, murid_l_34, murid_p_1, murid_l_1 } = row.original;
        
                        const value = parseInt(murid_p_34 || 0) + parseInt(murid_p_1 || 0) + parseInt(murid_l_34 || 0) + parseInt(murid_l_1 || 0);
        
                        return value;
                    }
                },
            ]
        },
    ]
}

export default dataInput;