import React, { Component } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavTitle,
  Link,
  Row,
  Col,
  Button,
  Icon,
} from 'framework7-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';
import Table from './Table';

class ProfilPendidikan extends Component {
  state = {
    error: null,
    loading: true,
    current_url: localStorage.getItem('current_url'),
    searchQuery: {
      bentuk_pendidikan: '',
      req: '',
    },
  };

  componentDidMount = () => {
    const query = this.props.f7route.query;

    this.setState(
      {
        ...this.state,
        searchQuery: {
          ...this.state.searchQuery,
          bentuk_pendidikan: query.bentuk_pendidikan,
          req: query.req || '',
        },
      },
      () => {}
    );

    // Show preloader when data is being loaded
    this.$f7.dialog.preloader('Memuat data');

    this.props.getDataProfilPendidikan({
      module: this.props.module,
      ...(query.bentuk_pendidikan && { bentuk_pendidikan: query.bentuk_pendidikan }),
      ...(query.req && { req: query.req }),
    })
    .then(() => {
      // Hide preloader when data is fetched
      this.$f7.dialog.close();
    })
    .catch((error) => {
      // Handle error (if any)
      console.error('Error fetching data:', error);
      // Hide preloader even if there's an error
      this.$f7.dialog.close();
    });
  };

  componentDidUpdate = (prevProps, prevState) => {};

  render() {
    const { data } = this.props;

    const TitleFromModule = () => {
      const title = this.props.module;
      const titleCase = title.replace(/-/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
      return titleCase;
    };

    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += parseInt(this[i][prop]);
      }
      return total;
    };

    return (
      <Page name="profil-pendidikan-data" hideBarsOnScroll>
        <Navbar sliding={false}>
          <NavLeft>
            <Link
              iconIos="f7:chevron_left"
              iconAurora="f7:chevron_left"
              iconMd="material:chevron_left"
              href="/"
            >
              Beranda
            </Link>
          </NavLeft>
          <NavTitle sliding>
            Profil Pendidikan{' '}&#45;{' '}{TitleFromModule()}{' '}
            {this.state.searchQuery.bentuk_pendidikan &&
              this.state.searchQuery.bentuk_pendidikan.toUpperCase()}
          </NavTitle>
        </Navbar>
        {this.props.loaded && (
          <div style={{ padding: '2% 3%' }}>
            <Table
              current_url={TitleFromModule()}
              columns={this.props.profilPendidikanPsp.columns}
              data={this.props.profilPendidikanPsp.rows}
              title={TitleFromModule()}
              rows={this.props.profilPendidikanPsp.rows}
              total={[]}
            />
          </div>
        )}
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWindowDimension: Actions.updateWindowDimension,
      getDataProfilPendidikan: Actions.getDataProfilPendidikan,
    },
    dispatch
  );
}

function mapStateToProps({ App, ProfilPendidikan }) {
  return {
    window_dimension: App.window_dimension,
    data: App.data_apk_apm,
    loading: App.loading,
    profilPendidikanPsp: ProfilPendidikan.profilPendidikanPsp,
    loaded: ProfilPendidikan.loaded,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilPendidikan);
