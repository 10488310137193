import axios from 'axios/index';

export const GET_PROFIL_PENDIDIKAN_PSP = '[PROFIL_PENDIDIKAN] GET PSP';
export const GET_PROFIL_PENDIDIKAN_PD_LUAR = '[PROFIL_PENDIDIKAN] GET PD LUAR';

export function getDataProfilPendidikan(routeParams) {
    // const request = axios.post(localStorage.getItem('api_base')+'/api/getDataProfilPendidikan', {
        let req = '';
        
        if(routeParams.req) {
            req = routeParams.req;
        } else {
            req = routeParams.module;
        }

        const request = axios.get(localStorage.getItem('api_base')+`/api/profilPendidikan/${req}`, {
            params : {
                bentuk_pendidikan: routeParams.bentuk_pendidikan,
            }
        });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_PROFIL_PENDIDIKAN_PSP,
                payload: response.data,
                routeParams
            })
        );
}
export function getDataPDLuar(routeParams) {
        let req = '';
        
        if(routeParams.req) {
            req = routeParams.req;
        } else {
            req = routeParams.module;
        }

        const request = axios.get(localStorage.getItem('api_base')+`/api/profilPendidikan/getPDSekolahLuarDiy/get`, {
            params : {
                bentuk_pendidikan: routeParams.bentuk_pendidikan,
            }
        });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_PROFIL_PENDIDIKAN_PD_LUAR,
                payload: response.data,
                routeParams
            })
        );
}