import * as Actions from '../actions';

const initialState = {
    apkapm              : { count: 0, rows: [] },
    anggaran            : { count: 0, rows: [] },
    akreditasi          : { count: 0, rows: [] },
    gurumenurutusia     : { count: 0, rows: [] },
    kondisiruangkelas   : { count: 0, rows: [] },
    kualifikasipendidik : { count: 0, rows: [] },
    ipm                 : { count: 0, rows: [] },
    siswa_mengulang     : { const: 0, rows: [] },

    satuanpendikan      : { count: 0, rows: [] },
    pendidik            : { count: 0, rows: [] },
    pesertadidik        : { count: 0, rows: [] },
    ruangkelas          : { count: 0, rows: [] },
    tendik              : { count: 0, rows: [] },
    rombel              : { count: 0, rows: [] },
};

const NPDReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_APKAPM:
        {
            return {
                ...state,
                apkapm: action.payload
            };
        }
        case Actions.GET_ANGGARAN:
        {
            return {
                ...state,
                anggaran: action.payload
            };
        }
        case Actions.GET_AKREDITASI:
        {
            return {
                ...state,
                akreditasi: action.payload
            };
        }
        case Actions.GET_GURU_MENURUT_USIA:
        {
            return {
                ...state,
                gurumenurutusia: action.payload
            };
        }
        case Actions.GET_KONDISI_RUANG_KELAS:
        {
            return {
                ...state,
                kondisiruangkelas: action.payload
            };
        }
        case Actions.GET_KUALIFIKASI_PENDIDIKAN:
        {
            return {
                ...state,
                kualifikasipendidik: action.payload
            };
        }
        case Actions.GET_SATUAN_PENDIDIKAN:
        {
            return {
                ...state,
                satuanpendikan: action.payload
            };
        }
        case Actions.GET_PENDIDIK:
        {
            return {
                ...state,
                pendidik: action.payload
            };
        }
        case Actions.GET_PESERTA_DIDIK:
        {
            return {
                ...state,
                pesertadidik: action.payload
            };
        }
        case Actions.GET_RUANG_KELAS:
        {
            return {
                ...state,
                ruangkelas: action.payload
            };
        }
        case Actions.GET_TENDIK:
        {
            return {
                ...state,
                tendik: action.payload
            };
        }
        case Actions.GET_ROMBEL:
        {
            return {
                ...state,
                rombel: action.payload
            };
        }
        case Actions.GET_IPM:
        {
            return {
                ...state,
                ipm: action.payload
            }
        }
        case Actions.GET_SISWA_MENGULANG: 
        {
            return {
                ...state,
                siswa_mengulang: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
}

export default NPDReducer;