import React from "react";

const dataInputSMA = {
    title: `Input APM SMA/SMK > Rekap Data Jumlah Penduduk Usia 16-18 Tahun dengan Jumlah Siswa SMA/SMK`,
    data: [],
    columns: [
        {
            Header: 'No',
            rowSpan: 2,
            columns: [
                {
                    Header: 'NO',
                    skip:1,
                    Cell:({row}) => {
                        const { index } = row;
                        return index + 1;
                    },
                },
            ]
        },
        {
            // Header: 'Kecamatan',
            Header: () => {
                const kode_wilayah = localStorage.getItem('kode_wilayah_aplikasi');
                return(
                    kode_wilayah === '040000' ? "Kab/Kota" : "Kecamatan"
                )
            },
            id: 'kecamatan',
            rowSpan: 2,
            columns: [
                {
                    Header: '',
                    skip: 1,
                    accessor: 'kecamatan',
                    Cell: ({ row }) => (
                        <div style={{ textAlign: "left" }}>
                            {row.original.kecamatan}
                        </div>
                    )
                },
            ]
        },
        {
            Header: 'Jumlah Penduduk Umur 16-18',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'penduduk_16_18_l',
                    type: 'input',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'penduduk_16_18_p',
                    type: 'input',
                    align: 'center',
                },
                {
                    id: 'jml_ppd',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { penduduk_16_18_l, penduduk_16_18_p } = row.original;

                        const value = parseInt(penduduk_16_18_l || 0) + parseInt(penduduk_16_18_p || 0);

                        return (<span>{ value }</span>)
                    }
                },
            ]
        },
        {
            Header: 'Jumlah Siswa SMA/SMK Umur 16-18',
            align: 'center',
            columns: [
                {
                    Header: 'L',
                    accessor: 'murid_usia_ideal_l_13',
                    type: 'input',
                    align: 'center',
                },
                {
                    Header: 'P',
                    accessor: 'murid_usia_ideal_p_13',
                    type: 'input',
                    align: 'center',
                },
                {
                    id: 'jumlah_13',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_usia_ideal_l_13, murid_usia_ideal_p_13 } = row.original;
                        const value = parseInt(murid_usia_ideal_l_13 || 0) + parseInt(murid_usia_ideal_p_13 || 0);

                        return (<span>{ value }</span>)
                    }
                },
            ]
        },
        {
            Header: 'Jumlah',
            align: 'center',
            columns: [
                {
                    id: 'jumlah_siswa_l_total',
                    Header: 'L',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_usia_ideal_l_13 } = row.original;
        
                        const value = parseInt(murid_usia_ideal_l_13 || 0) ;
        
                        return (<span>{ value }</span>)
                    }
                },
                {
                    id: 'jumlah_siswa_p_total',
                    Header: 'P',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_usia_ideal_p_13 } = row.original;
        
                        const value = parseInt(murid_usia_ideal_p_13 || 0);
        
                        return (<span>{ value }</span>)
                    }
                },
                {
                    id: 'jumlah_siswa_total',
                    Header: 'Jumlah',
                    align: 'center',
                    Cell: ({ row }) => {
                        const { murid_usia_ideal_p_13, murid_usia_ideal_l_13 } = row.original;
        
                        const value = parseInt(murid_usia_ideal_p_13 || 0) + parseInt(murid_usia_ideal_l_13 || 0);
        
                        return (<span>{ value }</span>)
                    }
                },
            ]
        }
    ]
}

export default dataInputSMA;