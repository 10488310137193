import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../store/actions';
import { Card, CardContent, CardHeader, Col, List, ListItem, Navbar, NavTitle, NavTitleLarge, Page, Row } from 'framework7-react';

class ProfilSekolahAnggotaDetailPD extends Component {
    state = {
        loading: true,
        routeParams: {
            peserta_didik_id: this.props.peserta_didik_id            
        }
    }

    getUmut = (date) => {
        if(date !== ""){
            var today = new Date();
            var birthday = new Date(date);
            var year = 0;
            if (today.getMonth() < birthday.getMonth()) {
                year = 1;
            } else if ((today.getMonth() == birthday.getMonth()) && today.getDate() < birthday.getDate()) {
                year = 1;
            }
            var age = today.getFullYear() - birthday.getFullYear() - year;
    
            if(age < 0){
                age = 0;
            }
            return age;
        }else{
            return "-";
        }
    }
    
    componentDidMount = () => {
        this.setState({ loading: true });
            
        this.props.getPesertaDidikDetail(this.state.routeParams).then(() => {
            this.setState({ loading: false });
        });
    }

    render()
    {
        const { peserta_didik_detail:pd } = this.props;

        return (
            <Page name="ProfilSekolahAnggotaDetailPD" hideBarsOnScroll>
                <Navbar sliding={false} backLink="Kembali" onBackClick={this.backClick}>
                    <NavTitle sliding>Biodata Peserta Didik</NavTitle>
                    <NavTitleLarge>
                        Biodata Peserta Didik
                    </NavTitleLarge>
                </Navbar>
                <Card>
                    <CardContent>
                        <Row>
                            <Col width={100} tabletWidth={50}>
                                <Card>
                                    <CardHeader>IDENTITAS PESERTA DIDIK</CardHeader>
                                    <CardContent>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Nama Lengkap
                                            </Col>
                                            <Col width="60">
                                                : <b>{ pd.nama }</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                NIK
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.nik}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Jenis Kelamin
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.jenis_kelamin}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Tempat, Tanggal Lahir
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.tempat_lahir + ", " + pd.tanggal_lahir}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Umur
                                            </Col>
                                            <Col width="60">
                                                : <b>{ this.getUmut(pd.tanggal_lahir) } Tahun</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Agama
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.agama}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Nama Ayah
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.nama_ayah}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Nama Ibu
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.nama_ibu_kandung}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Anak ke
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.anak_keberapa}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Jumlah Saudara
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.jumlah_saudara_kandung}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Pekerjaan Ayah
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.pekerjaan_ayah}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Pekerjaan Ibu
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.pekerjaan_ibu}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Pendidikan Ayah
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.jenjang_pendidikan_ayah}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Pendidikan Ibu
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.jenjang_pendidikan_ibu}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Tinggi Badan
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.tinggi_badan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Berat Badan
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.berat_badan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Jarak Rumah
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.jarak_rumah_ke_sekolah}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Hoby
                                            </Col>
                                            <Col width="60">
                                                : -
                                            </Col>
                                        </Row>
                                    </CardContent>
                                </Card>
                            </Col>
                            <Col width={100} tabletWidth={50}>
                                <Card>
                                    <CardHeader>ALAMAT</CardHeader>
                                    <CardContent>         
                                        <Row style={{marginBottom:'8px'}}>                       
                                            <Col width="40">
                                                Kampung / Jalan
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.alamat_jalan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Desa
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.desa_kelurahan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Kecamatan
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.kecamatan}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Kode POS
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.kode_pos}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Kabupaten
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.kabupaten}</b>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:'8px'}}>
                                            <Col width="40">
                                                Propinsi
                                            </Col>
                                            <Col width="60">
                                                : <b>{pd.provinsi}</b>
                                            </Col>
                                        </Row>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
            </Page>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPesertaDidikDetail           : Actions.getPesertaDidikDetail,
    }, dispatch);
}

function mapStateToProps({ App, PesertaDidik }) {

    return {
        loading                         : App.loading,
        peserta_didik_detail            : PesertaDidik.peserta_didik_detail
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ProfilSekolahAnggotaDetailPD));