import * as Actions from '../actions';

const initialState = {
    profilPendidikanPspObj: { rows: [] },
    profilPendidikanPsp: {
        rows: [],
        columns: [],
    },
    profilPendidikanPdLuar: {
        data: [],
        total: 0,
        perPage: 0,
    },
    loaded: false
};

const ProfilPendidikanReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_PROFIL_PENDIDIKAN_PSP:
        {
            return {
                ...state,
                profilPendidikanPsp: action.payload,
                loaded: true
            };
        }
        case Actions.GET_PROFIL_PENDIDIKAN_PD_LUAR:
        {
            console.log(action.payload);
            return {
                ...state,
                profilPendidikanPdLuar: action.payload,
                loaded: true
            };
        }
        default:
        {
            return state;
        }
    }
}

export default ProfilPendidikanReducer;