import React, { Component } from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    Link,
    Row,
    Col,
    Button,
    Icon,
    Popover,
} from 'framework7-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../../store/actions';
import ApkTable from './ApkTable';
import ApmRaTable from './ApmTable';
import ConfigPaudRa from './configTable/ConfigPaudRa';
import ConfigPaudSdMiPaketA from './configTable/ConfigPaudSdMiPaketA';
import ConfigPaudSmpMTsPaketB from './configTable/ConfigPaudSmpMTsPaketB';
import ConfigSmaSmkPaketC from './configTable/ConfigSmaSmkPaketC';
import ConfigPaud from './configTable/ConfigPaud';
import ConfigPaud04 from './configTable/ConfigPaud04';
import ConfigSLB from './configTable/ConfigSLB';


class ApkApmPaudRa extends Component {
    state = {
        error: null,
        loading: true,
        current_url: localStorage.getItem('current_url'),
        // config: {
        //     paud_ra : {
        //         apk: {},
        //         apm: {}
        //     }
        // },
    }

    componentDidMount = () => {
        this.$f7.dialog.preloader('Memuat data'); // Show preloader when data is being fetched

        const current_url = this.props.f7route.path;
        
        let jenjang = 1;
        if (current_url.includes('/apk/paud-ra')) {
            jenjang = 1;
        } else if (current_url.includes('/apk/sd-mi-paketa')) {
            jenjang = 2;
        } else if (current_url.includes('/apk/smp-mts-paketb')) {
            jenjang = 3;
        } else if(current_url.includes('/apk/sma-paketc')) {
            jenjang = 4;
        } else if(current_url.includes('/apk/paud')) {
            jenjang = 0;
        }

        this.props.getDataApkapm({ jenjang_id: jenjang })
            .then(() => {
                this.$f7.dialog.close(); // Close preloader when data is loaded
            })
            .catch((error) => {
                this.$f7.dialog.close(); // Close preloader in case of an error
                console.error('Error fetching data:', error);
            });

        if (parseInt(localStorage.getItem('sudah_login')) !== 1 && localStorage.getItem('harus_login') === 'Y') {
            this.$f7router.navigate('/login');
        }

        localStorage.setItem('current_url', this.$f7route.url);
        this.setState({
            ...this.state,
            current_url: localStorage.getItem('current_url')
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.current_url !== localStorage.getItem('current_url')) {
            this.setState({
                ...this.state,
                current_url: localStorage.getItem('current_url')
            });
        }
    }

    render() {
        const { current_url } = this.state;
        const { data } = this.props;

        const data_ = data.rows || [];

        const TitleFromCurrentUrl = () => {
            if (current_url.includes('/apm/paud-ra')) {
                return 'TK/RA'
            } else if (current_url.includes('/apm/sd-mi-paketa')) {
                return 'SD/MI/Paket A'
            } else if (current_url.includes('/apm/smp-mts-paketb')) {
                return 'SMP/MTs/Paket B'
            }else if(current_url.includes('/apm/sma-smk-paketc')){
                return 'SMA/SMK/Paket C'
            }else if(current_url.includes('/apm/paud')){
                return 'PAUD'
            }
        }

        Array.prototype.sum = function (prop) {
            var total = 0
            for ( var i = 0, _len = this.length; i < _len; i++ ) {
                total += parseInt(this[i][prop])
            }
            return total
        }
        const dataApm = data_.map((opt) => {
            return {
                kecamatan: opt.nama,
                jp04_laki_laki: parseFloat((opt.murid_usia_ideal_l/opt.penduduk_0_4_l) * 100).toFixed(2),
                jp04_perempuan: parseFloat((opt.murid_usia_ideal_p/opt.penduduk_0_4_p) * 100).toFixed(2),
                jp04_jumlah: parseFloat((opt.murid_usia_ideal_jumlah/opt.penduduk_0_4_jumlah) * 100).toFixed(2),

                jp36_laki_laki: parseFloat((opt.murid_usia_ideal_l/opt.penduduk_5_6_l) * 100).toFixed(2),
                jp36_perempuan: parseFloat((opt.murid_usia_ideal_p/opt.penduduk_5_6_p) * 100).toFixed(2),
                jp36_jumlah: parseFloat((opt.murid_usia_ideal_jumlah/opt.penduduk_5_6_jumlah) * 100).toFixed(2),

                jp712_laki_laki: parseFloat((opt.murid_usia_ideal_l/opt.penduduk_7_12_l) * 100).toFixed(2),
                jp712_perempuan: parseFloat((opt.murid_usia_ideal_p/opt.penduduk_7_12_p) * 100).toFixed(2),
                jp712_jumlah: parseFloat((opt.murid_usia_ideal_jumlah/opt.penduduk_7_12_jumlah) * 100).toFixed(2),

                jp1315_laki_laki: parseFloat((opt.murid_usia_ideal_l/opt.penduduk_13_15_l) * 100).toFixed(2),
                jp1315_perempuan: parseFloat((opt.murid_usia_ideal_p/opt.penduduk_13_15_p) * 100).toFixed(2),
                jp1315_jumlah: parseFloat((opt.murid_usia_ideal_jumlah/opt.penduduk_13_15_jumlah) * 100).toFixed(2),

                jp1618_laki_laki: parseFloat((opt.murid_usia_ideal_l/opt.penduduk_16_18_l) * 100).toFixed(2),
                jp1618_perempuan: parseFloat((opt.murid_usia_ideal_p/opt.penduduk_16_18_p) * 100).toFixed(2),
                jp1618_jumlah: parseFloat((opt.murid_usia_ideal_jumlah/opt.penduduk_16_18_jumlah) * 100).toFixed(2),
            }
        });

        const total = {
            murid_jumlah            : data_.sum('murid_jumlah'),
            murid_l                 : data_.sum('murid_l'),
            murid_p                 : data_.sum('murid_p'),
            murid_usia_ideal_jumlah : data_.sum('murid_usia_ideal_jumlah'),
            murid_usia_ideal_l      : data_.sum('murid_usia_ideal_l'),
            murid_usia_ideal_p      : data_.sum('murid_usia_ideal_p'),

            penduduk_0_4_jumlah     : data_.sum('penduduk_0_4_jumlah'),
            penduduk_0_4_l          : data_.sum('penduduk_0_4_l'),
            penduduk_0_4_p          : data_.sum('penduduk_0_4_p'),

            penduduk_5_6_jumlah     : data_.sum('penduduk_5_6_jumlah'),
            penduduk_5_6_l          : data_.sum('penduduk_5_6_l'),
            penduduk_5_6_p          : data_.sum('penduduk_5_6_p'),

            penduduk_7_12_jumlah    : data_.sum('penduduk_7_12_jumlah'),
            penduduk_7_12_l         : data_.sum('penduduk_7_12_l'),
            penduduk_7_12_p         : data_.sum('penduduk_7_12_p'),

            penduduk_13_15_jumlah   : data_.sum('penduduk_13_15_jumlah'),
            penduduk_13_15_l        : data_.sum('penduduk_13_15_l'),
            penduduk_13_15_p        : data_.sum('penduduk_13_15_p'),

            penduduk_16_18_jumlah   : data_.sum('penduduk_16_18_jumlah'),
            penduduk_16_18_l        : data_.sum('penduduk_16_18_l'),
            penduduk_16_18_p        : data_.sum('penduduk_16_18_p'),
        }
        
        const totalApm = {
            jp04_laki_laki: parseFloat((total.murid_usia_ideal_l/total.penduduk_0_4_l) * 100).toFixed(2),
            jp04_perempuan: parseFloat((total.murid_usia_ideal_p/total.penduduk_0_4_p) * 100).toFixed(2),
            jp04_jumlah: parseFloat((total.murid_usia_ideal_jumlah/total.penduduk_0_4_jumlah) * 100).toFixed(2),

            jp36_laki_laki: parseFloat((total.murid_usia_ideal_l/total.penduduk_5_6_l) * 100).toFixed(2),
            jp36_perempuan: parseFloat((total.murid_usia_ideal_p/total.penduduk_5_6_p) * 100).toFixed(2),
            jp36_jumlah: parseFloat((total.murid_usia_ideal_jumlah/total.penduduk_5_6_jumlah) * 100).toFixed(2),


            jp712_laki_laki: parseFloat((total.murid_usia_ideal_l/total.penduduk_7_12_l) * 100).toFixed(2),
            jp712_perempuan: parseFloat((total.murid_usia_ideal_p/total.penduduk_7_12_p) * 100).toFixed(2),
            jp712_jumlah: parseFloat((total.murid_usia_ideal_jumlah/total.penduduk_7_12_jumlah) * 100).toFixed(2),


            jp1315_laki_laki: parseFloat((total.murid_usia_ideal_l/total.penduduk_13_15_l) * 100).toFixed(2),
            jp1315_perempuan: parseFloat((total.murid_usia_ideal_p/total.penduduk_13_15_p) * 100).toFixed(2),
            jp1315_jumlah: parseFloat((total.murid_usia_ideal_jumlah/total.penduduk_13_15_jumlah) * 100).toFixed(2),


            jp1618_laki_laki: parseFloat((total.murid_usia_ideal_l/total.penduduk_16_18_l) * 100).toFixed(2),
            jp1618_perempuan: parseFloat((total.murid_usia_ideal_p/total.penduduk_16_18_p) * 100).toFixed(2),
            jp1618_jumlah: parseFloat((total.murid_usia_ideal_jumlah/total.penduduk_16_18_jumlah) * 100).toFixed(2),
        }

        return (
            <Page name="apm" hideBarsOnScroll>
                <Navbar sliding={false}>
                    <NavLeft>
                        <Link iconIos="f7:chevron_left" iconAurora="f7:chevron_left" iconMd="material:chevron_left" href="/">Beranda</Link>
                    </NavLeft>
                    <NavTitle sliding>
                        Data APK/APM{' '}&#45;{' '}{TitleFromCurrentUrl()}
                    </NavTitle>
                </Navbar>

                <div style={{ padding: "2% 3%" }}>
                    {current_url.includes('/apm/paud-ra') && (
                        <>
                            <ApmRaTable current_url={TitleFromCurrentUrl()} columns={ConfigPaudRa.Apm.columns} data={ConfigPaudRa.Apm.data} title={ConfigPaudRa.Apm.title} rows={dataApm} total={totalApm} />
                        </>
                    )}
                    {current_url.includes('/apm/sd-mi-paketa') && (
                        <>
                            <ApmRaTable current_url={TitleFromCurrentUrl()} columns={ConfigPaudSdMiPaketA.Apm.columns} data={ConfigPaudSdMiPaketA.Apm.data} title={ConfigPaudSdMiPaketA.Apm.title} rows={dataApm} total={totalApm} />
                        </>
                    )}
                    {current_url.includes('/apm/smp-mts-paketb') && (
                        <>
                            <ApmRaTable current_url={TitleFromCurrentUrl()} columns={ConfigPaudSmpMTsPaketB.Apm.columns} data={ConfigPaudSmpMTsPaketB.Apm.data} title={ConfigPaudSmpMTsPaketB.Apm.title} rows={dataApm} total={totalApm} />
                        </>
                    )}
                    {current_url.includes('/apm/paud-only') && (
                        <>
                            <ApmRaTable current_url={TitleFromCurrentUrl()} columns={ConfigPaud04.Apm.columns} data={ConfigPaud04.Apm.data} title={ConfigPaud04.Apm.title} rows={dataApm} total={totalApm} />
                        </>
                    )}
                    {current_url.includes('/apm/sma-smk-paketc') && (
                        <>
                            <ApmRaTable current_url={TitleFromCurrentUrl()} columns={ConfigSmaSmkPaketC.Apm.columns} data={ConfigSmaSmkPaketC.Apm.data} title={ConfigSmaSmkPaketC.Apm.title} rows={dataApm} total={totalApm} />
                        </>
                    )}
                    {current_url.includes('/apm/slb') && (
                        <>
                            <ApmRaTable current_url={TitleFromCurrentUrl()} columns={ConfigSLB.Apm.columns} data={ConfigSLB.Apm.data} title={ConfigSLB.Apm.title} rows={dataApm} total={totalApm} />
                        </>
                    )}
                </div>
            </Page>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateWindowDimension       : Actions.updateWindowDimension,
        getDataApkapm               : Actions.getDataApkapm,
        setLoading                  : Actions.setLoading,
    }, dispatch);
}

function mapStateToProps({ App }) {

    return {
        window_dimension            : App.window_dimension,
        data                        : App.data_apk_apm,
        loading                     : App.loading,
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ApkApmPaudRa));