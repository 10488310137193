import React, { Component } from 'react';
import { App, Panel, Views, View, Statusbar, Popup, Page, Navbar, Toolbar, NavRight, Link, Block, BlockTitle, LoginScreen, List, ListItem, AccordionContent } from 'framework7-react';
import LoginPage from '../pages/login';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../store/actions';
import cordovaApp from '../js/cordova-app';
import routes from '../js/routes';
import 'framework7-icons';

const semester = localStorage.getItem('semester_id_aplikasi');
const semester_opt = localStorage.getItem('semester_opt') ? JSON.parse(localStorage.getItem('semester_opt')) : [];

class app extends Component {
  state = {
    f7params: {
      id: 'io.timkayu.simdik',
      name: 'Simdik',
      theme: 'ios',
      data: function () {
        return {
          user: {
            firstName: 'Khalid',
            lastName: 'Saifuddin',
          },

        };
      },
      routes: routes,
      panel: {
        leftBreakpoint: 960,
      },
      serviceWorker: this.$device.cordova ? {} : {
        path: '/service-worker.js',
      },
      input: {
        scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
        scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
      },
      statusbar: {
        overlay: this.$device.cordova && this.$device.ios || 'auto',
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      },
    },
    tabBar: {
      beranda: true,
      kategori: false,
      cari: false,
      materi: false,
      profil: false
    },
    username: '',
    password: '',
  };
  onClickLinkTab = (menu) => {
    for (var property in this.props.tabBar) {
      this.props.tabBar[property] = false;
    }

    this.props.tabBar[menu] = true;
    this.props.setTabActive(this.props.tabBar);
  }

  onClickMenu() {
    // code...
  }

  componentDidMount = () => {
    this.getSemester();
  }

  getSemester = () => {
    this.props.getSemester().then(() => {
      localStorage.setItem('semester_opt', JSON.stringify(this.props.semester));
      localStorage.setItem('semester_id_aplikasi', this.props.semester_aktif.semester_id);
    });
  }

  gantiSemester = (b) => {
    localStorage.setItem('semester_id_aplikasi', b.target.value);
  }

  keluar = () => {
    localStorage.setItem('sudah_login', '0');
    localStorage.setItem('user', '');
    localStorage.setItem('token', '');

    if (localStorage.getItem('device') === 'android') {
      window.location.reload(true);
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <App params={this.state.f7params} hideToolbarOnScroll>
        <Statusbar></Statusbar>
        {localStorage.getItem('harus_login') === "N" && parseInt(localStorage.getItem('sudah_login')) !== 1 &&
          <Panel left cover className="panelMenuUtama">
            <View>
              <Page>
                {localStorage.getItem('logo_aplikasi_2') &&
                  <Navbar title={(<img src="/static/icons/logo_putih.png" style={{ width: '135px' }} />)} />
                }
                {!localStorage.getItem('logo_aplikasi_2') &&
                  <Navbar title={localStorage.getItem('judul_aplikasi')} />
                }
                <List style={{ marginBottom: '0px', marginTop: '0px' }}>
                  <ListItem title="Semester" smartSelect>
                    <select onChange={this.gantiSemester} name="semester_id" value={semester} defaultValue={semester}>
                      <option value="">-Semester-</option>
                      {
                        semester_opt.map((smt, key) =>
                          <option key={key} value={smt.semester_id}>{smt.nama}</option>
                        )
                      }
                    </select>
                  </ListItem>
                </List>
                <BlockTitle>Menu</BlockTitle>
                <List noHairlines>
                  <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/" view=".view-main" panelClose panel-close title="Beranda">
                    <i slot="media" className="f7-icons">house</i>
                  </ListItem>
                  {localStorage.getItem('kode_aplikasi') !== 'SPM' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/DataPokokSekolah/" view=".view-main" panelClose panel-close title="Cari Sekolah">
                      <i slot="media" className="f7-icons">search</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link={"/RaporDapodikRingkasan/" + localStorage.getItem('id_level_wilayah_aplikasi') + "/" + localStorage.getItem('kode_wilayah_aplikasi')} view=".view-main" panelClose panel-close title={(localStorage.getItem('kode_aplikasi') === 'RAPORDAPODIK' ? 'Rapor Dapodik' : 'Rapor Kualitas Data')}>
                      <i slot="media" className="f7-icons">book</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') !== 'SPM' && localStorage.getItem('sudah_login') === '1' && parseInt(JSON.parse(localStorage.getItem('user')).verified) === 1 && localStorage.getItem('jenjang_aplikasi') === '13' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link={"/ValidasiData/" + (JSON.parse(localStorage.getItem('user')).kode_wilayah ? JSON.parse(localStorage.getItem('user')).kode_wilayah : localStorage.getItem('id_level_wilayah_aplikasi')) + "/" + localStorage.getItem('kode_wilayah_aplikasi')} view=".view-main" panelClose panel-close title={'Validasi Data'}>
                      <i slot="media" className="f7-icons">checkmark_shield_fill</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/RekapData/" view=".view-main" panelClose panel-close title="Rekap Data">
                      <i slot="media" className="f7-icons">folder</i>
                    </ListItem>
                  }
                  {/* {
                    localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <ListItem view=".view-main" accordionItem title="APK/APM" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                      <i slot="media" className="f7-icons">table</i>
                      <AccordionContent>
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/paud-only" view=".view-main" panelClose panel-close title="PAUD">
                            <i slot="media" className="f7-icons">table</i>
                          </ListItem>
                        }

                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/paud-ra" view=".view-main" panelClose panel-close title="TK/RA">
                            <i slot="media" className="f7-icons">table</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/sd-mi-paketa" view=".view-main" panelClose panel-close title="SD/MI/Paket A">
                            <i slot="media" className="f7-icons">table</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/smp-mts-paketb" view=".view-main" panelClose panel-close title="SMP/MTs/Paket B">
                            <i slot="media" className="f7-icons">table</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/sma-smk-paketc" view=".view-main" panelClose panel-close title="SMA/SMK/Paket C">
                            <i slot="media" className="f7-icons">table</i>
                          </ListItem>
                        }
                      </AccordionContent>
                    </ListItem>
                  } */}
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/Peta" view=".view-main" panelClose panel-close title="Peta">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'RAPORDAPODIK' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/Peta" view=".view-main" panelClose panel-close title="Peta">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/CustomQuery/" view=".view-main" panelClose panel-close title="Custom Query">
                      <i slot="media" className="f7-icons">quote_bubble</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('kode_wilayah_aplikasi') === '020800' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formIndexPendidikan/" view=".view-main" panelClose panel-close title="Indikator Pendidikan">
                      <i slot="media" className="f7-icons">quote_bubble</i>
                    </ListItem>
                  }
                </List>
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Pendataan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formATS" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formAnakMiskin" view=".view-main" panelClose panel-close title="PD Miskin">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Rekapitulasi</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMUsiaSekolah" view=".view-main" panelClose panel-close title="Usia Sekolah (1.3a)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMLuarWilayah" view=".view-main" panelClose panel-close title="Peserta Didik (1.3b)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMSatuanPendidikan" view=".view-main" panelClose panel-close title="Satuan Pendidikan  (1.3c)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMPendidik" view=".view-main" panelClose panel-close title="Pendidik (1.3d)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMKepsek" view=".view-main" panelClose panel-close title="Kepala Sekolah (1.3e)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMTenagaPenunjang" view=".view-main" panelClose panel-close title="Tenaga Penunjang (1.3f)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Perhitungan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel21" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah (2.1)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formPenerimaSPM" view=".view-main" panelClose panel-close title="Penerima SPM (2.2)">
                      <i slot="media" className="f7-icons">layers_fill</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Perencanaan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel31" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah (3.1)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formRencanaPemenuhanSPM" view=".view-main" panelClose panel-close title="Penerima SPM (3.2)">
                      <i slot="media" className="f7-icons">lightbulb_fill</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Pelaksanaan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel41" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah (4.1)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel42" view=".view-main" panelClose panel-close title="Penerima SPM (4.2)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Pelaporan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel43" view=".view-main" panelClose panel-close title="Capaian SPM (4.3)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('sudah_login') === '0' &&
                  <List>
                    <ListItem link="/login" view=".view-main" panelClose panel-close title="Login/Masuk">
                      <i slot="media" className="f7-icons">square_arrow_right</i>
                    </ListItem>
                  </List>
                }
                {localStorage.getItem('sudah_login') === '1' &&
                  <>
                    <BlockTitle>Pengaturan</BlockTitle>
                    <List>
                      <ListItem style={{ background: '#E3F2FD' }} link="/ProfilPengguna" view=".view-main" panelClose panel-close title="Profil Pengguna">
                        <i slot="media" className="f7-icons">person_crop_square_fill</i>
                      </ListItem>

                      {localStorage.getItem('user') && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 && localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                        <ListItem style={{ background: '#E3F2FD' }} link="/Pengaturan" view=".view-main" panelClose panel-close title="Pengaturan">
                          <i slot="media" className="f7-icons">gear_alt</i>
                        </ListItem>
                      }
                      <ListItem onClick={this.keluar} panelClose panel-close title="Keluar" style={{ background: '#ef5350', cursor: 'pointer', color: 'white' }}>
                        <i slot="media" className="f7-icons">square_arrow_left</i>
                      </ListItem>
                    </List>
                  </>
                }
              </Page>
            </View>
          </Panel>
        }

        {parseInt(localStorage.getItem('sudah_login')) === 1 &&
          <Panel left cover className="panelMenuUtama">
            <View>
              <Page>
                {localStorage.getItem('logo_aplikasi_2') &&
                  <Navbar title={(<img src="/static/icons/logo_putih.png" style={{ width: '135px' }} />)} />
                }
                {!localStorage.getItem('logo_aplikasi_2') &&
                  <Navbar title={localStorage.getItem('judul_aplikasi')} />
                }
                <List style={{ marginBottom: '0px', marginTop: '0px' }}>
                  <ListItem
                    title="Semester"
                    smartSelect
                  >
                    <select onChange={this.gantiSemester} name="semester_id" value={semester} defaultValue={semester}>
                      <option value="">-Semester-</option>
                      {
                        semester_opt.map((smt, key) =>
                          <option key={key} value={smt.semester_id}>{smt.nama}</option>
                        )
                      }
                    </select>
                  </ListItem>
                </List>
                <BlockTitle>Menu</BlockTitle>
                <List noHairlines>
                  <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/" view=".view-main" panelClose panel-close title="Beranda">
                    <i slot="media" className="f7-icons">house</i>
                  </ListItem>
                  {localStorage.getItem('kode_aplikasi') !== 'SPM' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/DataPokokSekolah/" view=".view-main" panelClose panel-close title="Cari Sekolah">
                      <i slot="media" className="f7-icons">search</i>
                    </ListItem>
                  }
                  {/* {localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                <ListItem noHairlines  style={{background:'#E3F2FD', fontSize:'14px'}} link={"/RaporDapodikRingkasan/"+localStorage.getItem('id_level_wilayah_aplikasi')+"/"+localStorage.getItem('kode_wilayah_aplikasi')} view=".view-main" panelClose panel-close title={(localStorage.getItem('kode_aplikasi') === 'RAPORDAPODIK' ? 'Rapor Dapodik' : 'Rapor Kualitas Data')}>
                  <i slot="media" className="f7-icons">book</i>
                </ListItem>
                } */}
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/RekapData/" view=".view-main" panelClose panel-close title="Rekap Data">
                      <i slot="media" className="f7-icons">folder</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/Peta" view=".view-main" panelClose panel-close title="Peta">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'RAPORDAPODIK' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/Peta" view=".view-main" panelClose panel-close title="Peta">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem view=".view-main" accordionItem title="Custom Query" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                      <i slot="media" className="f7-icons">cube_box</i>
                      <AccordionContent>
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/CustomQuery/" view=".view-main" panelClose panel-close title="Query Sekolah">
                            <i slot="media" className="f7-icons">house_fill</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/CustomQueryPesertaDidik/" view=".view-main" panelClose panel-close title="Query PD">
                            <i slot="media" className="f7-icons">person_3_fill</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/CustomQueryGTK/" view=".view-main" panelClose panel-close title="Query GTK">
                            <i slot="media" className="f7-icons">person_alt</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/CustomQuerySarpras/" view=".view-main" panelClose panel-close title="Query Sarpras">
                            <i slot="media" className="f7-icons">building_2_fill</i>
                          </ListItem>
                        }
                      </AccordionContent>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' && ['026100', '040000'].includes(localStorage.getItem('kode_wilayah_aplikasi')) &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/NPD" view=".view-main" panelClose panel-close title="NPD">
                      <i slot="media" className="f7-icons">doc_chart</i>
                    </ListItem>
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 &&
                    <ListItem view=".view-main" accordionItem title="Data Individu" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                      <i slot="media" className="f7-icons">person_2</i>
                      <AccordionContent>
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/data-individu/ptk" view=".view-main" panelClose panel-close title="PTK">
                            <i slot="media" className="f7-icons">person_2</i>
                          </ListItem>
                        }
                        {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                          <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/data-individu/pd" view=".view-main" panelClose panel-close title="PD">
                            <i slot="media" className="f7-icons">person_2</i>
                          </ListItem>
                        }
                      </AccordionContent>
                    </ListItem>
                  }

                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/peserta-didik-bersekolah-di-luar-kota-palembang" view=".view-main" panelClose panel-close title="PD Bersekolah di luar Kota Palembang">
                      <i slot="media" className="f7-icons">person</i>
                    </ListItem>
                  }

                  {/* {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem view=".view-main" accordionItem title="APK/APM" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                      <i slot="media" className="f7-icons">doc_chart</i>
                      <AccordionContent>
                        {
                          localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 &&
                          <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="Input Data" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                            <i slot="media" className="f7-icons">
                              <img src="/static/icons/input-data.png" style={{ width: '28px' }} />
                            </i>
                            <AccordionContent style={{ paddingLeft: "20px" }}>
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/input-data/apk" view=".view-main" panelClose panel-close title="APK">
                                  <i slot="media" className="f7-icons">chart_bar</i>
                                </ListItem>}
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/input-data/apm" view=".view-main" panelClose panel-close title="APM">
                                  <i slot="media" className="f7-icons">chart_bar</i>
                                </ListItem>}
                            </AccordionContent>

                          </ListItem>
                        }
                        {
                          localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                          <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="Data APK/APM" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                            <i slot="media" className="f7-icons">chart_bar</i>

                            <AccordionContent style={{ paddingLeft: "20px" }}>
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/paud-only" view=".view-main" panelClose panel-close title="PAUD">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/paud-ra" view=".view-main" panelClose panel-close title="TK/RA">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/sd-mi-paketa" view=".view-main" panelClose panel-close title="SD/MI/Paket A">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/smp-mts-paketb" view=".view-main" panelClose panel-close title="SMP/MTs/Paket B">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/sma-smk-paketc" view=".view-main" panelClose panel-close title="SMA/SMK/Paket C">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk-apm/slb" view=".view-main" panelClose panel-close title="SLB">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                            </AccordionContent>

                          </ListItem>
                        }
                        {
                          localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                          <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="Data APK" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                            <i slot="media" className="f7-icons">chart_bar</i>

                            <AccordionContent style={{ paddingLeft: "20px" }}>
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/paud-only" view=".view-main" panelClose panel-close title="PAUD">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/paud-ra" view=".view-main" panelClose panel-close title="TK/RA">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/sd-mi-paketa" view=".view-main" panelClose panel-close title="SD/MI/Paket A">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/smp-mts-paketb" view=".view-main" panelClose panel-close title="SMP/MTs/Paket B">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/sma-smk-paketc" view=".view-main" panelClose panel-close title="SMA/SMK/Paket C">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/slb" view=".view-main" panelClose panel-close title="SLB">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                            </AccordionContent>

                          </ListItem>
                        }
                        {
                          localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                          <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="Data APM" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                            <i slot="media" className="f7-icons">chart_bar</i>

                            <AccordionContent style={{ paddingLeft: "20px" }}>
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/paud-only" view=".view-main" panelClose panel-close title="PAUD">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/paud-ra" view=".view-main" panelClose panel-close title="TK/RA">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/sd-mi-paketa" view=".view-main" panelClose panel-close title="SD/MI/Paket A">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/smp-mts-paketb" view=".view-main" panelClose panel-close title="SMP/MTs/Paket B">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/sma-smk-paketc" view=".view-main" panelClose panel-close title="SMA/SMK/Paket C">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                              {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                                <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/slb" view=".view-main" panelClose panel-close title="SLB">
                                  <i slot="media" className="f7-icons">
                                    <img src="/static/icons/school.png" style={{ width: '28px' }} />
                                  </i>
                                </ListItem>
                              }
                            </AccordionContent>

                          </ListItem>
                        }
                      </AccordionContent>
                    </ListItem>
                  } */}
                  {/* {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                    <ListItem view=".view-main" accordionItem title="Profil Pendidikan" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                      <i slot="media" className="f7-icons">
                        <img src="/static/icons/profil-pendidikan.png" alt='profil-pendidikan' style={{ width: '28px' }} />
                      </i>
                      {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="1.PAUD" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>

                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/paud-only" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/paud-only" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-guru" view=".view-main" panelClose panel-close title="d. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="e. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="f. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="g. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-pd-by-jk" view=".view-main" panelClose panel-close title="h. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paud-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="i. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="2.TK" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>
                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/paud-ra" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/paud-ra" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-sp-terakreditasi" view=".view-main" panelClose panel-close title="d. SP Terakreditasi">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-guru" view=".view-main" panelClose panel-close title="e. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="f. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="g. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="h. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-pd-by-jk" view=".view-main" panelClose panel-close title="i. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="j. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-sarpras-by-ruang-kelas" view=".view-main" panelClose panel-close title="k. Sarpras by Ruang Kelas">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-sarpras-by-perpustakaan" view=".view-main" panelClose panel-close title="l. Sarpras by Perpustakaan">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/tk-sarpras-by-lab-komputer" view=".view-main" panelClose panel-close title="m. Sarpras by Lab Komputer">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="3.SD" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>

                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/sd-mi-paketa" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/sd-mi-paketa" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-sp-terakreditasi" view=".view-main" panelClose panel-close title="d. SP Terakreditasi">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-guru" view=".view-main" panelClose panel-close title="e. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="f. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="g. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="h. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-pd-by-jk" view=".view-main" panelClose panel-close title="i. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-pd-by=penerima-pip" view=".view-main" panelClose panel-close title="j. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-sarpras-by-ruang-kelas" view=".view-main" panelClose panel-close title="k. Sarpras by Ruang Kelas">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-sarpras-by-perpustakaan" view=".view-main" panelClose panel-close title="l. Sarpras by Perpustakaan">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sd-sarpras-by-lab-komputer" view=".view-main" panelClose panel-close title="m. Sarpras by Lab Komputer">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="4.SMP" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>

                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/smp-mts-paketb" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/smp-mts-paketb" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-sp-terakreditasi" view=".view-main" panelClose panel-close title="d. SP Terakreditasi">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-guru" view=".view-main" panelClose panel-close title="e. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="f. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="g. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="h. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-pd-by-jk" view=".view-main" panelClose panel-close title="i. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="j. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-sarpras-by-ruang-kelas" view=".view-main" panelClose panel-close title="k. Sarpras by Ruang Kelas">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-sarpras-by-perpustakaan" view=".view-main" panelClose panel-close title="l. Sarpras by Perpustakaan">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smp-sarpras-by-lab-komputer" view=".view-main" panelClose panel-close title="m. Sarpras by Lab Komputer">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>

                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="5.SMA" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>
                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/sma-smk-paketc" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/sma-smk-paketc" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-sp-terakreditasi" view=".view-main" panelClose panel-close title="d. SP Terakreditasi">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-guru" view=".view-main" panelClose panel-close title="e. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="f. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="g. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="h. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-pd-by-jk" view=".view-main" panelClose panel-close title="i. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="j. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-sarpras-by-ruang-kelas" view=".view-main" panelClose panel-close title="k. Sarpras by Ruang Kelas">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-sarpras-by-perpustakaan" view=".view-main" panelClose panel-close title="l. Sarpras by Perpustakaan">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/sma-sarpras-by-lab-komputer" view=".view-main" panelClose panel-close title="m. Sarpras by Lab Komputer">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>

                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="6.SMK" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>
                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/sma-smk-paketc" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/sma-smk-paketc" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-sp-terakreditasi" view=".view-main" panelClose panel-close title="d. SP Terakreditasi">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-guru" view=".view-main" panelClose panel-close title="e. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="f. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="g. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="h. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-pd-by-jk" view=".view-main" panelClose panel-close title="i. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="j. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-sarpras-by-ruang-kelas" view=".view-main" panelClose panel-close title="k. Sarpras by Ruang Kelas">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-sarpras-by-perpustakaan" view=".view-main" panelClose panel-close title="l. Sarpras by Perpustakaan">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/smk-sarpras-by-lab-komputer" view=".view-main" panelClose panel-close title="m. Sarpras by Lab Komputer">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>

                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="7.SLB" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>
                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apk/slb" view=".view-main" panelClose panel-close title="a. APK">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/apm/slb" view=".view-main" panelClose panel-close title="b. APM">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-satuan-pendidikan" view=".view-main" panelClose panel-close title="c. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-sp-terakreditasi" view=".view-main" panelClose panel-close title="d. SP Terakreditasi">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-guru" view=".view-main" panelClose panel-close title="e. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-guru-by-gelar-akademik" view=".view-main" panelClose panel-close title="f. Guru by Gelar Akademik">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-guru-by-sertifikasi" view=".view-main" panelClose panel-close title="g. Guru by Sertifikasi">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-guru-by-status-kepegawaian" view=".view-main" panelClose panel-close title="h. Guru by Status Kepegawaian">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-pd-by-jk" view=".view-main" panelClose panel-close title="i. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="j. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-sarpras-by-ruang-kelas" view=".view-main" panelClose panel-close title="k. Sarpras by Ruang Kelas">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-sarpras-by-perpustakaan" view=".view-main" panelClose panel-close title="l. Sarpras by Perpustakaan">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/slb-sarpras-by-lab-komputer" view=".view-main" panelClose panel-close title="m. Sarpras by Lab Komputer">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/sarpras.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="8.Paket A" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>

                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-a-satuan-pendidikan" view=".view-main" panelClose panel-close title="a. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-a-guru" view=".view-main" panelClose panel-close title="b. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-a-pd-by-jk" view=".view-main" panelClose panel-close title="c. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-a-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="d. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="9.Paket B" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>

                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-b-satuan-pendidikan" view=".view-main" panelClose panel-close title="a. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-b-guru" view=".view-main" panelClose panel-close title="b. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-b-pd-by-jk" view=".view-main" panelClose panel-close title="c. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-b-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="d. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                      {
                        localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') === '1' &&
                        <ListItem className="itemSub" noHairlines view=".view-main" accordionItem title="10.Paket C" style={{ background: '#E3F2FD', fontSize: '14px' }}>
                          <i slot="media" className="f7-icons">
                            <img src="/static/icons/school.png" style={{ width: '28px' }} />
                          </i>

                          <AccordionContent style={{ paddingLeft: "20px" }}>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-c-satuan-pendidikan" view=".view-main" panelClose panel-close title="a. Satuan Pendidikan">
                              <i slot="media" className="f7-icons">chart_bar</i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-c-guru" view=".view-main" panelClose panel-close title="b. Guru">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/teacher.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-c-pd-by-jk" view=".view-main" panelClose panel-close title="c. PD by JK">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                            <ListItem className="itemSub" noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/profil-pendidikan/paket-c-pd-by-penerima-pip" view=".view-main" panelClose panel-close title="d. PD by Penerima PIP">
                              <i slot="media" className="f7-icons">
                                <img src="/static/icons/student.png" style={{ width: '28px' }} />
                              </i>
                            </ListItem>
                          </AccordionContent>

                        </ListItem>
                      }
                    </ListItem>
                  } */}
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('kode_wilayah_aplikasi') === '020800' &&
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formIndexPendidikan/" view=".view-main" panelClose panel-close title="Index Pendidikan">
                      <i slot="media" className="f7-icons">quote_bubble</i>
                    </ListItem>
                  }
                </List>
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Pendataan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formATS" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formAnakMiskin" view=".view-main" panelClose panel-close title="PD Miskin">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Rekapitulasi</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMUsiaSekolah" view=".view-main" panelClose panel-close title="Usia Sekolah (1.3a)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMLuarWilayah" view=".view-main" panelClose panel-close title="Peserta Didik (1.3b)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMSatuanPendidikan" view=".view-main" panelClose panel-close title="Satuan Pendidikan  (1.3c)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMPendidik" view=".view-main" panelClose panel-close title="Pendidik (1.3d)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMKepsek" view=".view-main" panelClose panel-close title="Kepala Sekolah (1.3e)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/SPMTenagaPenunjang" view=".view-main" panelClose panel-close title="Tenaga Penunjang (1.3f)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Perhitungan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel21" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah (2.1)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formPenerimaSPM" view=".view-main" panelClose panel-close title="Penerima SPM (2.2)">
                      <i slot="media" className="f7-icons">layers_fill</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Perencanaan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel31" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah (3.1)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/formRencanaPemenuhanSPM" view=".view-main" panelClose panel-close title="Penerima SPM (3.2)">
                      <i slot="media" className="f7-icons">lightbulb_fill</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Pelaksanaan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel41" view=".view-main" panelClose panel-close title="Anak Tidak Sekolah (4.1)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel42" view=".view-main" panelClose panel-close title="Penerima SPM (4.2)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <BlockTitle>Pelaporan</BlockTitle>
                }
                {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                  <List>
                    <ListItem noHairlines style={{ background: '#E3F2FD', fontSize: '14px' }} link="/tabel43" view=".view-main" panelClose panel-close title="Capaian SPM (4.3)">
                      <i slot="media" className="f7-icons">map</i>
                    </ListItem>
                  </List>
                }

                {localStorage.getItem('sudah_login') === '0' &&
                  <List>
                    <ListItem link="/login" view=".view-main" panelClose panel-close title="Login/Masuk">
                      <i slot="media" className="f7-icons">square_arrow_right</i>
                    </ListItem>
                  </List>
                }
                {localStorage.getItem('sudah_login') === '1' &&
                  <>
                    <BlockTitle>Pengaturan</BlockTitle>
                    <List>
                      <ListItem style={{ background: '#E3F2FD' }} link="/ProfilPengguna" view=".view-main" panelClose panel-close title="Profil Pengguna">
                        <i slot="media" className="f7-icons">person_crop_square_fill</i>
                      </ListItem>

                      {localStorage.getItem('user') && parseInt(JSON.parse(localStorage.getItem('user')).peran_id) === 1 && localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                        <ListItem style={{ background: '#E3F2FD' }} link="/Pengaturan" view=".view-main" panelClose panel-close title="Pengaturan">
                          <i slot="media" className="f7-icons">gear_alt</i>
                        </ListItem>
                      }
                      <ListItem onClick={this.keluar} panelClose panel-close title="Keluar" style={{ background: '#ef5350', cursor: 'pointer', color: 'white' }}>
                        <i slot="media" className="f7-icons">square_arrow_left</i>
                      </ListItem>
                    </List>
                  </>
                }
              </Page>
            </View>
          </Panel>
        }

        <Panel right cover themeDark style={{ width: '320px' }}>
          <View>
            <Page>
              <Navbar title={this.props.judul_panel_kanan} />
              <Block style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                {this.props.isi_panel_kanan}
              </Block>
            </Page>
          </View>
        </Panel>

        <Views tabs className="safe-areas" hideToolbarOnScroll>
          {localStorage.getItem('harus_login') === 'N' && parseInt(localStorage.getItem('sudah_login')) !== 1 &&
            <Toolbar labels bottom className="mobileTab" hideToolbarOnScroll>
              <Link
                href="/"
                tabLinkActive={this.props.tabBar.beranda}
                iconIos="f7:house"
                iconAurora="f7:house"
                iconMd="f7:house"
                text="Beranda"
                style={{ fontSize: '12px' }}
              />
              <Link
                href="/DataPokokSekolah/"
                tabLinkActive={this.props.tabBar.DataPokokSekolah}
                iconIos="f7:search_fil"
                iconMd="f7:search_fil"
                text="Cari"
                style={{ fontSize: '12px' }}
              />
              {localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                <>
                  <Link
                    href={"/RaporDapodikRingkasan/" + localStorage.getItem('id_level_wilayah_aplikasi') + "/" + localStorage.getItem('kode_wilayah_aplikasi')}
                    tabLinkActive={this.props.tabBar.RaporDapodik}
                    iconIos="f7:book"
                    iconMd="f7:book"
                    text="Rapor"
                    style={{ fontSize: '12px' }}
                  />
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') !== '0' && parseInt(JSON.parse(localStorage.getItem('user')).verified) === 1 && localStorage.getItem('jenjang_aplikasi') === 13 &&
                    <Link
                      href={("/ValidasiData/" + (JSON.parse(localStorage.getItem('user')).kode_wilayah ? (JSON.parse(localStorage.getItem('user')).kode_wilayah.substring(0, 2) === '00' ? '0' : (JSON.parse(localStorage.getItem('user')).kode_wilayah.substring(2, 4) === '00' ? '1' : (JSON.parse(localStorage.getItem('user')).kode_wilayah.substring(4, 6) === '00' ? '3' : '0'))) : localStorage.getItem('id_level_wilayah_aplikasi')) + "/" + (JSON.parse(localStorage.getItem('user')).kode_wilayah ? JSON.parse(localStorage.getItem('user')).kode_wilayah : localStorage.getItem('kode_wilayah_aplikasi')))}
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:checkmark_shield_fill"
                      iconMd="f7:checkmark_shield_fill"
                      text="Validasi"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <Link
                      href="/RekapData/"
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:folder"
                      iconMd="f7:folder"
                      text="Rekap"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('kode_wilayah_aplikasi') === '000000' &&
                    <Link
                      href="/CustomQuery/"
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:quote_bubble"
                      iconMd="f7:quote_bubble"
                      text="Custom Query"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('kode_wilayah_aplikasi') === '020800' && parseInt(localStorage.getItem('sudah_login')) === 1 &&
                    <Link
                      href="/formIndexPendidikan/"
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:quote_bubble"
                      iconMd="f7:quote_bubble"
                      text="Index Pendidikan"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('sudah_login') === '0' &&
                    <Link
                      href="/login"
                      tabLinkActive={this.props.tabBar.beranda}
                      iconIos="f7:square_arrow_right"
                      iconAurora="f7:square_arrow_right"
                      iconMd="material:square_arrow_right"
                      text="Login"
                      style={{ fontSize: '12px' }}
                    />
                  }
                </>
              }
              {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                <Link
                  href="/formPenerimaSPM"
                  tabLinkActive={this.props.tabBar.beranda}
                  iconIos="f7:layers_fill"
                  iconAurora="f7:layers_fill"
                  iconMd="material:layers_fill"
                  text="Hitung Penerima"
                  style={{ fontSize: '12px' }}
                />
              }
              {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                <Link
                  href="/formRencanaPemenuhanSPM"
                  tabLinkActive={this.props.tabBar.beranda}
                  iconIos="f7:lightbulb_fill"
                  iconAurora="f7:lightbulb_fill"
                  iconMd="material:lightbulb_fill"
                  text="Pemenuhan"
                  style={{ fontSize: '12px' }}
                />
              }
              <Link
                iconIos="f7:ellipsis"
                iconAurora="f7:ellipsis"
                iconMd="material:ellipsis"
                text="More"
                panelOpen="left"
                style={{ fontSize: '12px' }}
              />
            </Toolbar>
          }

          {parseInt(localStorage.getItem('sudah_login')) === 1 &&
            <Toolbar labels bottom className="mobileTab" hideToolbarOnScroll>
              <Link
                href="/"
                tabLinkActive={this.props.tabBar.beranda}
                iconIos="f7:house"
                iconAurora="f7:house"
                iconMd="f7:house"
                text="Beranda"
                style={{ fontSize: '12px' }}
              />
              <Link
                href="/DataPokokSekolah/"
                tabLinkActive={this.props.tabBar.DataPokokSekolah}
                iconIos="f7:search_fil"
                iconMd="f7:search_fil"
                text="Cari"
                style={{ fontSize: '12px' }}
              />
              {localStorage.getItem('kode_aplikasi') !== 'SPM' &&
                <>
                  <Link
                    href={"/RaporDapodikRingkasan/" + localStorage.getItem('id_level_wilayah_aplikasi') + "/" + localStorage.getItem('kode_wilayah_aplikasi')}
                    tabLinkActive={this.props.tabBar.RaporDapodik}
                    iconIos="f7:book"
                    iconMd="f7:book"
                    text="Rapor"
                    style={{ fontSize: '12px' }}
                  />
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('sudah_login') !== '0' && parseInt(JSON.parse(localStorage.getItem('user')).verified) === 1 && localStorage.getItem('jenjang_aplikasi') === 13 &&
                    <Link
                      href={("/ValidasiData/" + (JSON.parse(localStorage.getItem('user')).kode_wilayah ? (JSON.parse(localStorage.getItem('user')).kode_wilayah.substring(0, 2) === '00' ? '0' : (JSON.parse(localStorage.getItem('user')).kode_wilayah.substring(2, 4) === '00' ? '1' : (JSON.parse(localStorage.getItem('user')).kode_wilayah.substring(4, 6) === '00' ? '3' : '0'))) : localStorage.getItem('id_level_wilayah_aplikasi')) + "/" + (JSON.parse(localStorage.getItem('user')).kode_wilayah ? JSON.parse(localStorage.getItem('user')).kode_wilayah : localStorage.getItem('kode_wilayah_aplikasi')))}
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:checkmark_shield_fill"
                      iconMd="f7:checkmark_shield_fill"
                      text="Validasi"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' &&
                    <Link
                      href="/RekapData/"
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:folder"
                      iconMd="f7:folder"
                      text="Rekap"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('kode_wilayah_aplikasi') === '000000' &&
                    <Link
                      href="/CustomQuery/"
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:quote_bubble"
                      iconMd="f7:quote_bubble"
                      text="Custom Query"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('kode_aplikasi') === 'SIMDIK' && localStorage.getItem('kode_wilayah_aplikasi') === '020800' && parseInt(localStorage.getItem('sudah_login')) === 1 &&
                    <Link
                      href="/formIndexPendidikan/"
                      tabLinkActive={this.props.tabBar.RaporData}
                      iconIos="f7:quote_bubble"
                      iconMd="f7:quote_bubble"
                      text="Indikator Pendidikan"
                      style={{ fontSize: '12px' }}
                    />
                  }
                  {localStorage.getItem('sudah_login') === '0' &&
                    <Link
                      href="/login"
                      tabLinkActive={this.props.tabBar.beranda}
                      iconIos="f7:square_arrow_right"
                      iconAurora="f7:square_arrow_right"
                      iconMd="material:square_arrow_right"
                      text="Login"
                      style={{ fontSize: '12px' }}
                    />
                  }
                </>
              }
              {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                <Link
                  href="/formPenerimaSPM"
                  tabLinkActive={this.props.tabBar.beranda}
                  iconIos="f7:layers_fill"
                  iconAurora="f7:layers_fill"
                  iconMd="material:layers_fill"
                  text="Hitung Penerima"
                  style={{ fontSize: '12px' }}
                />
              }
              {localStorage.getItem('kode_aplikasi') === 'SPM' &&
                <Link
                  href="/formRencanaPemenuhanSPM"
                  tabLinkActive={this.props.tabBar.beranda}
                  iconIos="f7:lightbulb_fill"
                  iconAurora="f7:lightbulb_fill"
                  iconMd="material:lightbulb_fill"
                  text="Pemenuhan"
                  style={{ fontSize: '12px' }}
                />
              }
              <Link
                iconIos="f7:ellipsis"
                iconAurora="f7:ellipsis"
                iconMd="material:ellipsis"
                text="More"
                panelOpen="left"
                style={{ fontSize: '12px' }}
              />
            </Toolbar>
          }
          <View id="view-beranda" main tab tabActive url="/" pushState={localStorage.getItem('device') === 'web' ? true : false} />
        </Views>

        <Popup id="my-popup">
          <View>
            <Page>
              <Navbar title="Popup">
                <NavRight>
                  <Link popupClose>Close</Link>
                </NavRight>
              </Navbar>
              <Block>
                <p>Popup content goes here.</p>
              </Block>
            </Page>
          </View>
        </Popup>

        <LoginScreen id="my-login-screen">
          <LoginPage />
        </LoginScreen>
      </App>
    )
  }
  alertLoginData() {
    this.$f7.dialog.alert('Username: ' + this.state.username + '<br>Password: ' + this.state.password);
  }
  componentDidMount() {
    this.$f7ready((f7) => {
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
    });
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateWindowDimension: Actions.updateWindowDimension,
    setLoading: Actions.setLoading,
    setTabActive: Actions.setTabActive,
    getSemester: Actions.getSemester,
  }, dispatch);
}

function mapStateToProps({ App }) {
  return {
    window_dimension: App.window_dimension,
    loading: App.loading,
    tabBar: App.tabBar,
    judul_panel_kanan: App.judul_panel_kanan,
    isi_panel_kanan: App.isi_panel_kanan,
    semester: App.semester,
    semester_aktif: App.semester_aktif
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(app));