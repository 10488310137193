const headerExcel = (labelExcel) => {
  return `<table>
      <tbody>
        <tr>
          <td colSpan="10" style="text-align: center;">
            <b>${labelExcel}</b>
          </td>
        </tr>
        <tr>
          <td colSpan="10">
          </td>
        </tr>
      </tbody>
    </table>`;
};

const generateContent = (row, gtk, idx, module) => {
  // row.insertCell().innerHTML = idx + 101;
  // row.insertCell().innerHTML = gtk.nama;
  // row.insertCell().innerHTML = gtk.nip || '-';
  // row.insertCell().innerHTML = gtk.jenis_guru || '-';
  // row.insertCell().innerHTML = gtk.nama_sekolah || '-';
  // row.insertCell().innerHTML = gtk.jumlah_kehadiran || '-';
  // row.insertCell().innerHTML = gtk.jumlah_dinas_luar || '-';
  // row.insertCell().innerHTML = gtk.jumlah_alpa || '-';
  // row.insertCell().innerHTML = gtk.jumlah_sakit || '-';
  // row.insertCell().innerHTML = gtk.jumlah_cuti || '-';
  // row.insertCell().innerHTML = gtk.jumlah_alpa + gtk.jumlah_sakit + gtk.jumlah_cuti;
  // row.insertCell().innerHTML = gtk.jumlah_terlambat_dengan_izin || '-';
  // row.insertCell().innerHTML = gtk.jumlah_terlambat_tanpa_izin || '-';
  // row.insertCell().innerHTML = gtk.durasi_terlambat_dengan_izin || '-';
  // row.insertCell().innerHTML = gtk.durasi_terlambat_tanpa_izin || '-';
  // row.insertCell().innerHTML = gtk.jumlah_pulang_cepat_dengan_izin || '-';
  // row.insertCell().innerHTML = gtk.jumlah_pulang_cepat_tanpa_izin || '-';
  // row.insertCell().innerHTML = gtk.durasi_pulang_cepat_dengan_izin || '-';
  // row.insertCell().innerHTML = gtk.durasi_pulang_cepat_tanpa_izin || '-';
};

function generateTableForExcel(tableId, labelExcel) {
  const containerF = document.createElement("div");
  const containerH = document.createElement("div");
  const header = containerH;
  containerH.innerHTML = headerExcel(labelExcel);

  const table = document.getElementById(tableId);
  console.log("🚀 ~ file: generateTableForExcel.js:53 ~ generateTableForExcel ~ table:", table)
  const newTable = table.cloneNode(true);
  newTable.setAttribute("border", "1");

  // const rows = newTable.childNodes[1];
  // _.sortBy(listRekap, 'nama_sekolah')
  //   .splice(100)
  //   .forEach((gtk, idx) => {
  //     generateContent(newTable.insertRow(), gtk, idx, module);
  //   });

  // if (rows.hasChildNodes()) {
  //   rows.childNodes.forEach((row) => {
  //     const nip = row.childNodes[3];
  //     nip?.setAttribute("style", "mso-number-format: '0'");
  //   });
  // }

  const content = document.createElement("div");
  content.appendChild(header);
  content.appendChild(newTable);

  return content;
}

export default generateTableForExcel;
